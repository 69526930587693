/**** Side bar css here ****/
.list-unstyled, .app-nav, .app-menu, .treeview-menu {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
@media (min-width: 768px) {
  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }
  .app.sidenav-toggled .app-sidebar {
    left: -200px;
  }
  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }

  .app-sidebar.collapsed {
    width: 50px !important;
  }

  .app-sidebar.collapsed .treeview-indicator {
    visibility: hidden;
  }

  .app-sidebar.collapsed .treeview-menu {
    display: None;
  }

  .collapsed .app-menu__label {
    display: none;
  }
}
@media (max-width: 767px) {
  .app {
    overflow-x: hidden;
  }
  .app .app-sidebar {
    left: -280px;
  }
  .app .app-sidebar__overlay {
    visibility: hidden;
  }
  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }
  .app.sidenav-toggled .app-sidebar {
    left: 0;
  }
  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: visible;
  }
  .app-sidebar {
    width: 280px !important;
  }
}
.app-content {
  min-height: calc(100vh - 50px);
  margin-top: 50px;
  padding: 30px;
  background-color: $body-bg;
  -webkit-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
}
@media (min-width: 768px) {
  .app-content {
    margin-left: 50px;
  }
}
@media (max-width: 767px) {
  .app-content {
    margin-top: 50px;
    min-width: 100%;
  }
}
@media (max-width: 480px) {
  .app-content {
    padding: 15px;
  }
}
@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: #fff;
  }
}
.light-text {
  font-weight: 300 !important;
}
.semibold-text {
  font-weight: 600 !important;
}
.line-head {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
/*----- Componant: Top Navigation Bar ----- */
.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: $white;
  z-index: 1030;
  padding-right: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: all .1s ease-in-out;
  height: 60px;
  align-items: center;
}
@media (min-width: 768px) {
  /*.app-header {
    padding-right: 30px;
  }*/
}
@media print {
  .app-header {
    display: none;
  }
}
.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: $white;
  text-align: center;
  padding: 5px 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
}
@media (min-width: 768px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 200px;
    background-color: $primary;
  }
}
.app-header__logo:focus, .app-header__logo:hover {
  text-decoration: none;
  color: #fff;
}
.app-sidebar__toggle {
  padding: 0 15px;
  font-family: fontAwesome;
  color: #fff;
  line-height: 2.4;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
@media (max-width: 767px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .app-sidebar__toggle {
    padding: 5px 20px;
  }
}
.app-sidebar__toggle:before {
  content: "\f0c9";
  font-size: 21px;
}
.app-sidebar__toggle:focus, .app-sidebar__toggle:hover {
  color: $white;
  background-color: $side-nav-hover-bg;
  text-decoration: none;
}
.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
.app-nav__item {
  display: block;
  padding: 20px 18px 20px 18px;
  line-height: 20px;
  color: $body-text-color;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  i {
    font-weight: 700;
  }
}
@media screen and ( max-width: 950px) {
  .app-nav__item {
    font-size: 13px !important;
    padding: 20px 12px 20px 12px;
  }
}
@media screen and ( max-width: 900px) {
  .app-nav__item {
    font-size: 12px !important;
    padding: 20px 7px 20px 8px;
  }
}
@media screen and ( max-width: 811px) {
  .app-nav__item {
    font-size: 11px !important;
  }
}
@media screen and ( max-width: 805px) {
  .app-nav__item {
    padding: 20px 6px;
  }
  .emp-sidebar .navbar-right-menu .nav-icon-img {
    margin-right: 3px;
  }
}
@media screen and ( max-width: 767px) {
  .app-nav__item {
    padding: 20px 18px 20px 18px;
  }
}
.app-nav__item:hover, .app-nav__item:focus {
  //background: rgba(46, 57, 73, 0.1);
  color: $primary;
}
.app-search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
}
@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}
.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  font-size: 13px;
  font-weight: 400;
}
.app-search__input::-webkit-input-placeholder {
  text-transform: inherit;
  color: $placeholder-color !important;
  opacity: 1;
}
.app-searchw__input:-ms-input-placeholder {
  text-transform: inherit;
  color: $placeholder-color !important;
  opacity: 1;
}
.app-search__input::-ms-input-placeholder {
  text-transform: inherit;
  color: $placeholder-color !important;
  opacity: 1;
}
.app-search__input::placeholder {
  text-transform: inherit;
  color: $placeholder-color !important;
  opacity: 1;
}
.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: rgba(0, 0, 0, 0.8);
  background: none;
  cursor: pointer;
}
.app-notification {
  min-width: 270px;
}

.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}

.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}

.app-notification__content::-webkit-scrollbar {
  width: 6px;
}

.app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.app-notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.app-notification__item:focus, .app-notification__item:hover {
  color: inherit;
  text-decoration: none;
  background-color: #e0e0e0;
}

.app-notification__message,
.app-notification__meta {
  margin-bottom: 0;
}

.app-notification__icon {
  padding-right: 10px;
}

.app-notification__message {
  line-height: 1.2;
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 70px;
  width: 200px;
  overflow-y: hidden;
  z-index: 10;
  background-color: $side-nav-bg;
  -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
  -webkit-transition: left 0.3s ease,
  width 0.3s ease;
  -o-transition: left 0.3s ease,
  width 0.3s ease;
  transition: left 0.3s ease,
  width 0.3s ease;
}

.app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 767px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
    background: rgba(0,0,0, 0.35);
  }
}

.app-sidebar__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
}

.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  font-size: 17px;
  line-height: 1.3;
}

.app-sidebar__user-name,
.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.app-menu {
  margin-bottom: 0;
}

.app-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 15px;
  font-size: 13px;
  border-left: 3px solid transparent;
  -webkit-transition: border-left-color 0.3s ease,
  background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease,
  background-color 0.3s ease;
  transition: border-left-color 0.3s ease,
  background-color 0.3s ease;
  color: #fff;
}

.app-menu__item.active, .app-menu__item:hover, .app-menu__item:focus {
  background: $side-nav-hover-bg;
  border-left-color: $primary;
  text-decoration: none;
  color: $white;
}

.app-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}

.app-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.treeview.is-expanded [data-toggle='treeview'] {
  border-left-color: transparent;
  background: $side-nav-hover-bg;
}
.treeview.is-expanded .icn-circle-bg {
  color: $primary;
}

.treeview.is-expanded .treeview-menu {
  max-height: 100vh;
}

.treeview.is-expanded .treeview-indicator {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.treeview-menu {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  -o-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  background: $side-nav-bg;
}

.treeview-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 15px 12px 60px;
  font-size: 1em;
  color: $white;
  font-size: 13px;
  border-left: 3px solid transparent;
  border-left-color: transparent;
  -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;
}

.treeview-item.active, .treeview-item:hover, .treeview-item:focus {
  background: $side-nav-hover-bg;
  text-decoration: none;
  color: $white;
  border-left-color: $primary;
}

.treeview-item .icon {
  margin-right: 5px;
}

.treeview-indicator {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled .app-sidebar__user-name,
  .sidebar-mini.sidenav-toggled .app-sidebar__user-designation,
  .sidebar-mini.sidenav-toggled .treeview-indicator {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user-avatar {
    width: 30px;
    height: 30px;
  }
  .sidebar-mini.sidenav-toggled .app-content {
    margin-left: 50px;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar {
    left: 0;
    width: 50px;
    overflow: hidden;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar:hover {
    overflow: visible;
  }
  .sidebar-mini.sidenav-toggled .app-menu__item {
    overflow: hidden;
  }
  .sidebar-mini.sidenav-toggled .app-menu__item:hover {
    overflow: visible;
  }
  .sidebar-mini.sidenav-toggled .app-menu__item:hover .app-menu__label {
    opacity: 1;
  }
  .sidebar-mini.sidenav-toggled .app-menu__item:hover + .treeview-menu {
    visibility: visible;
  }
  .sidebar-mini.sidenav-toggled .app-menu__label {
    display: block;
    position: absolute;
    top: 0;
    left: 50px;
    min-width: 180px;
    padding: 12px 5px 12px 20px;
    margin-left: -3px;
    line-height: 1;
    opacity: 0;
    background: $side-nav-bg;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidenav-toggled .treeview:hover .app-menu__item {
    overflow: visible;
    background: $side-nav-hover-bg;
    border-left-color: $primary;
    color: $white;
  }
  .sidebar-mini.sidenav-toggled .treeview:hover .app-menu__label {
    opacity: 1;
  }
  .sidebar-mini.sidenav-toggled .treeview:hover .treeview-menu {
    max-height: 100vh;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }
  .sidebar-mini.sidenav-toggled .treeview .app-menu__label {
    border-bottom-right-radius: 0;
  }
  .sidebar-mini.sidenav-toggled .treeview-menu {
    position: absolute;
    left: 50px;
    min-width: 180px;
    padding: 12px 0;
    opacity: 0;
    border-bottom-right-radius: 4px;
    z-index: 9;
    visibility: hidden;
    -webkit-transition: visibility 0.3s ease;
    -o-transition: visibility 0.3s ease;
    transition: visibility 0.3s ease;
  }
}

.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: transparent;
  margin: -20px -10px 12px;
  padding: 20px 9px;
  border-bottom: 1px solid $side-nav-title-hr;
  h1 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .emp-title-item {
    margin-left: 15px;
    display: flex;
    align-items: center;
  }
}

@media print {
  .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .app-title {
    margin: -5px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}



@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}

.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }
}

.tile {
  position: relative;
  background: $white;
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media print {
  .tile {
    border: 1px solid #ddd;
  }
}

.tile.small {
  padding: 0;
}

.tile .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #555;
  border-radius: 3px;
}

.tile .overlay .l-text {
  font-size: 24px;
  margin-top: 15px;
}

.tile .tile-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.tile .tile-title-w-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.tile .tile-title-w-btn p {
  margin: 0;
}

.tile .tile-title-w-btn .title {
  margin: 0;
}

.tile .tile-title-w-btn .side {
  margin-bottom: 0;
  padding-left: 0;
}

.tile .tile-title-w-btn .btn-group {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tile .tile-footer {
  border-top: 1px solid #ddd;
  padding: 20px 0 0 0;
  margin-top: 10px;
}

/* Custom Checkbox and Radio Buttons */
.animated-checkbox input[type="checkbox"] {
  display: none;
}

.animated-checkbox input[type="checkbox"] + .label-text {
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.animated-checkbox input[type="checkbox"] + .label-text:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 2px;
  vertical-align: -2px;
}

.animated-checkbox input[type="checkbox"]:checked + .label-text:before {
  content: "";
  color: #009688;
  -webkit-animation: tick 180ms ease-in;
  animation: tick 180ms ease-in;
}

.animated-checkbox input[type="checkbox"]:disabled + .label-text {
  cursor: not-allowed !important;
}

.animated-checkbox input[type="checkbox"]:disabled + .label-text:before {
  content: "";
  color: #ccc;
}

.animated-radio-button input[type="radio"] {
  display: none;
}

.animated-radio-button input[type="radio"] + .label-text {
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.animated-radio-button input[type="radio"] + .label-text:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 2px;
  vertical-align: -2px;
}

.animated-radio-button input[type="radio"]:checked + .label-text:before {
  content: "";
  color: #009688;
  -webkit-animation: tick 180ms ease-in;
  animation: tick 180ms ease-in;
}

.animated-radio-button input[type="radio"]:disabled + .label-text {
  cursor: not-allowed !important;
}

.animated-radio-button input[type="radio"]:disabled + .label-text:before {
  content: "";
  color: #ccc;
}

@-webkit-keyframes tick {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes tick {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.toggle.lg input[type="checkbox"] + .button-indecator:before {
  font-size: 30px;
}

.toggle input[type="checkbox"] {
  display: none;
}

.toggle input[type="checkbox"] + .button-indecator {
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle input[type="checkbox"] + .button-indecator:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 25px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  margin-right: 5px;
  vertical-align: -2px;
}

.toggle input[type="checkbox"]:checked + .button-indecator:before {
  content: "";
  color: #009688;
  -webkit-animation: toggleBtn 0.3s ease-in-out;
  animation: toggleBtn 0.3s ease-in-out;
}

.toggle input[type="checkbox"]:disabled + .button-indecator {
  cursor: not-allowed !important;
}

.toggle input[type="checkbox"]:disabled + .button-indecator:before {
  color: #ccc;
}

.toggle-flip input[type="checkbox"] {
  display: none;
}

.toggle-flip input[type="checkbox"] + .flip-indecator {
  position: relative;
  width: 60px;
  height: 30px;
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-perspective: 90px;
  perspective: 90px;
}

.toggle-flip input[type="checkbox"] + .flip-indecator:before, .toggle-flip input[type="checkbox"] + .flip-indecator:after {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  line-height: 30px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  border-radius: 3px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.toggle-flip input[type="checkbox"] + .flip-indecator:before {
  content: attr(data-toggle-off);
  background-color: #ddd;
}

.toggle-flip input[type="checkbox"] + .flip-indecator:after {
  content: attr(data-toggle-on);
  background-color: #009688;
  color: #fff;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.toggle-flip input[type="checkbox"]:checked + .flip-indecator:before {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.toggle-flip input[type="checkbox"]:checked + .flip-indecator:after {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.toggle-flip input[type="checkbox"]:disabled + .flip-indecator {
  cursor: not-allowed !important;
  color: #ccc;
}

@-webkit-keyframes toggleBtn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes toggleBtn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.material-half-bg {
  height: 100vh;
  background-color: #e7e7e7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.material-half-bg .cover {
  background-color: #009688;
  height: 50vh;
}

.m-loader {
  position: relative;
  width: 40px;
}

.m-loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.m-circular {
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke: #009688;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}

.btn-spinner {
  display: inline-block;
  z-index: 2000;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  border: solid 2px transparent;
  border-top-color: #fff;
  border-left-color: #fff;
  border-radius: 10px;
  -webkit-animation: bSpinner 0.8s linear infinite;
  animation: bSpinner 0.8s linear infinite;
}

@-webkit-keyframes bSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes bSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.widget-small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  color: #FFF;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.widget-small .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 85px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;
  font-size: 2.5rem;
}

.widget-small .info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 20px;
  -ms-flex-item-align: center;
  align-self: center;
}

.widget-small .info h4 {
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 1.1rem;
}

.widget-small .info p {
  margin: 0;
  font-size: 16px;
}

.widget-small.primary {
  background-color: #009688;
}

.widget-small.primary.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.primary.coloured-icon .icon {
  background-color: #009688;
  color: #fff;
}

.widget-small.info {
  background-color: #17a2b8;
}

.widget-small.info.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.info.coloured-icon .icon {
  background-color: #17a2b8;
  color: #fff;
}

.widget-small.warning {
  background-color: #ffc107;
}

.widget-small.warning.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.warning.coloured-icon .icon {
  background-color: #ffc107;
  color: #fff;
}

.widget-small.danger {
  background-color: #dc3545;
}

.widget-small.danger.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.danger.coloured-icon .icon {
  background-color: #dc3545;
  color: #fff;
}

.messanger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.messanger .messages {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 10px 0;
  padding: 0 10px;
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
}

.messanger .messages .message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.messanger .messages .message.me {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.messanger .messages .message.me img {
  margin-right: 0;
  margin-left: 15px;
}

.messanger .messages .message.me .info {
  background-color: #009688;
  color: #FFF;
}

.messanger .messages .message.me .info:before {
  display: none;
}

.messanger .messages .message.me .info:after {
  position: absolute;
  right: -13px;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 0;
  border-color: transparent #009688 transparent transparent;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.messanger .messages .message img {
  border-radius: 50%;
  margin-right: 15px;
}

.messanger .messages .message .info {
  margin: 0;
  background-color: #ddd;
  padding: 5px 10px;
  border-radius: 3px;
  position: relative;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.messanger .messages .message .info:before {
  position: absolute;
  left: -14px;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 0;
  border-color: transparent #ddd transparent transparent;
}

.messanger .sender {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.messanger .sender input[type="text"] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #009688;
  outline: none;
  padding: 5px 10px;
}

.messanger .sender button {
  border-radius: 0;
}

.login-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
}

.login-content .logo {
  margin-bottom: 40px;
  font-family: "Niconne";
  color: #fff;
}

.login-content .logo h1 {
  font-size: 52px;
  font-weight: 400;
}

.login-content .login-box {
  position: relative;
  min-width: 350px;
  min-height: 390px;
  background-color: #fff;
  -webkit-box-shadow: 0px 29px 147.5px 102.5px rgba(0, 0, 0, 0.05), 0px 29px 95px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 29px 147.5px 102.5px rgba(0, 0, 0, 0.05), 0px 29px 95px 0px rgba(0, 0, 0, 0.16);
  -webkit-perspective: 800px;
  perspective: 800px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.login-content .login-box .login-head {
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.login-content .login-box label {
  color: #666;
  font-weight: 700;
}

.login-content .login-box .utility {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-content .login-box .btn-container {
  margin-bottom: 0;
}

.login-content .login-box .login-form, .login-content .login-box .forget-form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.login-content .login-box .forget-form {
  opacity: 0;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.login-content .login-box.flipped {
  min-height: 300px;
}

.login-content .login-box.flipped .login-form {
  opacity: 0;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.login-content .login-box.flipped .forget-form {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

@media (max-width: 351px) {
  .login-content .login-box {
    min-width: 100%;
  }
  .login-content .login-box .login-form, .login-content .login-box .forget-form {
    width: 100%;
  }
}

.lockscreen-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
}

.lockscreen-content .logo {
  font-family: "Niconne";
  margin-bottom: 40px;
  color: #fff;
}

.lockscreen-content .logo h1 {
  font-size: 52px;
  font-weight: 400;
}

.lockscreen-content .lock-box {
  position: relative;
  min-width: 320px;
  padding: 20px 40px;
  background-color: #fff;
  -webkit-box-shadow: 0px 29px 147.5px 102.5px rgba(0, 0, 0, 0.05), 0px 29px 95px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 29px 147.5px 102.5px rgba(0, 0, 0, 0.05), 0px 29px 95px 0px rgba(0, 0, 0, 0.16);
  -webkit-perspective: 800px;
  perspective: 800px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.lockscreen-content .lock-box .user-image {
  border: 3px solid #fff;
  max-width: 100px;
  display: block;
  margin: 0 auto;
}

.lockscreen-content .lock-box .user-name {
  margin-bottom: 2px;
}

.lockscreen-content .lock-box label {
  color: #666;
  font-weight: 700;
}

.page-error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: calc(100vh - 110px);
  margin-bottom: 0;
}

.page-error h1 {
  margin: 10px;
  color: #dc3545;
  font-size: 42px;
}

.folder-head {
  padding: 10px;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #ddd;
}

.mailbox-controls {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding: 0 0 10px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mailbox-controls .animated-checkbox .label-text:before {
  vertical-align: -7px !important;
}

.mail-nav > li > a {
  display: block;
  background-color: #fff;
  color: #2a2a2a;
  border-radius: 0;
  font-weight: 500;
}

.mailbox-messages table tr td {
  border: 0;
  padding: 5px;
}

.mailbox-messages .mail-subject {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
}

.user .profile {
  margin: -30px -30px 0 -30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.user .profile .info {
  padding: 30px 60px;
  text-align: center;
  background-color: #fff;
  white-space: nowrap;
  background-color: #404040;
  color: #fff;
}

.user .profile .info img {
  border-radius: 50%;
  max-width: 110px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.user .profile .cover-image {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url(http://placeimg.com/1200/300/nature);
  background-size: cover;
  background-position: center;
  min-height: 300px;
}

.user .user-tabs {
  margin-top: 20px;
}

.user .user-tabs .nav-link {
  border-left: 3px solid transparent;
  padding: 12px 15px;
  border-bottom: 0;
  font-size: 15px;
  border-radius: 0;
}

.user .user-tabs .nav-link.active {
  border-left-color: #009688;
  border-bottom: 0;
}

.user .user-tabs .nav-link:hover, .user .user-tabs .nav-link:active {
  background-color: #eee !important;
  border-bottom: 0;
}

.user .tab-content {
  margin: 20px -10px -10px;
}

.user .timeline-post {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.user .timeline-post .post-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 15px;
}

.user .timeline-post .post-media img {
  margin-right: 10px;
}

.user .timeline-post .post-media h5 {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 15px;
}

.user .timeline-post .post-content {
  margin-bottom: 20px;
}

.user .timeline-post .post-utility {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.user .timeline-post .post-utility .likes,
.user .timeline-post .post-utility .shares {
  margin-right: 10px;
}

.user .timeline-post .post-utility .likes a,
.user .timeline-post .post-utility .shares a {
  color: #555;
}

.user .timeline-post .post-utility .comments {
  color: #555;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.user .user-settings .line-head {
  font-size: 18px;
  margin-bottom: 30px;
  color: #2a2a2a;
}

.user .user-settings label {
  color: #666;
}


@media (min-width: 768px) {
  .app-sidebar__toggle {
    display: none;
  }
  .app-sidebar__toggle-xs-circle-times {
    display: none;
  }
}
@media (max-width: 767px) {
  .app-header__logo {
    display: none !important;
  }
  .app-sidebar__toggle {
    background-color: $primary;
  }
  .app-sidebar__toggle-xs-circle-times {
    display: none;
  }
}