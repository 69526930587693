.cartesian-chart .recharts-cartesian-axis-ticks {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
}

.cartesian-chart .recharts-legend-item-text {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    fill: #616161;
}

.cartesian-chart .recharts-tooltip-item-name,
.cartesian-chart .recharts-tooltip-item-value,
.cartesian-chart .recharts-tooltip-item-separator {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    fill: #616161;
}