@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');
body{
  font-size: $font-size-base;
  transition: all .1s ease-in-out;
  background-color: $body-bg;
  font-family: $font-family-base;
  font-weight: 400;
  color: $body-text-color;
}
// html, body {
//   height: 100%;
// }
#wrap {
  min-height: 100%;
}
a{
  transition: all .1s ease-in-out;
}
:focus,:active{
  /*border:0;*/
  outline: none;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  text-transform: capitalize;
  color: $black;
  opacity: 1;
  font-weight: 500;
}
::-moz-placeholder { /* Firefox 19+ */
  text-transform: capitalize;
  color: $black;
  opacity: 1;
  font-weight: 500;
}
:-ms-input-placeholder { /* IE 10+ */
  text-transform: capitalize;
  color: $black;
  opacity: 1;
  font-weight: 500;
}
:-moz-placeholder { /* Firefox 18- */
  text-transform: capitalize;
  color: $black;
  opacity: 1;
  font-weight: 500;
}

.emp_lower_case :-moz-placeholder { /* Firefox 18- */
  text-transform: initial !important;
}
.emp_lower_case ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  text-transform: initial !important;
}
.emp_lower_case ::-moz-placeholder { /* Firefox 19+ */
  text-transform: initial !important;
}
.emp_lower_case :-ms-input-placeholder { /* IE 10+ */
  text-transform: initial !important;
}





.input_modifier {
  text-transform: capitalize;
  color: $body-text-color;
  /*opacity: 0.8;*/
}
.shadow_select_hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(52, 70, 84, 0.15) !important;
  border-color: transparent;
}
.cst-select-box {
  -webkit-appearance: none !important;
  -moz-appearance: none;
  background-image: image_url("icon/ic_sort.png") !important;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}
.input_modifier {
  line-height: 1.5;
  padding: 5px 14px;
  border-radius: 4px;
  font-size: 13px;
  height: 2rem;
  border-color: $bg-range-slider;
  color: $placeholder-color;
}

.emp_position {
  position: relative;
}
.emp_absolute {
  position: absolute;
}


/** Alert css here **/
/*alert_message css*/
.cst-alert{
  background-color:$bg_em_circle_green !important;
  border-color:$bg_em_circle_green !important;;
  color: $white !important;;
  padding: 0.3rem 0.5rem 0.6rem !important;;
  .alert-msg-media_body{
    padding-left: 20px;
  }
  .media-heading{
    font-weight: 300;
    font-size: 17px;
  }
  .alert-msg-media{
    padding: 5px 0 0 20px;
  }
}


/**** css for only chrome browsers ****/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .emp-number-box-min-height {
    min-height: 232px;
  }
}