a, a:hover, a:focus {
	color: #f35b3f;
	text-decoration: none;
	-o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}
/***** Top content *****/

.top-content { 
padding: 40px 0 170px 0; 
text-align:center;
}
.emp-form-step {
  box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
  .emp-bottom-text {
	margin: 35px 0 50px 0;
	padding: 0 66px;
	color: #8a8a8a;
  }
  .emp-activate-devices {
	.emp-bottom-text {
	  margin: 0 0 26px 0;
	}
	h5 {
	  margin: 35px 0 17px 0;
	  .emp-icn-times {
		position: relative;
		top: -8px;
		font-size: 22px;
	  }
	}
  }
  .emp-danger-card-alert {
	.emp-danger-alert-box {
	  background-color: $card-danger-bg;
	  border-color: $card-danger-bg;
	  color: $white;
	  margin-top: -70px;
	  margin-bottom: 8px;
	  box-shadow: 0px 5px 7px 0px rgba(12, 11, 11, 0.25);
	  -moz-box-shadow: 0px 5px 7px 0px rgba(12, 11, 11, 0.25);
	  -webkit-box-shadow: 0px 5px 7px 0px rgba(12, 11, 11, 0.25);
	  .card-body {
		padding: 12px 10px 10px 29px;
		font-size: 14px;
		line-height: 18px;
		h5 {
		  margin-bottom: 0px;
		  font-size: 16px;
		  font-weight: 400;
		}
	  }

	}
  }

  .emp-btn {
	min-width: 6rem;
	margin: 10px 14px;
  }
  .emp-back-btn {
	background-color: $back-btn-bg;
	border-color: $back-btn-bg;
	color: $white;
	&:hover {
	  background-color: $primary;
	  border-color: $primary;
	  color: $white;
	}
  }
  .input_modifier {
	padding: 5px 18px;
  }
  .textarea_modifier {
	min-height: 80px;
  text-transform: none;
  }
  .active-plus-anchor-block {
	margin-top: 18px;
  }
  .active-plus-anchor {
	.icn_plus {
	  font-size: 22px;
	  margin: 0 5px 0 0;
	}
	.icn_plus_txt {
	  position: relative;
	  top: -2px;
	}
  }
  .f1-btn-activate-d {
	  margin-top: 26px;
  }
  .emp_icn_middle {
	position: relative;
	top: -2px;
  }
}


.f1 {
	padding: 37px 25px 39px 25px;
	background: $white;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
    border-radius: 6px;
}
.f1 h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin: 64px 0 50px 0;
  color: #413e3f;
}

.f1-steps {
  	overflow: hidden;
  	position: relative;
  	margin-top: 20px;
}

.f1-progress {
	position: absolute;
	top: 23px;
	left: 120px;
	width: 74%;
	height: 2px;
	background: #ddd;
}
.f1-progress-line { position: absolute; top: 0; left: 1px; height: 2px; background: #0273eb; }

.f1-step { position: relative; float: left; width: 24.333333%; padding: 0 5px; }

.f1-step-icon {
	display: inline-block; width: 25px; height: 25px; margin-top: 11px; background: #fff;
	font-size: 16px; color: #fff; line-height: 40px;
	-moz-border-radius: 50%; -webkit-border-radius: 50%; border-radius: 50%;
	border: 2px solid #ddd;
}
.f1-step.activated .f1-step-icon {
	background: #fff; border: 8px solid #0273eb; color: #0273eb; line-height: 38px;
}
.f1-step.active .f1-step-icon {
	width: 25px; height: 25px; margin-top: 11px; background: #fff; font-size: 22px; line-height: 48px;
	border: 8px solid #0273eb;
}

.f1-step p { color: #ccc; }
.f1-step.activated p { color: #0273eb; }
.f1-step.active p { color: #f35b3f; }

.f1 fieldset { display: none; text-align: left; }

.f1-buttons { text-align: right; }

.f1 .input-error { border-color: #f35b3f; }



/***** Media queries *****/

@media screen and (max-width: 1250px) {

  .f1-progress {
	left: 108px;
  }

}
@media screen and (max-width: 1120px) {
  .f1-progress {
	left: 93px;
  }
}
@media screen and (max-width: 991px) {
.emp-form-step .emp-bottom-text {
  padding: 0 33px;
}
  .emp-form-step {
	padding: 37px 0px 39px 0px;
  }
}
@media screen and (max-width: 955px) {
  .f1-progress {
	left: 75px;
  }
}
@media screen and (max-width: 767px) {
  .emp-form-step .emp-danger-card-alert .emp-danger-alert-box .card-body {
	padding: 10px;
	font-size: 13px;
	line-height: 16px;
  }
  .f1-progress {
	left: 80px;
  }
}
@media screen and (max-width: 646px) {
  .f1-progress {
	left: 56px;
  }
}
@media screen and (max-width: 580px) {
  .emp-form-step {
	padding: 37px 15px 39px 15px;
  }
}
@media screen and (max-width: 470px) {
  .f1-progress {
	left: 35px;
  }
  .emp-form-step .emp-bottom-text {
	padding: 0;
  }
}
@media screen and (max-width: 415px) {
	.f1 { padding-bottom: 20px; }
	.f1-buttons button { margin-bottom: 5px; }

}


/* input animation effect */
.input {
  position: relative;
  z-index: 1;
  display: block;
  vertical-align: top;
}
.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  background: $white;
  -webkit-appearance: none;
  text-transform: uppercase;
}
.input__field:focus {
  outline: none;
}
.input__label {
  display: inline-block;
  float: right;
  color: $primary;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input__label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}
/* Yoshiko */
.input__field--yoshiko {
  width: 100%;
  background-color: $white;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
.input__label--yoshiko {
  width: 100%;
  text-align: left;
  position: absolute;
  bottom: 100%;
  pointer-events: none;
  overflow: hidden;
  padding: 6px 1.75em;
  -webkit-transform: translate3d(0, 3em, 0);
  transform: translate3d(0, 3em, 0);
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: normal;
  line-height: 27px;
}
.input__label-content--yoshiko {
  color: #8B8C8B;
  padding: 0.25em 0;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.input__label-content--yoshiko::after {
  content: attr(data-content);
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 100%;
  width: 100%;
  color: $primary;
  padding: 0.20em 0;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 13px;
}
.input__field--yoshiko:focus + .input__label--yoshiko,
.input--filled .input__label--yoshiko {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.input__field--yoshiko:focus + .input__label--yoshiko .input__label-content--yoshiko,
.input--filled .input__label-content--yoshiko {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.input__field--yoshiko:focus + .input__field--yoshiko,
.input--filled .input__field--yoshiko {
  background-color: transparent;
}

