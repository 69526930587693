.organization_year_picker .date_time_react {
  width: 90%;
}
.date_time_react {
  width: 80%;
  input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
@media (max-width: 1366px) {
  .about_switch_btn.cst-switch {
    width: 14rem;
    margin-left: -11px !important;
  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.event-start-end-block .time-pipe.vertical-pipe::after {
    top: 50% !important;
}
.form-group__error {
  color: #dc0f0f;
  font-size: 12px;
}
.input_error {
  border: 1px solid #dc0f0f
}

.pointer_none {
  pointer-events: none;
  opacity: 0.6;
}
.margin-top10 {
  margin-top: 10%;
}
#content .organization-details-block .photo-bottom-block img {
  border: 3px solid #e7e7e7;
  margin: 5px 5px 0 5px;
}
.hide_data {
  display: none;
}
@media (max-width: 1055px) {
  .about_switch_btn.cst-switch {
    width: 14rem;
    margin-left: -16px !important;
  }
}
.user-avatar-block {
  height: 40px;
  img {
    height: 100%;
  }
}
.events-pic {
  width: 100%;
  height: 250px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.organization_images {
  width: 90px;
  height: 75px;
  overflow: hidden;
  display: inline-block;
  img {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }
}

#react-autowhatever-1{
  width:  93.4%;
  background: #ffffff;
  position:  absolute;
  z-index: 99999;
  ul{
    padding: 0 15px;
    width: 100%;
  }
  li{
    width:  100%;
    list-style:  none;
    padding: 0px 0 5px;
    margin: 0 0 5px;
    border-bottom: solid 1px #e7e7e7;
    cursor: pointer;
  }
}
.destination-search-bar.destination-search-bar--sm {
  width: 93.4%;
}
.date_time_react .form-control[readonly]{
  background-color: #ffffff;
}
.event_hero{
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}
.event_hero .address-text{
  font-size: 1.3rem !important;
}
.event_hero .big-heading{
  text-transform: capitalize;
}
.checked-role {
  color: $primary;
}
.search-datetime-picker {
  .date_time_react {
    width: 100%;
    .rdtPicker {
      width: 100%;
    }
  }
  .custom-group {
    margin-bottom: 0;
    .rdt {
      position: inherit !important;
    }
  }
}

.cst-upload {
  .sponsorship_image.custom-file-label::after {
    content: "Brand Image/Logo" !important;
  }
}
.sponsor_date_picker {
  .date_time_react {
    width:  79%;
  }
}
.options_scroller {
  .scrollarea.area {
    height: 400px;
    width: 100%;
  }
  .scrollarea .scrollbar-container.vertical .scrollbar {
    width: 3px !important;
    //height: 20px !important;
    background: $primary !important;
    margin-left: 3px !important;
    opacity: 1;
  }
  .scrollarea:hover .scrollbar-container {
    opacity: 1 !important;
  }
}
.events-blcok.events-details-photo {
  width: 100%;
  height: 320px;
  img {
    width: 100%;
    height: 100%;
  }
}
.show .dropdown-toggle1 {
  background-color: $white;
  color: $primary !important;
  border-radius: 4px;
}
.show .dropdown-toggle1:hover {
  color: $primary !important;
}
.show .dropdown-toggle1:focus {
  color: $primary !important;
}
.cst-icon-label {
  display: inline-block !important;
}
.custom-icon {
  font-weight: 600;
  display: inline-block;
  margin-left: 5px;
  color: $primary !important;
}
.about_switch_btn .form-group__error {
  display: block;
  width: 67%;
  float: left;
}

.sponsor_date_picker {
  span.bg-input-text {
    background-color: $white !important;
    color: $black;
    border-left: none;
    i.fa-calendar {
      color: $black !important;
    }
  }
  .date_time_react .form-control[readonly] {
    border-right: none;
  }

}
.thankyou-section {
  .thankyou-block {
    padding-top: 75px;
    padding-bottom: 80px;
    h1 {
      font-size: 40px;
      font-weight: 600;
      margin-top: 35px;
      color: $text-gray-800;
    }
    h2 {
      font-size: 36px;
      font-weight: 500;
      color: $text-gray-800;
      margin-top: 35px;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 0;
    }
    .event-url-card {
      margin: 55px 0 45px 0;
      p {
        font-size: 18px;
        margin-bottom: 0;
      }
      a:hover {
        text-decoration: none;
      }
    }
    .bottom-text {
      font-size: 17px;
      color: $text-gray-400;
    }
  }
}
@media screen and (max-width: 991px) {
  .thankyou-section .thankyou-block h1 {
    font-size: 36px;
  }
  .thankyou-section .thankyou-block h2 {
    font-size: 30px;
  }
  .thankyou-section .thankyou-block h3 {
    font-size: 18px;
  }
  .thankyou-section .thankyou-block .event-url-card p {
    font-size: 16px;
  }
  .thankyou-section .thankyou-block .bottom-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .thankyou-section .thankyou-block h1 {
    font-size: 32px;
  }
  .thankyou-section .thankyou-block h2 {
    font-size: 28px;
    line-height: 38px;
  }
}


/*coming soon page css here*/
.commingsoon-section {
  background-image:image_url("background/coming-soon-bg.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: scroll;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  align-items: center;
  h1 {
    text-align: center;
    font-size: 60px;
    color: $white;
  }
  .fl-rich-text {
    font-size: 18px;
    color: $white;
    text-align: center;
    margin: 30px 0;
  }
  .btn-center {
    margin: 40px 0;
    a {
      display: inline-block;
      padding: 10px 30px;
      margin: 0 15px 20px;
      border: solid 2px;
      font-size: 18px;
      border-radius: 100px;
      color: $white;
      &:hover {
        border-color: $primary;
        color: $primary !important;
        text-decoration: none;
      }
    }
  }
  .fl-icon {
    margin: 20px 0;
    a {
      font-size: 40px;
      color: $white;
      text-decoration: none;
      margin-left: 15px;
      margin-right: 15px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.organization-photo-block {
  width: 65px;
  height: 65px;
  img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.btn-primary {
  color: #fff;
  background-color: #0054a6;
  border-color: #0054a6;
}
.btn-primary {
   color: #fff;
   background-color: $primary !important;
   border-color: $primary !important;
 }
a.btn-primary:focus {
  color: #fff;
}


@media screen and (max-width: 580px) {
  .commingsoon-section h1 {
    font-size: 45px;
  }
}
@media screen and (max-width: 350px) {
  .commingsoon-section h1 {
    font-size: 36px;
  }
}
.event_location_form {
  #react-autowhatever-1 {
    width: 94%;
    background: #fafafa;
    border-radius: 0px 0 4px 4px;
  }
  #react-autowhatever-1 ul{
    padding: 0px;
    margin-bottom: 0px;
  }
  #react-autowhatever-1 ul li{
    padding: 8px 15px;
    font-size: 13px;
    margin-bottom: 0px;
  }
  .destination-search-bar.destination-search-bar--sm {
    width: 100%;
  }
}
.loader_container {
  height: 100vh;
  display: flex;
  justify-items: center;
  width: 100%;
}
.loader_container img{
  margin: 0 auto;
}

.card:focus, .card:active {
  border: 1px solid rgba(0, 0, 0, 0.125);
  outline: none;
}
@media (max-width: 1920px) {
  .max-vh{
    height: 100vh !important;
  }
}
@media (max-width: 1366px) {
  .max-vh{
    height: 100% !important;
  }
}
.search-form .destination-search-bar ul.react-autosuggest__suggestions-list li {
  text-align: left;
}
.hide-error-msg .form-group__error {
  display: none;
}
.cursor-default {
  cursor: default !important;
}
.f1 fieldset.active {
  display: block;
}
/*coming soon page css here*/
/*temprary chart css */
.highcharts-container {
  //top: 59px;
  //bottom: 59px;
  border-radius: 4px;
  height: 20% !important;
}
.highcharts-legend {
  top: 5px !important;
}
.first-chart {
  width: 80%;
  margin-left: 10%;
}
.second-chart {
  width: 80%;
  margin-top: 5%;
  margin-left: 10%;
  margin-bottom: 10%;
}
.main-container {
  background-color: #F2F2F6;
}
.left-chart {
  width: 49%;
  display: inline-block;

}
.right-chart {
  width: 49%;
  display: inline-block;
  margin-left: 20px;
}
.symbol-img {
  float: left;
  margin: 5% 3% 0 5%;
  height: 13px;
}
/* temprary chart css */
.app-header {
  z-index: 1030 !important;
}
.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}
.element-card {
  height: 248px;
}
.emp-form-step .emp-danger-card-alert .emp-success-alert-box {
  background-color: #2dce6e;
  border-color: #2dce6e;
  color: #fff;
  margin-top: -70px;
  margin-bottom: 8px;
}
.activate_btn {
  border: none;
  background: none;
  cursor: pointer;
}
.graph_block img {
  width: 100%;
}
.custom-control-label::before {
  left: -1.5rem;
}
.custom-control-label::after {
  left: -1.5rem;
}
.app-sidebar {
  .scrollarea.area {
    height: 100%;
    width: 100%;
  }
  .appmenu {
    padding-left: 0;
    list-style: none;
  }
  .scrollarea .scrollbar-container.vertical .scrollbar {
    width: 5px !important;
    //height: 20px !important;
    background: $primary !important;
    margin-left: 4px !important;
    opacity: 1;
  }
  .scrollarea:hover .scrollbar-container {
    opacity: 1 !important;
  }
}
.report_section {
  .cursor-pointer {
    cursor: pointer;
  }
}
.search-member-container {
  #react-autowhatever-1 {
    width: 91.4%;
    border-radius: 4px;
  }
  #react-autowhatever-1 ul {
    padding: 0;
    margin: 0;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
  }
  #react-autowhatever-1 li {
    margin: 0 0 0px;
  }
}
.alert-select {
  .search-member-container #react-autowhatever-1 {
    width: 100%;
  }
}


.rc-slider-tooltip-hidden {
  display: block !important;
}
.rc-slider-tooltip-inner {
  background: none !important;
  color: #526C79 !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none;
  font-weight: 500;
}
.emp-sidebar .app-content .emp-incidents .emp-circle-icn {
  background: none;
}
.emp_all_groups_w_200 {
  width: 225px;
}
.datepicker--input {
  border: 0;
  border-radius: 4px;
  padding: 5px 0 5px 10px;
  font-weight: 300 !important;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.selectpicker {
  .react-select__indicators {
    padding: 10px;
  }
  .react-select__indicator-separator {
    background-color: $white;
  }
  .react-select__dropdown-indicator {
    //background-image: image_url("/icon/icn_select.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-attachment: scroll;
    svg {
      display: none;
    }
  }
  .react-select__option--is-selected {
    background-color: $primary;
  }
  .react-select__option {
    position: relative;
  }
  .react-select__option--is-selected::before {
    content: "";
    background-image: image_url("/icon/ic_checking.png");
    width: 16px;
    height: 16px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
input[type=email],input[type=password]  {
  text-transform: none;
}
.login.alert {
  width: 500px;
  position: fixed;
  right: 34px;
  top: 15px;
  z-index: 99999;
  padding: 15px;
}

.login.alert p{
  margin: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.custom-file-label {
  z-index: 10;
}
.pointer-none {
  pointer-events: none;
}
.search_sensors {
  .form-control.search-field {
    padding: 5px 10px;
    font-size: 13px;
  }
  #react-autowhatever-1 {
    width: 100%;
    float: left;
  }
  #react-autowhatever-1 {
    width: 100%;
    border-radius: 4px;
  }
  #react-autowhatever-1 {
    max-height: 50vh;
    overflow-y: auto;
  }
}

.cancel_btn {
  vertical-align: middle;
  display: inline-block;

}
.environment-select {
  .react-select__control {
    width: 100px;
  }
}
.env-date  .date_time_react {
  width: 100%;
  input {
    border-radius: 4px;
  }
}
.scheduled-report {
  .date_time_react {
    width: 95%;
  }
  .input-group-text.bg-input-text {
    background: none;
  }
  .destination-search-bar {
    width: 84%;
  }
  .custom-control-label {
    padding-top: 2px;
  }
}

.swal-title {
  font-weight: 400;
  margin-top: 10px !important;
  font-size: 24px;
  margin-bottom: 20px !important;
  border-bottom: solid 1px #e7e7e7;
}
.transform-inherit {
  text-transform: inherit !important;
}
.wender-circle {
  width: 32px;
  height: 32px;
  background: none !important;
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}
.search-report-group-container {
  .react-autosuggest__suggestions-list {
    height: 40vh;
    overflow-y: scroll;
  }
}
.hide_error {
  .form-group__error {
    display: none;
  }
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.emp-sec.organization-setting {
  padding: 10px 0;
  .emp-active-title {
    margin-bottom: 20px;
  }
}
.scheduled-report .emp_user{
  width: 13px;
  height: 15px;
  img {
    margin-bottom: 7px;
    border-radius: 0 !important;
  }
}
.create-group {
  display: inline-block !important;
  img {
    vertical-align: top;
  }
}
.active_device_controls .icn_nav {
  font-size: 26px;
}