.ui-btn-active {
  cursor: pointer
}

.ui-corner-all {
  -webkit-border-radius: .3125em;
  border-radius: .3125em
}

.ui-btn-corner-all,
.ui-btn.ui-corner-all,
.ui-slider-track.ui-corner-all,
.ui-flipswitch.ui-corner-all,
.ui-li-count {
  -webkit-border-radius: .3125em;
  border-radius: .3125em
}

.ui-btn-icon-notext.ui-btn-corner-all,
.ui-btn-icon-notext.ui-corner-all {
  -webkit-border-radius: 1em;
  border-radius: 1em
}

.ui-btn-corner-all,
.ui-corner-all {
  -webkit-background-clip: padding;
  background-clip: padding-box
}

.ui-popup.ui-corner-all>.ui-popup-arrow-guide {
  left: .6em;
  right: .6em;
  top: .6em;
  bottom: .6em
}

.ui-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15)
}

.ui-shadow-inset {
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2)
}

.ui-overlay-shadow {
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, .6);
  -moz-box-shadow: 0 0 12px rgba(0, 0, 0, .6);
  box-shadow: 0 0 12px rgba(0, 0, 0, .6)
}

.ui-btn-icon-left:after,
.ui-btn-icon-right:after,
.ui-btn-icon-top:after,
.ui-btn-icon-bottom:after,
.ui-btn-icon-notext:after {
  background-color: #666;
  background-color: rgba(0, 0, 0, .3);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-border-radius: 1em;
  border-radius: 1em
}

.ui-alt-icon.ui-btn:after,
.ui-alt-icon .ui-btn:after,
html .ui-alt-icon.ui-checkbox-off:after,
html .ui-alt-icon.ui-radio-off:after,
html .ui-alt-icon .ui-checkbox-off:after,
html .ui-alt-icon .ui-radio-off:after {
  background-color: #666;
  background-color: rgba(0, 0, 0, .15)
}

.ui-nodisc-icon.ui-btn:after,
.ui-nodisc-icon .ui-btn:after {
  background-color: transparent
}

.ui-shadow-icon.ui-btn:after,
.ui-shadow-icon .ui-btn:after {
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, .3);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, .3);
  box-shadow: 0 1px 0 rgba(255, 255, 255, .3)
}

.ui-btn.ui-checkbox-off:after,
.ui-btn.ui-checkbox-on:after,
.ui-btn.ui-radio-off:after,
.ui-btn.ui-radio-on:after {
  display: block;
  width: 18px;
  height: 18px;
  margin: -9px 2px 0 2px
}

.ui-checkbox-off:after,
.ui-btn.ui-radio-off:after {
  filter: Alpha(Opacity=30);
  opacity: .3
}

.ui-btn.ui-checkbox-off:after,
.ui-btn.ui-checkbox-on:after {
  -webkit-border-radius: .1875em;
  border-radius: .1875em
}

.ui-btn.ui-checkbox-off:after {
  background-color: #666;
  background-color: rgba(0, 0, 0, .3)
}

.ui-radio .ui-btn.ui-radio-on:after {
  background-image: none;
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-width: 5px;
  border-style: solid
}

.ui-alt-icon.ui-btn.ui-radio-on:after,
.ui-alt-icon .ui-btn.ui-radio-on:after {
  background-color: #000
}

.ui-icon-loading {
  //background: image_url('ajax-loader.gif');
  background-size: 2.875em 2.875em
}

.ui-bar-a,
.ui-page-theme-a .ui-bar-inherit,
html .ui-bar-a .ui-bar-inherit,
html .ui-body-a .ui-bar-inherit,
html body .ui-group-theme-a .ui-bar-inherit {
  background-color: #e9e9e9;
  border-color: #ddd;
  color: #333;
  text-shadow: 0 1px 0 #eee;
  font-weight: 700
}

.ui-bar-a {
  border-width: 1px;
  border-style: solid
}

.ui-overlay-a,
.ui-page-theme-a,
.ui-page-theme-a .ui-panel-wrapper {
  background-color: transparent;
  border-color: #bbb;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3
}

.ui-page-theme-a a,
html .ui-bar-a a,
html .ui-body-a a,
html body .ui-group-theme-a a {
  color: $primary;
  font-weight: 700
}

.ui-page-theme-a a:visited,
html .ui-bar-a a:visited,
html .ui-body-a a:visited,
html body .ui-group-theme-a a:visited {
  color: #38c
}

.ui-page-theme-a a:hover,
html .ui-bar-a a:hover,
html .ui-body-a a:hover,
html body .ui-group-theme-a a:hover {
  color: #059
}

.ui-page-theme-a a:active,
html .ui-bar-a a:active,
html .ui-body-a a:active,
html body .ui-group-theme-a a:active {
  color: #059
}

.ui-page-theme-a .ui-btn,
html .ui-bar-a .ui-btn,
html .ui-body-a .ui-btn,
html body .ui-group-theme-a .ui-btn,
html head+body .ui-btn.ui-btn-a,
.ui-page-theme-a .ui-btn:visited,
html .ui-bar-a .ui-btn:visited,
html .ui-body-a .ui-btn:visited,
html body .ui-group-theme-a .ui-btn:visited,
html head+body .ui-btn.ui-btn-a:visited {
  background-color: $primary;
  border-color: #ddd;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3
}

.ui-page-theme-a .ui-btn:hover,
html .ui-bar-a .ui-btn:hover,
html .ui-body-a .ui-btn:hover,
html body .ui-group-theme-a .ui-btn:hover,
html head+body .ui-btn.ui-btn-a:hover {
  background-color: $primary-900;
  border-color: #ddd;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3
}

.ui-page-theme-a .ui-btn:active,
html .ui-bar-a .ui-btn:active,
html .ui-body-a .ui-btn:active,
html body .ui-group-theme-a .ui-btn:active,
html head+body .ui-btn.ui-btn-a:active {
  background-color: #e8e8e8;
  border-color: #ddd;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3
}

.ui-page-theme-a .ui-btn.ui-btn-active,
html .ui-bar-a .ui-btn.ui-btn-active,
html .ui-body-a .ui-btn.ui-btn-active,
html body .ui-group-theme-a .ui-btn.ui-btn-active,
html head+body .ui-btn.ui-btn-a.ui-btn-active,
.ui-page-theme-a .ui-checkbox-on:after,
html .ui-bar-a .ui-checkbox-on:after,
html .ui-body-a .ui-checkbox-on:after,
html body .ui-group-theme-a .ui-checkbox-on:after,
.ui-btn.ui-checkbox-on.ui-btn-a:after,
.ui-page-theme-a .ui-flipswitch-active,
html .ui-bar-a .ui-flipswitch-active,
html .ui-body-a .ui-flipswitch-active,
html body .ui-group-theme-a .ui-flipswitch-active,
html body .ui-flipswitch.ui-bar-a.ui-flipswitch-active,
.ui-page-theme-a .ui-slider-track .ui-btn-active,
html .ui-bar-a .ui-slider-track .ui-btn-active,
html .ui-body-a .ui-slider-track .ui-btn-active,
html body .ui-group-theme-a .ui-slider-track .ui-btn-active,
html body div.ui-slider-track.ui-body-a .ui-btn-active {
  background-color: $primary;
  border-color: $primary;
  color: $white;
  text-shadow: 0 1px 0 #059
}

.ui-page-theme-a .ui-radio-on:after,
html .ui-bar-a .ui-radio-on:after,
html .ui-body-a .ui-radio-on:after,
html body .ui-group-theme-a .ui-radio-on:after,
.ui-btn.ui-radio-on.ui-btn-a:after {
  border-color: #38c
}

.ui-page-theme-a .ui-btn:focus,
html .ui-bar-a .ui-btn:focus,
html .ui-body-a .ui-btn:focus,
html body .ui-group-theme-a .ui-btn:focus,
html head+body .ui-btn.ui-btn-a:focus,
.ui-page-theme-a .ui-focus,
html .ui-bar-a .ui-focus,
html .ui-body-a .ui-focus,
html body .ui-group-theme-a .ui-focus,
html head+body .ui-btn-a.ui-focus,
html head+body .ui-body-a.ui-focus {
  -webkit-box-shadow: 0 0 12px #38c;
  -moz-box-shadow: 0 0 12px #38c;
  box-shadow: 0 0 12px #38c
}

.ui-bar-b,
.ui-page-theme-b .ui-bar-inherit,
html .ui-bar-b .ui-bar-inherit,
html .ui-body-b .ui-bar-inherit,
html body .ui-group-theme-b .ui-bar-inherit {
  background-color: #1d1d1d;
  border-color: #1b1b1b;
  color: #fff;
  text-shadow: 0 1px 0 #111;
  font-weight: 700
}

.ui-bar-b {
  border-width: 1px;
  border-style: solid
}

.ui-overlay-b,
.ui-page-theme-b,
.ui-page-theme-b .ui-panel-wrapper {
  background-color: transparent;
  border-color: #454545;
  color: #fff;
  text-shadow: 0 1px 0 #111
}

.ui-body-b,
.ui-page-theme-b .ui-body-inherit,
html .ui-bar-b .ui-body-inherit,
html .ui-body-b .ui-body-inherit,
html body .ui-group-theme-b .ui-body-inherit,
html .ui-panel-page-container-b {
  background-color: #2a2a2a;
  border-color: #1d1d1d;
  color: #fff;
  text-shadow: 0 1px 0 #111
}

.ui-body-b {
  border-width: 1px;
  border-style: solid
}

.ui-page-theme-b a,
html .ui-bar-b a,
html .ui-body-b a,
html body .ui-group-theme-b a {
  color: #2ad;
  font-weight: 700
}

.ui-page-theme-b a:visited,
html .ui-bar-b a:visited,
html .ui-body-b a:visited,
html body .ui-group-theme-b a:visited {
  color: #2ad
}

.ui-page-theme-b a:hover,
html .ui-bar-b a:hover,
html .ui-body-b a:hover,
html body .ui-group-theme-b a:hover {
  color: #08b
}

.ui-page-theme-b a:active,
html .ui-bar-b a:active,
html .ui-body-b a:active,
html body .ui-group-theme-b a:active {
  color: #08b
}

.ui-page-theme-b .ui-btn,
html .ui-bar-b .ui-btn,
html .ui-body-b .ui-btn,
html body .ui-group-theme-b .ui-btn,
html head+body .ui-btn.ui-btn-b,
.ui-page-theme-b .ui-btn:visited,
html .ui-bar-b .ui-btn:visited,
html .ui-body-b .ui-btn:visited,
html body .ui-group-theme-b .ui-btn:visited,
html head+body .ui-btn.ui-btn-b:visited {
  background-color: #333;
  border-color: #1f1f1f;
  color: #fff;
  text-shadow: 0 1px 0 #111
}

.ui-page-theme-b .ui-btn:hover,
html .ui-bar-b .ui-btn:hover,
html .ui-body-b .ui-btn:hover,
html body .ui-group-theme-b .ui-btn:hover,
html head+body .ui-btn.ui-btn-b:hover {
  background-color: #373737;
  border-color: #1f1f1f;
  color: #fff;
  text-shadow: 0 1px 0 #111
}

.ui-page-theme-b .ui-btn:active,
html .ui-bar-b .ui-btn:active,
html .ui-body-b .ui-btn:active,
html body .ui-group-theme-b .ui-btn:active,
html head+body .ui-btn.ui-btn-b:active {
  background-color: #404040;
  border-color: #1f1f1f;
  color: #fff;
  text-shadow: 0 1px 0 #111
}

.ui-page-theme-b .ui-btn.ui-btn-active,
html .ui-bar-b .ui-btn.ui-btn-active,
html .ui-body-b .ui-btn.ui-btn-active,
html body .ui-group-theme-b .ui-btn.ui-btn-active,
html head+body .ui-btn.ui-btn-b.ui-btn-active,
.ui-page-theme-b .ui-checkbox-on:after,
html .ui-bar-b .ui-checkbox-on:after,
html .ui-body-b .ui-checkbox-on:after,
html body .ui-group-theme-b .ui-checkbox-on:after,
.ui-btn.ui-checkbox-on.ui-btn-b:after,
.ui-page-theme-b .ui-flipswitch-active,
html .ui-bar-b .ui-flipswitch-active,
html .ui-body-b .ui-flipswitch-active,
html body .ui-group-theme-b .ui-flipswitch-active,
html body .ui-flipswitch.ui-bar-b.ui-flipswitch-active,
.ui-page-theme-b .ui-slider-track .ui-btn-active,
html .ui-bar-b .ui-slider-track .ui-btn-active,
html .ui-body-b .ui-slider-track .ui-btn-active,
html body .ui-group-theme-b .ui-slider-track .ui-btn-active,
html body div.ui-slider-track.ui-body-b .ui-btn-active {
  background-color: #2ad;
  border-color: #2ad;
  color: $white;
  text-shadow: 0 1px 0 #08b
}

.ui-page-theme-b .ui-radio-on:after,
html .ui-bar-b .ui-radio-on:after,
html .ui-body-b .ui-radio-on:after,
html body .ui-group-theme-b .ui-radio-on:after,
.ui-btn.ui-radio-on.ui-btn-b:after {
  border-color: #2ad
}

.ui-page-theme-b .ui-btn:focus,
html .ui-bar-b .ui-btn:focus,
html .ui-body-b .ui-btn:focus,
html body .ui-group-theme-b .ui-btn:focus,
html head+body .ui-btn.ui-btn-b:focus,
.ui-page-theme-b .ui-focus,
html .ui-bar-b .ui-focus,
html .ui-body-b .ui-focus,
html body .ui-group-theme-b .ui-focus,
html head+body .ui-btn-b.ui-focus,
html head+body .ui-body-b.ui-focus {
  -webkit-box-shadow: 0 0 12px #2ad;
  -moz-box-shadow: 0 0 12px #2ad;
  box-shadow: 0 0 12px #2ad
}

.ui-disabled,
.ui-state-disabled,
button[disabled],
.ui-select .ui-btn.ui-state-disabled {
  filter: Alpha(Opacity=30);
  opacity: .3;
  cursor: default!important;
  pointer-events: none
}

.ui-btn:focus,
.ui-btn.ui-focus {
  outline: 0
}

.ui-noboxshadow .ui-shadow,
.ui-noboxshadow .ui-shadow-inset,
.ui-noboxshadow .ui-overlay-shadow,
.ui-noboxshadow .ui-shadow-icon.ui-btn:after,
.ui-noboxshadow .ui-shadow-icon .ui-btn:after,
.ui-noboxshadow .ui-focus,
.ui-noboxshadow .ui-btn:focus,
.ui-noboxshadow input:focus,
.ui-noboxshadow .ui-panel {
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important
}

.ui-noboxshadow .ui-btn:focus,
.ui-noboxshadow .ui-focus {
  outline-width: 1px;
  outline-style: auto
}

.ui-mobile,
.ui-mobile body {
  height: 99.9%
}

.ui-mobile fieldset,
.ui-page {
  padding: 0;
  margin: 0
}

.ui-mobile a img,
.ui-mobile fieldset {
  border-width: 0
}

.ui-mobile fieldset {
  min-width: 0
}

@-moz-document url-prefix() {
  .ui-mobile fieldset {
    display: table-column;
    vertical-align: middle
  }
}

.ui-mobile-viewport {
  margin: 0;
  overflow-x: visible;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body.ui-mobile-viewport,
div.ui-mobile-viewport {
  overflow-x: hidden
}

.ui-mobile [data-role=page],
.ui-mobile [data-role=dialog],
.ui-page {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  position: absolute;
  display: none;
  border: 0
}

.ui-page {
  outline: 0
}

.ui-mobile .ui-page-active {
  display: block;
  overflow: visible;
  overflow-x: hidden
}

@media screen and (orientation:portrait) {
  .ui-mobile .ui-page {
    min-height: 20px !important;
  }
}

@media screen and (orientation:landscape) {
  .ui-mobile .ui-page {
    min-height: 20px !important;
  }
}

.ui-mobile-rendering>* {
  visibility: hidden
}

.ui-nojs {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-loading .ui-loader {
  display: block
}

.ui-loader {
  display: none;
  z-index: 9999999;
  position: fixed;
  top: 50%;
  left: 50%;
  border: 0
}

.ui-loader-default {
  background: 0;
  filter: Alpha(Opacity=18);
  opacity: .18;
  width: 2.875em;
  height: 2.875em;
  margin-left: -1.4375em;
  margin-top: -1.4375em
}

.ui-loader-verbose {
  width: 12.5em;
  filter: Alpha(Opacity=88);
  opacity: .88;
  box-shadow: 0 1px 1px -1px #fff;
  height: auto;
  margin-left: -6.875em;
  margin-top: -2.6875em;
  padding: .625em
}

.ui-loader-default h1 {
  font-size: 0;
  width: 0;
  height: 0;
  overflow: hidden
}

.ui-loader-verbose h1 {
  font-size: 1em;
  margin: 0;
  text-align: center
}

.ui-loader .ui-icon-loading {
  background-color: #000;
  display: block;
  margin: 0;
  width: 2.75em;
  height: 2.75em;
  padding: .0625em;
  -webkit-border-radius: 2.25em;
  border-radius: 2.25em
}

.ui-loader-verbose .ui-icon-loading {
  margin: 0 auto .625em;
  filter: Alpha(Opacity=75);
  opacity: .75
}

.ui-loader-textonly {
  padding: .9375em;
  margin-left: -7.1875em
}

.ui-loader-textonly .ui-icon-loading {
  display: none
}

.ui-loader-fakefix {
  position: absolute
}

.ui-bar,
.ui-body {
  position: relative;
  padding: .4em 1em;
  overflow: hidden;
  display: block;
  clear: both
}

.ui-bar h1,
.ui-bar h2,
.ui-bar h3,
.ui-bar h4,
.ui-bar h5,
.ui-bar h6 {
  margin: 0;
  padding: 0;
  font-size: 1em;
  display: inline-block
}

.ui-header,
.ui-footer {
  border-width: 1px 0;
  border-style: solid;
  position: relative
}

.ui-header:empty,
.ui-footer:empty {
  min-height: 2.6875em
}

.ui-header .ui-title,
.ui-footer .ui-title {
  font-size: 1em;
  min-height: 1.1em;
  text-align: center;
  display: block;
  margin: 0 30%;
  padding: .7em 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  outline: 0!important
}

.ui-footer .ui-title {
  margin: 0 1em
}

.ui-content {
  border-width: 0;
  overflow: visible;
  overflow-x: hidden;
  padding: 1em
}

.ui-corner-all>.ui-header:first-child,
.ui-corner-all>.ui-content:first-child,
.ui-corner-all>.ui-footer:first-child {
  -webkit-border-top-left-radius: inherit;
  border-top-left-radius: inherit;
  -webkit-border-top-right-radius: inherit;
  border-top-right-radius: inherit
}

.ui-corner-all>.ui-header:last-child,
.ui-corner-all>.ui-content:last-child,
.ui-corner-all>.ui-footer:last-child {
  -webkit-border-bottom-left-radius: inherit;
  border-bottom-left-radius: inherit;
  -webkit-border-bottom-right-radius: inherit;
  border-bottom-right-radius: inherit
}

.ui-btn {
  font-size: 16px;
  margin: .5em 0;
  padding: .7em 1em;
  display: block;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.ui-btn-icon-notext,
.ui-header button.ui-btn.ui-btn-icon-notext,
.ui-footer button.ui-btn.ui-btn-icon-notext {
  padding: 0;
  width: 1.75em;
  height: 1.75em;
  text-indent: -9999px;
  white-space: nowrap!important
}

.ui-mini {
  font-size: 12.5px
}

.ui-mini .ui-btn {
  font-size: inherit
}

.ui-header .ui-btn,
.ui-footer .ui-btn {
  font-size: 12.5px;
  display: inline-block;
  vertical-align: middle
}

.ui-header .ui-controlgroup .ui-btn-icon-notext,
.ui-footer .ui-controlgroup .ui-btn-icon-notext {
  font-size: 12.5px
}

.ui-header .ui-btn-left,
.ui-header .ui-btn-right {
  font-size: 12.5px
}

.ui-mini.ui-btn-icon-notext,
.ui-mini .ui-btn-icon-notext,
.ui-header .ui-btn-icon-notext,
.ui-footer .ui-btn-icon-notext {
  font-size: 16px;
  padding: 0
}

.ui-btn-inline {
  display: inline-block;
  vertical-align: middle;
  margin-right: .625em
}

.ui-btn-icon-left {
  padding-left: 2.5em
}

.ui-btn-icon-right {
  padding-right: 2.5em
}

.ui-btn-icon-top {
  padding-top: 2.5em
}

.ui-btn-icon-bottom {
  padding-bottom: 2.5em
}

.ui-header .ui-btn-icon-top,
.ui-footer .ui-btn-icon-top,
.ui-header .ui-btn-icon-bottom,
.ui-footer .ui-btn-icon-bottom {
  padding-left: .3125em;
  padding-right: .3125em
}

.ui-btn-icon-left:after,
.ui-btn-icon-right:after,
.ui-btn-icon-top:after,
.ui-btn-icon-bottom:after,
.ui-btn-icon-notext:after {
  content: "";
  position: absolute;
  display: block;
  width: 22px;
  height: 22px
}

.ui-btn-icon-notext:after,
.ui-btn-icon-left:after,
.ui-btn-icon-right:after {
  top: 50%;
  margin-top: -11px
}

.ui-btn-icon-left:after {
  left: .5625em
}

.ui-btn-icon-right:after {
  right: .5625em
}

.ui-mini.ui-btn-icon-left:after,
.ui-mini .ui-btn-icon-left:after,
.ui-header .ui-btn-icon-left:after,
.ui-footer .ui-btn-icon-left:after {
  left: .37em
}

.ui-mini.ui-btn-icon-right:after,
.ui-mini .ui-btn-icon-right:after,
.ui-header .ui-btn-icon-right:after,
.ui-footer .ui-btn-icon-right:after {
  right: .37em
}

.ui-btn-icon-notext:after,
.ui-btn-icon-top:after,
.ui-btn-icon-bottom:after {
  left: 50%;
  margin-left: -11px
}

.ui-btn-icon-top:after {
  top: .5625em
}

.ui-btn-icon-bottom:after {
  top: auto;
  bottom: .5625em
}

.ui-header .ui-btn-left,
.ui-header .ui-btn-right,
.ui-btn-left>[class*=ui-],
.ui-btn-right>[class*=ui-] {
  margin: 0
}

.ui-btn-left,
.ui-btn-right {
  position: absolute;
  top: .24em
}

.ui-btn-left {
  left: .4em
}

.ui-btn-right {
  right: .4em
}

.ui-btn-icon-notext.ui-btn-left {
  top: .3125em;
  left: .3125em
}

.ui-btn-icon-notext.ui-btn-right {
  top: .3125em;
  right: .3125em
}

button.ui-btn,
.ui-controlgroup-controls button.ui-btn-icon-notext {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%
}

button.ui-btn-inline,
.ui-header button.ui-btn,
.ui-footer button.ui-btn {
  width: auto
}

button.ui-btn::-moz-focus-inner {
  border: 0
}

button.ui-btn-icon-notext,
.ui-controlgroup-horizontal .ui-controlgroup-controls button.ui-btn {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 1.75em
}

.ui-screen-hidden {
  display: none!important
}

.ui-mobile-viewport-transitioning,
.ui-mobile-viewport-transitioning .ui-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.ui-page-pre-in {
  opacity: 0
}

.in {
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 350ms;
  -moz-animation-timing-function: ease-out;
  -moz-animation-duration: 350ms;
  animation-timing-function: ease-out;
  animation-duration: 350ms
}

.out {
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-duration: 225ms;
  -moz-animation-timing-function: ease-in;
  -moz-animation-duration: 225ms;
  animation-timing-function: ease-in;
  animation-duration: 225ms
}

@-webkit-keyframes fadein {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadein {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

@-moz-keyframes fadeout {
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

@keyframes fadeout {
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

.fade.out {
  opacity: 0;
  -webkit-animation-duration: 125ms;
  -webkit-animation-name: fadeout;
  -moz-animation-duration: 125ms;
  -moz-animation-name: fadeout;
  animation-duration: 125ms;
  animation-name: fadeout
}

.fade.in {
  opacity: 1;
  -webkit-animation-duration: 225ms;
  -webkit-animation-name: fadein;
  -moz-animation-duration: 225ms;
  -moz-animation-name: fadein;
  animation-duration: 225ms;
  animation-name: fadein
}

.pop {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.pop.in {
  -webkit-transform: scale(1);
  -webkit-animation-name: popin;
  -webkit-animation-duration: 350ms;
  -moz-transform: scale(1);
  -moz-animation-name: popin;
  -moz-animation-duration: 350ms;
  transform: scale(1);
  animation-name: popin;
  animation-duration: 350ms;
  opacity: 1
}

.pop.out {
  -webkit-animation-name: fadeout;
  -webkit-animation-duration: 100ms;
  -moz-animation-name: fadeout;
  -moz-animation-duration: 100ms;
  animation-name: fadeout;
  animation-duration: 100ms;
  opacity: 0
}

.pop.in.reverse {
  -webkit-animation-name: fadein;
  -moz-animation-name: fadein;
  animation-name: fadein
}

.pop.out.reverse {
  -webkit-transform: scale(.8);
  -webkit-animation-name: popout;
  -moz-transform: scale(.8);
  -moz-animation-name: popout;
  transform: scale(.8);
  animation-name: popout
}

@-webkit-keyframes popin {
  from {
    -webkit-transform: scale(.8);
    opacity: 0
  }
  to {
    -webkit-transform: scale(1);
    opacity: 1
  }
}

@-moz-keyframes popin {
  from {
    -moz-transform: scale(.8);
    opacity: 0
  }
  to {
    -moz-transform: scale(1);
    opacity: 1
  }
}

@keyframes popin {
  from {
    transform: scale(.8);
    opacity: 0
  }
  to {
    transform: scale(1);
    opacity: 1
  }
}

@-webkit-keyframes popout {
  from {
    -webkit-transform: scale(1);
    opacity: 1
  }
  to {
    -webkit-transform: scale(.8);
    opacity: 0
  }
}

@-moz-keyframes popout {
  from {
    -moz-transform: scale(1);
    opacity: 1
  }
  to {
    -moz-transform: scale(.8);
    opacity: 0
  }
}

@keyframes popout {
  from {
    transform: scale(1);
    opacity: 1
  }
  to {
    transform: scale(.8);
    opacity: 0
  }
}

@-webkit-keyframes slideinfromright {
  from {
    -webkit-transform: translate3d(100%, 0, 0)
  }
  to {
    -webkit-transform: translate3d(0, 0, 0)
  }
}

@-moz-keyframes slideinfromright {
  from {
    -moz-transform: translateX(100%)
  }
  to {
    -moz-transform: translateX(0)
  }
}

@keyframes slideinfromright {
  from {
    transform: translateX(100%)
  }
  to {
    transform: translateX(0)
  }
}

@-webkit-keyframes slideinfromleft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0)
  }
  to {
    -webkit-transform: translate3d(0, 0, 0)
  }
}

@-moz-keyframes slideinfromleft {
  from {
    -moz-transform: translateX(-100%)
  }
  to {
    -moz-transform: translateX(0)
  }
}

@keyframes slideinfromleft {
  from {
    transform: translateX(-100%)
  }
  to {
    transform: translateX(0)
  }
}

@-webkit-keyframes slideouttoleft {
  from {
    -webkit-transform: translate3d(0, 0, 0)
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0)
  }
}

@-moz-keyframes slideouttoleft {
  from {
    -moz-transform: translateX(0)
  }
  to {
    -moz-transform: translateX(-100%)
  }
}

@keyframes slideouttoleft {
  from {
    transform: translateX(0)
  }
  to {
    transform: translateX(-100%)
  }
}

@-webkit-keyframes slideouttoright {
  from {
    -webkit-transform: translate3d(0, 0, 0)
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0)
  }
}

@-moz-keyframes slideouttoright {
  from {
    -moz-transform: translateX(0)
  }
  to {
    -moz-transform: translateX(100%)
  }
}

@keyframes slideouttoright {
  from {
    transform: translateX(0)
  }
  to {
    transform: translateX(100%)
  }
}

.slide.out,
.slide.in {
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 350ms;
  -moz-animation-timing-function: ease-out;
  -moz-animation-duration: 350ms;
  animation-timing-function: ease-out;
  animation-duration: 350ms
}

.slide.out {
  -webkit-transform: translate3d(-100%, 0, 0);
  -webkit-animation-name: slideouttoleft;
  -moz-transform: translateX(-100%);
  -moz-animation-name: slideouttoleft;
  transform: translateX(-100%);
  animation-name: slideouttoleft
}

.slide.in {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-animation-name: slideinfromright;
  -moz-transform: translateX(0);
  -moz-animation-name: slideinfromright;
  transform: translateX(0);
  animation-name: slideinfromright
}

.slide.out.reverse {
  -webkit-transform: translate3d(100%, 0, 0);
  -webkit-animation-name: slideouttoright;
  -moz-transform: translateX(100%);
  -moz-animation-name: slideouttoright;
  transform: translateX(100%);
  animation-name: slideouttoright
}

.slide.in.reverse {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-animation-name: slideinfromleft;
  -moz-transform: translateX(0);
  -moz-animation-name: slideinfromleft;
  transform: translateX(0);
  animation-name: slideinfromleft
}

.slidefade.out {
  -webkit-transform: translateX(-100%);
  -webkit-animation-name: slideouttoleft;
  -webkit-animation-duration: 225ms;
  -moz-transform: translateX(-100%);
  -moz-animation-name: slideouttoleft;
  -moz-animation-duration: 225ms;
  transform: translateX(-100%);
  animation-name: slideouttoleft;
  animation-duration: 225ms
}

.slidefade.in {
  -webkit-transform: translateX(0);
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 200ms;
  -moz-transform: translateX(0);
  -moz-animation-name: fadein;
  -moz-animation-duration: 200ms;
  transform: translateX(0);
  animation-name: fadein;
  animation-duration: 200ms
}

.slidefade.out.reverse {
  -webkit-transform: translateX(100%);
  -webkit-animation-name: slideouttoright;
  -webkit-animation-duration: 200ms;
  -moz-transform: translateX(100%);
  -moz-animation-name: slideouttoright;
  -moz-animation-duration: 200ms;
  transform: translateX(100%);
  animation-name: slideouttoright;
  animation-duration: 200ms
}

.slidefade.in.reverse {
  -webkit-transform: translateX(0);
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 200ms;
  -moz-transform: translateX(0);
  -moz-animation-name: fadein;
  -moz-animation-duration: 200ms;
  transform: translateX(0);
  animation-name: fadein;
  animation-duration: 200ms
}

.slidedown.out {
  -webkit-animation-name: fadeout;
  -webkit-animation-duration: 100ms;
  -moz-animation-name: fadeout;
  -moz-animation-duration: 100ms;
  animation-name: fadeout;
  animation-duration: 100ms
}

.slidedown.in {
  -webkit-transform: translateY(0);
  -webkit-animation-name: slideinfromtop;
  -webkit-animation-duration: 250ms;
  -moz-transform: translateY(0);
  -moz-animation-name: slideinfromtop;
  -moz-animation-duration: 250ms;
  transform: translateY(0);
  animation-name: slideinfromtop;
  animation-duration: 250ms
}

.slidedown.in.reverse {
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 150ms;
  -moz-animation-name: fadein;
  -moz-animation-duration: 150ms;
  animation-name: fadein;
  animation-duration: 150ms
}

.slidedown.out.reverse {
  -webkit-transform: translateY(-100%);
  -webkit-animation-name: slideouttotop;
  -webkit-animation-duration: 200ms;
  -moz-transform: translateY(-100%);
  -moz-animation-name: slideouttotop;
  -moz-animation-duration: 200ms;
  transform: translateY(-100%);
  animation-name: slideouttotop;
  animation-duration: 200ms
}

@-webkit-keyframes slideinfromtop {
  from {
    -webkit-transform: translateY(-100%)
  }
  to {
    -webkit-transform: translateY(0)
  }
}

@-moz-keyframes slideinfromtop {
  from {
    -moz-transform: translateY(-100%)
  }
  to {
    -moz-transform: translateY(0)
  }
}

@keyframes slideinfromtop {
  from {
    transform: translateY(-100%)
  }
  to {
    transform: translateY(0)
  }
}

@-webkit-keyframes slideouttotop {
  from {
    -webkit-transform: translateY(0)
  }
  to {
    -webkit-transform: translateY(-100%)
  }
}

@-moz-keyframes slideouttotop {
  from {
    -moz-transform: translateY(0)
  }
  to {
    -moz-transform: translateY(-100%)
  }
}

@keyframes slideouttotop {
  from {
    transform: translateY(0)
  }
  to {
    transform: translateY(-100%)
  }
}

.slideup.out {
  -webkit-animation-name: fadeout;
  -webkit-animation-duration: 100ms;
  -moz-animation-name: fadeout;
  -moz-animation-duration: 100ms;
  animation-name: fadeout;
  animation-duration: 100ms
}

.slideup.in {
  -webkit-transform: translateY(0);
  -webkit-animation-name: slideinfrombottom;
  -webkit-animation-duration: 250ms;
  -moz-transform: translateY(0);
  -moz-animation-name: slideinfrombottom;
  -moz-animation-duration: 250ms;
  transform: translateY(0);
  animation-name: slideinfrombottom;
  animation-duration: 250ms
}

.slideup.in.reverse {
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 150ms;
  -moz-animation-name: fadein;
  -moz-animation-duration: 150ms;
  animation-name: fadein;
  animation-duration: 150ms
}

.slideup.out.reverse {
  -webkit-transform: translateY(100%);
  -webkit-animation-name: slideouttobottom;
  -webkit-animation-duration: 200ms;
  -moz-transform: translateY(100%);
  -moz-animation-name: slideouttobottom;
  -moz-animation-duration: 200ms;
  transform: translateY(100%);
  animation-name: slideouttobottom;
  animation-duration: 200ms
}

@-webkit-keyframes slideinfrombottom {
  from {
    -webkit-transform: translateY(100%)
  }
  to {
    -webkit-transform: translateY(0)
  }
}

@-moz-keyframes slideinfrombottom {
  from {
    -moz-transform: translateY(100%)
  }
  to {
    -moz-transform: translateY(0)
  }
}

@keyframes slideinfrombottom {
  from {
    transform: translateY(100%)
  }
  to {
    transform: translateY(0)
  }
}

@-webkit-keyframes slideouttobottom {
  from {
    -webkit-transform: translateY(0)
  }
  to {
    -webkit-transform: translateY(100%)
  }
}

@-moz-keyframes slideouttobottom {
  from {
    -moz-transform: translateY(0)
  }
  to {
    -moz-transform: translateY(100%)
  }
}

@keyframes slideouttobottom {
  from {
    transform: translateY(0)
  }
  to {
    transform: translateY(100%)
  }
}

.viewport-flip {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
  position: absolute
}

.flip {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateX(0);
  -moz-backface-visibility: hidden;
  -moz-transform: translateX(0);
  backface-visibility: hidden;
  transform: translateX(0)
}

.flip.out {
  -webkit-transform: rotateY(-90deg) scale(.9);
  -webkit-animation-name: flipouttoleft;
  -webkit-animation-duration: 175ms;
  -moz-transform: rotateY(-90deg) scale(.9);
  -moz-animation-name: flipouttoleft;
  -moz-animation-duration: 175ms;
  transform: rotateY(-90deg) scale(.9);
  animation-name: flipouttoleft;
  animation-duration: 175ms
}

.flip.in {
  -webkit-animation-name: flipintoright;
  -webkit-animation-duration: 225ms;
  -moz-animation-name: flipintoright;
  -moz-animation-duration: 225ms;
  animation-name: flipintoright;
  animation-duration: 225ms
}

.flip.out.reverse {
  -webkit-transform: rotateY(90deg) scale(.9);
  -webkit-animation-name: flipouttoright;
  -moz-transform: rotateY(90deg) scale(.9);
  -moz-animation-name: flipouttoright;
  transform: rotateY(90deg) scale(.9);
  animation-name: flipouttoright
}

.flip.in.reverse {
  -webkit-animation-name: flipintoleft;
  -moz-animation-name: flipintoleft;
  animation-name: flipintoleft
}

@-webkit-keyframes flipouttoleft {
  from {
    -webkit-transform: rotateY(0)
  }
  to {
    -webkit-transform: rotateY(-90deg) scale(.9)
  }
}

@-moz-keyframes flipouttoleft {
  from {
    -moz-transform: rotateY(0)
  }
  to {
    -moz-transform: rotateY(-90deg) scale(.9)
  }
}

@keyframes flipouttoleft {
  from {
    transform: rotateY(0)
  }
  to {
    transform: rotateY(-90deg) scale(.9)
  }
}

@-webkit-keyframes flipouttoright {
  from {
    -webkit-transform: rotateY(0)
  }
  to {
    -webkit-transform: rotateY(90deg) scale(.9)
  }
}

@-moz-keyframes flipouttoright {
  from {
    -moz-transform: rotateY(0)
  }
  to {
    -moz-transform: rotateY(90deg) scale(.9)
  }
}

@keyframes flipouttoright {
  from {
    transform: rotateY(0)
  }
  to {
    transform: rotateY(90deg) scale(.9)
  }
}

@-webkit-keyframes flipintoleft {
  from {
    -webkit-transform: rotateY(-90deg) scale(.9)
  }
  to {
    -webkit-transform: rotateY(0)
  }
}

@-moz-keyframes flipintoleft {
  from {
    -moz-transform: rotateY(-90deg) scale(.9)
  }
  to {
    -moz-transform: rotateY(0)
  }
}

@keyframes flipintoleft {
  from {
    transform: rotateY(-90deg) scale(.9)
  }
  to {
    transform: rotateY(0)
  }
}

@-webkit-keyframes flipintoright {
  from {
    -webkit-transform: rotateY(90deg) scale(.9)
  }
  to {
    -webkit-transform: rotateY(0)
  }
}

@-moz-keyframes flipintoright {
  from {
    -moz-transform: rotateY(90deg) scale(.9)
  }
  to {
    -moz-transform: rotateY(0)
  }
}

@keyframes flipintoright {
  from {
    transform: rotateY(90deg) scale(.9)
  }
  to {
    transform: rotateY(0)
  }
}

.viewport-turn {
  -webkit-perspective: 200px;
  -moz-perspective: 200px;
  -ms-perspective: 200px;
  perspective: 200px;
  position: absolute
}

.turn {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateX(0);
  -webkit-transform-origin: 0;
  -moz-backface-visibility: hidden;
  -moz-transform: translateX(0);
  -moz-transform-origin: 0;
  backface-visibility: hidden;
  transform: translateX(0);
  transform-origin: 0
}

.turn.out {
  -webkit-transform: rotateY(-90deg) scale(.9);
  -webkit-animation-name: flipouttoleft;
  -webkit-animation-duration: 125ms;
  -moz-transform: rotateY(-90deg) scale(.9);
  -moz-animation-name: flipouttoleft;
  -moz-animation-duration: 125ms;
  transform: rotateY(-90deg) scale(.9);
  animation-name: flipouttoleft;
  animation-duration: 125ms
}

.turn.in {
  -webkit-animation-name: flipintoright;
  -webkit-animation-duration: 250ms;
  -moz-animation-name: flipintoright;
  -moz-animation-duration: 250ms;
  animation-name: flipintoright;
  animation-duration: 250ms
}

.turn.out.reverse {
  -webkit-transform: rotateY(90deg) scale(.9);
  -webkit-animation-name: flipouttoright;
  -moz-transform: rotateY(90deg) scale(.9);
  -moz-animation-name: flipouttoright;
  transform: rotateY(90deg) scale(.9);
  animation-name: flipouttoright
}

.turn.in.reverse {
  -webkit-animation-name: flipintoleft;
  -moz-animation-name: flipintoleft;
  animation-name: flipintoleft
}

@-webkit-keyframes flipouttoleft {
  from {
    -webkit-transform: rotateY(0)
  }
  to {
    -webkit-transform: rotateY(-90deg) scale(.9)
  }
}

@-moz-keyframes flipouttoleft {
  from {
    -moz-transform: rotateY(0)
  }
  to {
    -moz-transform: rotateY(-90deg) scale(.9)
  }
}

@keyframes flipouttoleft {
  from {
    transform: rotateY(0)
  }
  to {
    transform: rotateY(-90deg) scale(.9)
  }
}

@-webkit-keyframes flipouttoright {
  from {
    -webkit-transform: rotateY(0)
  }
  to {
    -webkit-transform: rotateY(90deg) scale(.9)
  }
}

@-moz-keyframes flipouttoright {
  from {
    -moz-transform: rotateY(0)
  }
  to {
    -moz-transform: rotateY(90deg) scale(.9)
  }
}

@keyframes flipouttoright {
  from {
    transform: rotateY(0)
  }
  to {
    transform: rotateY(90deg) scale(.9)
  }
}

@-webkit-keyframes flipintoleft {
  from {
    -webkit-transform: rotateY(-90deg) scale(.9)
  }
  to {
    -webkit-transform: rotateY(0)
  }
}

@-moz-keyframes flipintoleft {
  from {
    -moz-transform: rotateY(-90deg) scale(.9)
  }
  to {
    -moz-transform: rotateY(0)
  }
}

@keyframes flipintoleft {
  from {
    transform: rotateY(-90deg) scale(.9)
  }
  to {
    transform: rotateY(0)
  }
}

@-webkit-keyframes flipintoright {
  from {
    -webkit-transform: rotateY(90deg) scale(.9)
  }
  to {
    -webkit-transform: rotateY(0)
  }
}

@-moz-keyframes flipintoright {
  from {
    -moz-transform: rotateY(90deg) scale(.9)
  }
  to {
    -moz-transform: rotateY(0)
  }
}

@keyframes flipintoright {
  from {
    transform: rotateY(90deg) scale(.9)
  }
  to {
    transform: rotateY(0)
  }
}

.flow {
  -webkit-transform-origin: 50% 30%;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .4);
  -moz-transform-origin: 50% 30%;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .4);
  transform-origin: 50% 30%;
  box-shadow: 0 0 20px rgba(0, 0, 0, .4)
}

.ui-dialog.flow {
  -webkit-transform-origin: none;
  -webkit-box-shadow: none;
  -moz-transform-origin: none;
  -moz-box-shadow: none;
  transform-origin: none;
  box-shadow: none
}

.flow.out {
  -webkit-transform: translateX(-100%) scale(.7);
  -webkit-animation-name: flowouttoleft;
  -webkit-animation-timing-function: ease;
  -webkit-animation-duration: 350ms;
  -moz-transform: translateX(-100%) scale(.7);
  -moz-animation-name: flowouttoleft;
  -moz-animation-timing-function: ease;
  -moz-animation-duration: 350ms;
  transform: translateX(-100%) scale(.7);
  animation-name: flowouttoleft;
  animation-timing-function: ease;
  animation-duration: 350ms
}

.flow.in {
  -webkit-transform: translateX(0) scale(1);
  -webkit-animation-name: flowinfromright;
  -webkit-animation-timing-function: ease;
  -webkit-animation-duration: 350ms;
  -moz-transform: translateX(0) scale(1);
  -moz-animation-name: flowinfromright;
  -moz-animation-timing-function: ease;
  -moz-animation-duration: 350ms;
  transform: translateX(0) scale(1);
  animation-name: flowinfromright;
  animation-timing-function: ease;
  animation-duration: 350ms
}

.flow.out.reverse {
  -webkit-transform: translateX(100%);
  -webkit-animation-name: flowouttoright;
  -moz-transform: translateX(100%);
  -moz-animation-name: flowouttoright;
  transform: translateX(100%);
  animation-name: flowouttoright
}

.flow.in.reverse {
  -webkit-animation-name: flowinfromleft;
  -moz-animation-name: flowinfromleft;
  animation-name: flowinfromleft
}

@-webkit-keyframes flowouttoleft {
  0% {
    -webkit-transform: translateX(0) scale(1)
  }
  60%,
  70% {
    -webkit-transform: translateX(0) scale(.7)
  }
  100% {
    -webkit-transform: translateX(-100%) scale(.7)
  }
}

@-moz-keyframes flowouttoleft {
  0% {
    -moz-transform: translateX(0) scale(1)
  }
  60%,
  70% {
    -moz-transform: translateX(0) scale(.7)
  }
  100% {
    -moz-transform: translateX(-100%) scale(.7)
  }
}

@keyframes flowouttoleft {
  0% {
    transform: translateX(0) scale(1)
  }
  60%,
  70% {
    transform: translateX(0) scale(.7)
  }
  100% {
    transform: translateX(-100%) scale(.7)
  }
}

@-webkit-keyframes flowouttoright {
  0% {
    -webkit-transform: translateX(0) scale(1)
  }
  60%,
  70% {
    -webkit-transform: translateX(0) scale(.7)
  }
  100% {
    -webkit-transform: translateX(100%) scale(.7)
  }
}

@-moz-keyframes flowouttoright {
  0% {
    -moz-transform: translateX(0) scale(1)
  }
  60%,
  70% {
    -moz-transform: translateX(0) scale(.7)
  }
  100% {
    -moz-transform: translateX(100%) scale(.7)
  }
}

@keyframes flowouttoright {
  0% {
    transform: translateX(0) scale(1)
  }
  60%,
  70% {
    transform: translateX(0) scale(.7)
  }
  100% {
    transform: translateX(100%) scale(.7)
  }
}

@-webkit-keyframes flowinfromleft {
  0% {
    -webkit-transform: translateX(-100%) scale(.7)
  }
  30%,
  40% {
    -webkit-transform: translateX(0) scale(.7)
  }
  100% {
    -webkit-transform: translateX(0) scale(1)
  }
}

@-moz-keyframes flowinfromleft {
  0% {
    -moz-transform: translateX(-100%) scale(.7)
  }
  30%,
  40% {
    -moz-transform: translateX(0) scale(.7)
  }
  100% {
    -moz-transform: translateX(0) scale(1)
  }
}

@keyframes flowinfromleft {
  0% {
    transform: translateX(-100%) scale(.7)
  }
  30%,
  40% {
    transform: translateX(0) scale(.7)
  }
  100% {
    transform: translateX(0) scale(1)
  }
}

@-webkit-keyframes flowinfromright {
  0% {
    -webkit-transform: translateX(100%) scale(.7)
  }
  30%,
  40% {
    -webkit-transform: translateX(0) scale(.7)
  }
  100% {
    -webkit-transform: translateX(0) scale(1)
  }
}

@-moz-keyframes flowinfromright {
  0% {
    -moz-transform: translateX(100%) scale(.7)
  }
  30%,
  40% {
    -moz-transform: translateX(0) scale(.7)
  }
  100% {
    -moz-transform: translateX(0) scale(1)
  }
}

@keyframes flowinfromright {
  0% {
    transform: translateX(100%) scale(.7)
  }
  30%,
  40% {
    transform: translateX(0) scale(.7)
  }
  100% {
    transform: translateX(0) scale(1)
  }
}

.ui-field-contain,
.ui-mobile fieldset.ui-field-contain {
  display: block;
  position: relative;
  overflow: visible;
  clear: both;
  padding: .8em 0
}

.ui-field-contain>label~[class*=ui-],
.ui-field-contain .ui-controlgroup-controls {
  margin: 0
}

.ui-field-contain:last-child {
  border-bottom-width: 0
}

@media (min-width:28em) {
  .ui-field-contain,
  .ui-mobile fieldset.ui-field-contain {
    padding: 0;
    margin: 1em 0;
    border-bottom-width: 0
  }
  .ui-field-contain:before,
  .ui-field-contain:after {
    content: "";
    display: table
  }
  .ui-field-contain:after {
    clear: both
  }
  .ui-field-contain>label,
  .ui-field-contain .ui-controlgroup-label,
  .ui-field-contain>.ui-rangeslider>label {
    float: left;
    width: 20%;
    margin: .5em 2% 0 0
  }
  .ui-popup .ui-field-contain>label,
  .ui-popup .ui-field-contain .ui-controlgroup-label,
  .ui-popup .ui-field-contain>.ui-rangeslider>label {
    float: none;
    width: auto;
    margin: 0 0 .4em
  }
  .ui-field-contain>label~[class*=ui-],
  .ui-field-contain .ui-controlgroup-controls {
    float: left;
    width: 78%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
  }
  .ui-hide-label>label~[class*=ui-],
  .ui-hide-label .ui-controlgroup-controls,
  .ui-popup .ui-field-contain>label~[class*=ui-],
  .ui-popup .ui-field-contain .ui-controlgroup-controls {
    float: none;
    width: 100%
  }
  .ui-field-contain>label~.ui-btn-inline {
    width: auto;
    margin-right: .625em
  }
  .ui-field-contain>label~.ui-btn-inline.ui-btn-icon-notext {
    width: 1.75em
  }
}

.ui-grid-a,
.ui-grid-b,
.ui-grid-c,
.ui-grid-d,
.ui-grid-solo {
  overflow: hidden
}

.ui-block-a,
.ui-block-b,
.ui-block-c,
.ui-block-d,
.ui-block-e {
  margin: 0;
  padding: 0;
  border: 0;
  float: left;
  min-height: 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.ui-block-a {
  clear: left
}

ul.ui-grid-a,
ul.ui-grid-b,
ul.ui-grid-c,
ul.ui-grid-d,
ul.ui-grid-solo,
li.ui-block-a,
li.ui-block-b,
li.ui-block-c,
li.ui-block-d,
li.ui-block-e {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  list-style: none
}

[class*=ui-block-]>button.ui-btn {
  margin-right: 0;
  margin-left: 0
}

[class*=ui-block-]>.ui-btn,
[class*=ui-block-]>.ui-select,
[class*=ui-block-]>.ui-checkbox,
[class*=ui-block-]>.ui-radio,
[class*=ui-block-]>button.ui-btn-inline,
[class*=ui-block-]>button.ui-btn-icon-notext,
.ui-header [class*=ui-block-]>button.ui-btn,
.ui-footer [class*=ui-block-]>button.ui-btn {
  margin-right: .3125em;
  margin-left: .3125em
}

.ui-grid-a>.ui-block-a,
.ui-grid-a>.ui-block-b {
  width: 50%
}

.ui-grid-b>.ui-block-a,
.ui-grid-b>.ui-block-b,
.ui-grid-b>.ui-block-c {
  width: 33.333%
}

.ui-grid-c>.ui-block-a,
.ui-grid-c>.ui-block-b,
.ui-grid-c>.ui-block-c,
.ui-grid-c>.ui-block-d {
  width: 25%
}

.ui-grid-d>.ui-block-a,
.ui-grid-d>.ui-block-b,
.ui-grid-d>.ui-block-c,
.ui-grid-d>.ui-block-d,
.ui-grid-d>.ui-block-e {
  width: 20%
}

.ui-grid-solo>.ui-block-a {
  width: 100%;
  float: none
}

@media (max-width:35em) {
  .ui-responsive>.ui-block-a,
  .ui-responsive>.ui-block-b,
  .ui-responsive>.ui-block-c,
  .ui-responsive>.ui-block-d,
  .ui-responsive>.ui-block-e {
    width: 100%;
    float: none
  }
}

.ui-header-fixed,
.ui-footer-fixed {
  left: 0;
  right: 0;
  width: 100%;
  position: fixed;
  z-index: 1000
}

.ui-header-fixed {
  top: -1px;
  padding-top: 1px
}

.ui-header-fixed.ui-fixed-hidden {
  top: 0;
  padding-top: 0
}

.ui-header-fixed .ui-btn-left,
.ui-header-fixed .ui-btn-right {
  margin-top: 1px
}

.ui-header-fixed.ui-fixed-hidden .ui-btn-left,
.ui-header-fixed.ui-fixed-hidden .ui-btn-right {
  margin-top: 0
}

.ui-footer-fixed {
  bottom: -1px;
  padding-bottom: 1px
}

.ui-footer-fixed.ui-fixed-hidden {
  bottom: 0;
  padding-bottom: 0
}

.ui-header-fullscreen,
.ui-footer-fullscreen {
  filter: Alpha(Opacity=90);
  opacity: .9
}

.ui-page-header-fixed {
  padding-top: 2.8125em
}

.ui-page-footer-fixed {
  padding-bottom: 2.8125em
}

.ui-page-header-fullscreen>.ui-content,
.ui-page-footer-fullscreen>.ui-content {
  padding: 0
}

.ui-fixed-hidden {
  position: absolute
}

.ui-footer-fixed.ui-fixed-hidden {
  display: none
}

.ui-page .ui-footer-fixed.ui-fixed-hidden {
  display: block
}

.ui-page-header-fullscreen .ui-fixed-hidden,
.ui-page-footer-fullscreen .ui-fixed-hidden {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-header-fixed .ui-btn,
.ui-footer-fixed .ui-btn {
  z-index: 10
}

.ui-android-2x-fixed .ui-li-has-thumb {
  -webkit-transform: translate3d(0, 0, 0)
}

.ui-navbar {
  max-width: 100%
}

.ui-navbar ul:before,
.ui-navbar ul:after {
  content: "";
  display: table
}

.ui-navbar ul:after {
  clear: both
}

.ui-navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  border: 0;
  max-width: 100%;
  overflow: visible
}

.ui-navbar li .ui-btn {
  font-size: 12.5px;
  display: block;
  margin: 0;
  border-right-width: 0
}

.ui-header .ui-navbar li button.ui-btn,
.ui-footer .ui-navbar li button.ui-btn {
  margin: 0;
  width: 100%
}

.ui-navbar .ui-btn:focus {
  z-index: 1
}

.ui-navbar li:last-child .ui-btn {
  margin-right: -4px
}

.ui-navbar li:last-child .ui-btn:after {
  margin-right: 4px
}

.ui-content .ui-navbar li:last-child .ui-btn,
.ui-content .ui-navbar .ui-grid-duo .ui-block-b .ui-btn {
  border-right-width: 1px;
  margin-right: 0
}

.ui-content .ui-navbar li:last-child .ui-btn:after,
.ui-content .ui-navbar .ui-grid-duo .ui-block-b .ui-btn:after {
  margin-right: 0
}

.ui-navbar .ui-grid-duo .ui-block-a:last-child .ui-btn {
  border-right-width: 1px;
  margin-right: -1px
}

.ui-navbar .ui-grid-duo .ui-block-a:last-child .ui-btn:after {
  margin-right: 1px
}

.ui-navbar .ui-grid-duo .ui-btn {
  border-top-width: 0
}

.ui-navbar .ui-grid-duo .ui-block-a:first-child .ui-btn,
.ui-navbar .ui-grid-duo .ui-block-a:first-child+.ui-block-b .ui-btn {
  border-top-width: 1px
}

.ui-header .ui-navbar .ui-btn,
.ui-footer .ui-navbar .ui-btn {
  border-top-width: 0;
  border-bottom-width: 0
}

.ui-header .ui-navbar .ui-grid-duo .ui-block-a:first-child .ui-btn,
.ui-footer .ui-navbar .ui-grid-duo .ui-block-a:first-child .ui-btn,
.ui-header .ui-navbar .ui-grid-duo .ui-block-a:first-child+.ui-block-b .ui-btn,
.ui-footer .ui-navbar .ui-grid-duo .ui-block-a:first-child+.ui-block-b .ui-btn {
  border-top-width: 0
}

.ui-header .ui-title~.ui-navbar .ui-btn,
.ui-footer .ui-title~.ui-navbar .ui-btn,
.ui-header .ui-navbar .ui-grid-duo .ui-btn,
.ui-footer .ui-navbar .ui-grid-duo .ui-btn,
.ui-header .ui-title~.ui-navbar .ui-grid-duo .ui-block-a:first-child .ui-btn,
.ui-footer .ui-title~.ui-navbar .ui-grid-duo .ui-block-a:first-child .ui-btn,
.ui-header .ui-title~.ui-navbar .ui-grid-duo .ui-block-a:first-child+.ui-block-b .ui-btn,
.ui-footer .ui-title~.ui-navbar .ui-grid-duo .ui-block-a:first-child+.ui-block-b .ui-btn {
  border-top-width: 1px
}

.ui-input-btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  outline: 0;
  -webkit-border-radius: inherit;
  border-radius: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background: #fff;
  background: rgba(255, 255, 255, 0);
  filter: Alpha(Opacity=0);
  opacity: .1;
  font-size: 1px;
  text-indent: -9999px;
  z-index: 2
}

.ui-input-btn.ui-state-disabled input {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-collapsible {
  margin: 0 -1em
}

.ui-collapsible-inset,
.ui-collapsible-set {
  margin: .5em 0
}

.ui-collapsible-heading {
  display: block;
  margin: 0;
  padding: 0;
  position: relative
}

.ui-collapsible-heading .ui-btn {
  text-align: left;
  margin: 0;
  border-left-width: 0;
  border-right-width: 0
}

.ui-collapsible-heading .ui-btn-icon-top,
.ui-collapsible-heading .ui-btn-icon-bottom {
  text-align: center
}

.ui-collapsible-inset .ui-collapsible-heading .ui-btn {
  border-right-width: 1px;
  border-left-width: 1px
}

.ui-collapsible-collapsed+.ui-collapsible:not(.ui-collapsible-inset)>.ui-collapsible-heading .ui-btn {
  border-top-width: 0
}

.ui-collapsible-set .ui-collapsible:not(.ui-collapsible-inset) .ui-collapsible-heading .ui-btn {
  border-top-width: 1px
}

.ui-collapsible-heading-status {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-collapsible-content {
  display: block;
  margin: 0;
  padding: .5em 1em
}

.ui-collapsible-themed-content .ui-collapsible-content {
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-style: solid
}

.ui-collapsible-inset.ui-collapsible-themed-content .ui-collapsible-content {
  border-left-width: 1px;
  border-right-width: 1px
}

.ui-collapsible-inset .ui-collapsible-content {
  margin: 0
}

.ui-collapsible-content-collapsed {
  display: none
}

.ui-collapsible-set>.ui-collapsible.ui-corner-all {
  -webkit-border-radius: 0;
  border-radius: 0
}

.ui-collapsible-heading,
.ui-collapsible-heading>.ui-btn {
  -webkit-border-radius: inherit;
  border-radius: inherit
}

.ui-collapsible-set .ui-collapsible.ui-first-child {
  -webkit-border-top-right-radius: inherit;
  border-top-right-radius: inherit;
  -webkit-border-top-left-radius: inherit;
  border-top-left-radius: inherit
}

.ui-collapsible-content,
.ui-collapsible-set .ui-collapsible.ui-last-child {
  -webkit-border-bottom-right-radius: inherit;
  border-bottom-right-radius: inherit;
  -webkit-border-bottom-left-radius: inherit;
  border-bottom-left-radius: inherit
}

.ui-collapsible-themed-content:not(.ui-collapsible-collapsed)>.ui-collapsible-heading {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0
}

.ui-collapsible-set .ui-collapsible {
  margin: -1px -1em 0
}

.ui-collapsible-set .ui-collapsible-inset {
  margin: -1px 0 0
}

.ui-collapsible-set .ui-collapsible.ui-first-child {
  margin-top: 0
}

.ui-controlgroup,
fieldset.ui-controlgroup {
  padding: 0;
  margin: .5em 0
}

.ui-field-contain .ui-controlgroup,
.ui-field-contain fieldset.ui-controlgroup {
  margin: 0
}

.ui-mini .ui-controlgroup-label {
  font-size: 16px
}

.ui-controlgroup.ui-mini .ui-btn-icon-notext,
.ui-controlgroup .ui-mini.ui-btn-icon-notext {
  font-size: inherit
}

.ui-controlgroup-controls .ui-btn,
.ui-controlgroup-controls .ui-checkbox,
.ui-controlgroup-controls .ui-radio,
.ui-controlgroup-controls .ui-select {
  margin: 0
}

.ui-controlgroup-controls .ui-btn:focus,
.ui-controlgroup-controls .ui-btn.ui-focus {
  z-index: 1
}

.ui-controlgroup-controls li {
  list-style: none
}

.ui-controlgroup-horizontal .ui-controlgroup-controls {
  display: inline-block;
  vertical-align: middle
}

.ui-controlgroup-horizontal .ui-controlgroup-controls:before,
.ui-controlgroup-horizontal .ui-controlgroup-controls:after {
  content: "";
  display: table
}

.ui-controlgroup-horizontal .ui-controlgroup-controls:after {
  clear: both
}

.ui-controlgroup-horizontal .ui-controlgroup-controls>.ui-btn,
.ui-controlgroup-horizontal .ui-controlgroup-controls li>.ui-btn,
.ui-controlgroup-horizontal .ui-controlgroup-controls .ui-checkbox,
.ui-controlgroup-horizontal .ui-controlgroup-controls .ui-radio,
.ui-controlgroup-horizontal .ui-controlgroup-controls .ui-select {
  float: left;
  clear: none
}

.ui-controlgroup-horizontal .ui-controlgroup-controls button.ui-btn,
.ui-controlgroup-controls .ui-btn-icon-notext {
  width: auto
}

.ui-controlgroup-horizontal .ui-controlgroup-controls .ui-btn-icon-notext,
.ui-controlgroup-horizontal .ui-controlgroup-controls button.ui-btn-icon-notext {
  width: 1.5em
}

.ui-controlgroup-controls .ui-btn-icon-notext {
  height: auto;
  padding: .7em 1em
}

.ui-controlgroup-vertical .ui-controlgroup-controls .ui-btn {
  border-bottom-width: 0
}

.ui-controlgroup-vertical .ui-controlgroup-controls .ui-btn.ui-last-child {
  border-bottom-width: 1px
}

.ui-controlgroup-horizontal .ui-controlgroup-controls .ui-btn {
  border-right-width: 0
}

.ui-controlgroup-horizontal .ui-controlgroup-controls .ui-btn.ui-last-child {
  border-right-width: 1px
}

.ui-controlgroup-controls .ui-btn-corner-all,
.ui-controlgroup-controls .ui-btn.ui-corner-all {
  -webkit-border-radius: 0;
  border-radius: 0
}

.ui-controlgroup-controls,
.ui-controlgroup-controls .ui-radio,
.ui-controlgroup-controls .ui-checkbox,
.ui-controlgroup-controls .ui-select,
.ui-controlgroup-controls li {
  -webkit-border-radius: inherit;
  border-radius: inherit
}

.ui-controlgroup-vertical .ui-btn.ui-first-child {
  -webkit-border-top-left-radius: inherit;
  border-top-left-radius: inherit;
  -webkit-border-top-right-radius: inherit;
  border-top-right-radius: inherit
}

.ui-controlgroup-vertical .ui-btn.ui-last-child {
  -webkit-border-bottom-left-radius: inherit;
  border-bottom-left-radius: inherit;
  -webkit-border-bottom-right-radius: inherit;
  border-bottom-right-radius: inherit
}

.ui-controlgroup-horizontal .ui-btn.ui-first-child {
  -webkit-border-top-left-radius: inherit;
  border-top-left-radius: inherit;
  -webkit-border-bottom-left-radius: inherit;
  border-bottom-left-radius: inherit
}

.ui-controlgroup-horizontal .ui-btn.ui-last-child {
  -webkit-border-top-right-radius: inherit;
  border-top-right-radius: inherit;
  -webkit-border-bottom-right-radius: inherit;
  border-bottom-right-radius: inherit
}

.ui-controlgroup-controls a.ui-shadow:not(:focus),
.ui-controlgroup-controls button.ui-shadow:not(:focus),
.ui-controlgroup-controls div.ui-shadow:not(.ui-focus) {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none
}

.ui-controlgroup-label legend {
  max-width: 100%
}

.ui-controlgroup-controls>label {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-dialog {
  background: none!important
}

.ui-dialog-contain {
  width: 92.5%;
  max-width: 500px;
  margin: 10% auto 1em;
  padding: 0;
  position: relative;
  top: -1em
}

.ui-dialog-contain>.ui-header,
.ui-dialog-contain>.ui-content,
.ui-dialog-contain>.ui-footer {
  display: block;
  position: relative;
  width: auto;
  margin: 0
}

.ui-dialog-contain>.ui-header {
  overflow: hidden;
  z-index: 10;
  padding: 0;
  border-top-width: 0
}

.ui-dialog-contain>.ui-footer {
  z-index: 10;
  padding: 0 1em;
  border-bottom-width: 0
}

.ui-popup-open .ui-header-fixed,
.ui-popup-open .ui-footer-fixed {
  position: absolute!important
}

.ui-popup-screen {
  background-image: url("data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
  top: 0;
  left: 0;
  right: 0;
  bottom: 1px;
  position: absolute;
  filter: Alpha(Opacity=0);
  opacity: 0;
  z-index: 1099
}

.ui-popup-screen.in {
  opacity: .5;
  filter: Alpha(Opacity=50)
}

.ui-popup-screen.out {
  opacity: 0;
  filter: Alpha(Opacity=0)
}

.ui-popup-container {
  z-index: 1100;
  display: inline-block;
  position: absolute;
  padding: 0;
  outline: 0
}

.ui-popup {
  position: relative
}

.ui-popup.ui-body-inherit {
  border-width: 1px;
  border-style: solid
}

.ui-popup-hidden {
  left: 0;
  top: 0;
  position: absolute!important;
  visibility: hidden
}

.ui-popup-truncate {
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-popup.ui-content,
.ui-popup .ui-content {
  overflow: visible
}

.ui-popup>.ui-header {
  border-top-width: 0
}

.ui-popup>.ui-footer {
  border-bottom-width: 0
}

.ui-popup>p,
.ui-popup>h1,
.ui-popup>h2,
.ui-popup>h3,
.ui-popup>h4,
.ui-popup>h5,
.ui-popup>h6 {
  margin: .5em .4375em
}

.ui-popup>span {
  display: block;
  margin: .5em .4375em
}

.ui-popup-container .ui-content>p,
.ui-popup-container .ui-content>h1,
.ui-popup-container .ui-content>h2,
.ui-popup-container .ui-content>h3,
.ui-popup-container .ui-content>h4,
.ui-popup-container .ui-content>h5,
.ui-popup-container .ui-content>h6 {
  margin: .5em 0
}

.ui-popup-container .ui-content>span {
  margin: 0
}

.ui-popup-container .ui-content>p:first-child,
.ui-popup-container .ui-content>h1:first-child,
.ui-popup-container .ui-content>h2:first-child,
.ui-popup-container .ui-content>h3:first-child,
.ui-popup-container .ui-content>h4:first-child,
.ui-popup-container .ui-content>h5:first-child,
.ui-popup-container .ui-content>h6:first-child {
  margin-top: 0
}

.ui-popup-container .ui-content>p:last-child,
.ui-popup-container .ui-content>h1:last-child,
.ui-popup-container .ui-content>h2:last-child,
.ui-popup-container .ui-content>h3:last-child,
.ui-popup-container .ui-content>h4:last-child,
.ui-popup-container .ui-content>h5:last-child,
.ui-popup-container .ui-content>h6:last-child {
  margin-bottom: 0
}

.ui-popup>img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle
}

.ui-popup:not(.ui-content)>img:only-child,
.ui-popup:not(.ui-content)>.ui-btn-left:first-child+img:last-child,
.ui-popup:not(.ui-content)>.ui-btn-right:first-child+img:last-child {
  -webkit-border-radius: inherit;
  border-radius: inherit
}

.ui-popup iframe {
  vertical-align: middle
}

.ui-popup>.ui-btn-left,
.ui-popup>.ui-btn-right {
  position: absolute;
  top: -11px;
  margin: 0;
  z-index: 1101
}

.ui-popup>.ui-btn-left {
  left: -11px
}

.ui-popup>.ui-btn-right {
  right: -11px
}

.ui-popup-arrow-container {
  width: 20px;
  height: 20px
}

.ui-popup-arrow-container.ui-popup-arrow-l {
  left: -10px;
  clip: rect(-1000px, 10px, 2000px, -1000px)
}

.ui-popup-arrow-container.ui-popup-arrow-t {
  top: -10px;
  clip: rect(-1000px, 2000px, 10px, -1000px)
}

.ui-popup-arrow-container.ui-popup-arrow-r {
  right: -10px;
  clip: rect(-1000px, 2000px, 2000px, 10px)
}

.ui-popup-arrow-container.ui-popup-arrow-b {
  bottom: -10px;
  clip: rect(10px, 2000px, 1000px, -1000px)
}

.ui-popup-arrow-container .ui-popup-arrow {
  width: 28.284271247px;
  height: 28.284271247px;
  border-width: 1px;
  border-style: solid
}

.ui-popup-arrow-container.ui-popup-arrow-t .ui-popup-arrow {
  left: -4.142135623px;
  top: 5.857864376px
}

.ui-popup-arrow-container.ui-popup-arrow-b .ui-popup-arrow {
  left: -4.142135623px;
  top: -14.142135623px
}

.ui-popup-arrow-container.ui-popup-arrow-l .ui-popup-arrow {
  left: 5.857864376px;
  top: -4.142135623px
}

.ui-popup-arrow-container.ui-popup-arrow-r .ui-popup-arrow {
  left: -14.142135623px;
  top: -4.142135623px
}

.ui-popup-arrow-container.ui-popup-arrow-t.ie .ui-popup-arrow {
  margin-left: -5.857864376269049px;
  margin-top: -7.0710678118654755px
}

.ui-popup-arrow-container.ui-popup-arrow-b.ie .ui-popup-arrow {
  margin-left: -5.857864376269049px;
  margin-top: -4.142135623730951px
}

.ui-popup-arrow-container.ui-popup-arrow-l.ie .ui-popup-arrow {
  margin-left: -7.0710678118654755px;
  margin-top: -5.857864376269049px
}

.ui-popup-arrow-container.ui-popup-arrow-r.ie .ui-popup-arrow {
  margin-left: -4.142135623730951px;
  margin-top: -5.857864376269049px
}

.ui-popup>.ui-popup-arrow-guide {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden
}

.ui-popup-arrow-container {
  position: absolute
}

.ui-popup-arrow {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  overflow: hidden;
  box-sizing: border-box
}

.ui-popup-arrow-container.ie .ui-popup-arrow {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865474, M12=-0.7071067811865477, M21=0.7071067811865477, M22=0.7071067811865474, SizingMethod='auto expand')";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=.7071067811865474, M12=-.7071067811865477, M21=.7071067811865477, M22=.7071067811865474, SizingMethod='auto expand')
}

.ui-checkbox,
.ui-radio {
  margin: .5em 0;
  position: relative
}

.ui-checkbox .ui-btn,
.ui-radio .ui-btn {
  margin: 0;
  text-align: left;
  white-space: normal;
  z-index: 2
}

.ui-controlgroup .ui-checkbox .ui-btn.ui-focus,
.ui-controlgroup .ui-radio .ui-btn.ui-focus {
  z-index: 3
}

.ui-checkbox .ui-btn-icon-top,
.ui-radio .ui-btn-icon-top,
.ui-checkbox .ui-btn-icon-bottom,
.ui-radio .ui-btn-icon-bottom {
  text-align: center
}

.ui-controlgroup-horizontal .ui-checkbox .ui-btn:after,
.ui-controlgroup-horizontal .ui-radio .ui-btn:after {
  content: none;
  display: none
}

.ui-checkbox input,
.ui-radio input {
  position: absolute;
  left: .466em;
  top: 50%;
  width: 22px;
  height: 22px;
  margin: -11px 0 0 0;
  outline: 0!important;
  z-index: 1
}

.ui-controlgroup-horizontal .ui-checkbox input,
.ui-controlgroup-horizontal .ui-radio input {
  left: 50%;
  margin-left: -9px
}

.ui-checkbox input:disabled,
.ui-radio input:disabled {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-select {
  margin-top: .5em;
  margin-bottom: .5em;
  position: relative
}

.ui-select>select {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-select .ui-btn {
  margin: 0;
  opacity: 1
}

.ui-select .ui-btn select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 1.5em;
  min-height: 100%;
  height: 3em;
  max-height: 100%;
  outline: 0;
  -webkit-border-radius: inherit;
  border-radius: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  filter: Alpha(Opacity=0);
  opacity: 0;
  z-index: 2
}

@-moz-document url-prefix() {
  .ui-select .ui-btn select {
    opacity: .0001
  }
}

.ui-select .ui-state-disabled select {
  display: none
}

.ui-select span.ui-state-disabled {
  filter: Alpha(Opacity=100);
  opacity: 1
}

.ui-select .ui-btn.ui-select-nativeonly {
  border-radius: 0;
  border: 0
}

.ui-select .ui-btn.ui-select-nativeonly select {
  opacity: 1;
  text-indent: 0;
  display: block
}

.ui-select .ui-li-has-count.ui-btn {
  padding-right: 2.8125em
}

.ui-select .ui-li-has-count.ui-btn-icon-right {
  padding-right: 4.6875em
}

.ui-select .ui-btn-icon-right .ui-li-count {
  right: 3.2em
}

.ui-select .ui-btn>span:not(.ui-li-count) {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden!important;
  white-space: nowrap
}

.ui-selectmenu.ui-popup {
  min-width: 11em
}

.ui-selectmenu .ui-dialog-contain {
  overflow: hidden
}

.ui-selectmenu .ui-header {
  margin: 0;
  padding: 0;
  border-width: 0
}

.ui-selectmenu.ui-dialog .ui-header {
  z-index: 1;
  position: relative
}

.ui-selectmenu.ui-popup .ui-header {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0
}

.ui-selectmenu.ui-popup .ui-header h1:after {
  content: '.';
  visibility: hidden
}

.ui-selectmenu .ui-header .ui-title {
  margin: 0 2.875em
}

.ui-selectmenu.ui-dialog .ui-content {
  overflow: visible;
  z-index: 1
}

.ui-selectmenu .ui-selectmenu-list {
  margin: 0;
  -webkit-border-radius: inherit;
  border-radius: inherit
}

.ui-header:not(.ui-screen-hidden)+.ui-selectmenu-list {
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0
}

.ui-header.ui-screen-hidden+.ui-selectmenu-list li.ui-first-child .ui-btn {
  border-top-width: 0
}

.ui-selectmenu .ui-selectmenu-list li.ui-last-child .ui-btn {
  border-bottom-width: 0
}

.ui-selectmenu .ui-btn.ui-li-divider {
  cursor: default
}

.ui-selectmenu .ui-selectmenu-placeholder {
  display: none
}

.ui-listview,
.ui-listview>li {
  margin: 0;
  padding: 0;
  list-style: none
}

.ui-content .ui-listview,
.ui-panel-inner>.ui-listview {
  margin: -1em
}

.ui-content .ui-listview-inset,
.ui-panel-inner>.ui-listview-inset {
  margin: 1em 0
}

.ui-collapsible-content>.ui-listview {
  margin: -.5em -1em
}

.ui-collapsible-content>.ui-listview-inset {
  margin: .5em 0
}

.ui-listview>li {
  display: block;
  position: relative;
  overflow: visible
}

.ui-listview>.ui-li-static,
.ui-listview>.ui-li-divider,
.ui-listview>li>a.ui-btn {
  margin: 0;
  display: block;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.ui-listview>li>.ui-btn:focus {
  z-index: 1
}

.ui-listview>.ui-li-static,
.ui-listview>.ui-li-divider,
.ui-listview>li>a.ui-btn {
  border-width: 1px 0 0;
  border-style: solid
}

.ui-listview-inset>.ui-li-static,
.ui-listview-inset>.ui-li-divider,
.ui-listview-inset>li>a.ui-btn {
  border-right-width: 1px;
  border-left-width: 1px
}

.ui-listview>.ui-li-static.ui-last-child,
.ui-listview>.ui-li-divider.ui-last-child,
.ui-listview>li.ui-last-child>a.ui-btn {
  border-bottom-width: 1px
}

.ui-collapsible-content>.ui-listview:not(.ui-listview-inset)>li.ui-first-child,
.ui-collapsible-content>.ui-listview:not(.ui-listview-inset)>li.ui-first-child>a.ui-btn {
  border-top-width: 0
}

.ui-collapsible-themed-content .ui-listview:not(.ui-listview-inset)>li.ui-last-child,
.ui-collapsible-themed-content .ui-listview:not(.ui-listview-inset)>li.ui-last-child>a.ui-btn {
  border-bottom-width: 0
}

.ui-listview>li.ui-first-child,
.ui-listview>li.ui-first-child>a.ui-btn {
  -webkit-border-top-right-radius: inherit;
  border-top-right-radius: inherit;
  -webkit-border-top-left-radius: inherit;
  border-top-left-radius: inherit
}

.ui-listview>li.ui-last-child,
.ui-listview>li.ui-last-child>a.ui-btn {
  -webkit-border-bottom-right-radius: inherit;
  border-bottom-right-radius: inherit;
  -webkit-border-bottom-left-radius: inherit;
  border-bottom-left-radius: inherit
}

.ui-listview>li.ui-li-has-alt>a.ui-btn {
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0
}

.ui-listview>li.ui-first-child>a.ui-btn+a.ui-btn {
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: inherit;
  border-top-right-radius: inherit
}

.ui-listview>li.ui-last-child>a.ui-btn+a.ui-btn {
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: inherit;
  border-bottom-right-radius: inherit
}

.ui-listview>li.ui-first-child img:first-child:not(.ui-li-icon) {
  -webkit-border-top-left-radius: inherit;
  border-top-left-radius: inherit
}

.ui-listview>li.ui-last-child img:first-child:not(.ui-li-icon) {
  -webkit-border-bottom-left-radius: inherit;
  border-bottom-left-radius: inherit
}

.ui-collapsible-content>.ui-listview:not(.ui-listview-inset) {
  -webkit-border-radius: inherit;
  border-radius: inherit
}

.ui-listview>.ui-li-static {
  padding: .7em 1em
}

.ui-listview>.ui-li-divider {
  padding: .5em 1.143em;
  font-size: 14px;
  font-weight: 700;
  cursor: default;
  outline: 0
}

.ui-listview>.ui-li-has-count>.ui-btn,
.ui-listview>.ui-li-static.ui-li-has-count,
.ui-listview>.ui-li-divider.ui-li-has-count {
  padding-right: 2.8125em
}

.ui-listview>.ui-li-has-count>.ui-btn-icon-right {
  padding-right: 4.6875em
}

.ui-listview>.ui-li-has-thumb>.ui-btn,
.ui-listview>.ui-li-static.ui-li-has-thumb {
  min-height: 3.625em;
  padding-left: 6.25em
}

.ui-listview>.ui-li-has-icon>.ui-btn,
.ui-listview>.ui-li-static.ui-li-has-icon {
  min-height: 1.25em;
  padding-left: 2.5em
}

.ui-li-count {
  position: absolute;
  font-size: 12.5px;
  font-weight: 700;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  padding: 0 .48em;
  line-height: 1.6em;
  min-height: 1.6em;
  min-width: .64em;
  right: .8em;
  top: 50%;
  margin-top: -.88em
}

.ui-listview .ui-btn-icon-right .ui-li-count {
  right: 3.2em
}

.ui-listview .ui-li-has-thumb>img:first-child,
.ui-listview .ui-li-has-thumb>.ui-btn>img:first-child,
.ui-listview .ui-li-has-thumb .ui-li-thumb {
  position: absolute;
  left: 0;
  top: 0;
  max-height: 5em;
  max-width: 5em
}

.ui-listview>.ui-li-has-icon>img:first-child,
.ui-listview>.ui-li-has-icon>.ui-btn>img:first-child {
  position: absolute;
  left: .625em;
  top: .9em;
  max-height: 1em;
  max-width: 1em
}

.ui-listview>li h1,
.ui-listview>li h2,
.ui-listview>li h3,
.ui-listview>li h4,
.ui-listview>li h5,
.ui-listview>li h6 {
  font-size: 1em;
  font-weight: 700;
  display: block;
  margin: .45em 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.ui-listview>li p {
  font-size: .75em;
  font-weight: 400;
  display: block;
  margin: .6em 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.ui-listview .ui-li-aside {
  position: absolute;
  top: 1em;
  right: 3.333em;
  margin: 0;
  text-align: right
}

.ui-listview>li.ui-li-has-alt>.ui-btn {
  margin-right: 2.5em;
  border-right-width: 0
}

.ui-listview>li.ui-li-has-alt>.ui-btn+.ui-btn {
  position: absolute;
  width: 2.5em;
  height: 100%;
  min-height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-left-width: 1px;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 2
}

.ui-listview-inset>li.ui-li-has-alt>.ui-btn+.ui-btn {
  border-right-width: 1px
}

.ui-listview>li.ui-li-has-alt>.ui-btn+.ui-btn:focus {
  z-index: 3
}

ol.ui-listview,
ol.ui-listview>.ui-li-divider {
  counter-reset: listnumbering
}

ol.ui-listview>li>.ui-btn,
ol.ui-listview>li.ui-li-static {
  vertical-align: middle
}

ol.ui-listview>li>.ui-btn:first-child:before,
ol.ui-listview>li.ui-li-static:before,
ol.ui-listview>li.ui-field-contain>label:before,
ol.ui-listview>li.ui-field-contain>.ui-controlgroup-label:before {
  display: inline-block;
  font-size: .9em;
  font-weight: 400;
  padding-right: .3em;
  min-width: 1.4em;
  line-height: 1.5;
  vertical-align: middle;
  counter-increment: listnumbering;
  content: counter(listnumbering) "."
}

ol.ui-listview>li.ui-field-contain:before {
  content: none;
  display: none
}

ol.ui-listview>li h1:first-child,
ol.ui-listview>li h2:first-child,
ol.ui-listview>li h3:first-child,
ol.ui-listview>li h4:first-child,
ol.ui-listview>li h5:first-child,
ol.ui-listview>li h6:first-child,
ol.ui-listview>li p:first-child,
ol.ui-listview>li img:first-child+* {
  display: inline-block;
  vertical-align: middle
}

ol.ui-listview>li h1:first-child~*,
ol.ui-listview>li h2:first-child~*,
ol.ui-listview>li h3:first-child~*,
ol.ui-listview>li h4:first-child~*,
ol.ui-listview>li h5:first-child~*,
ol.ui-listview>li h6:first-child~*,
ol.ui-listview>li p:first-child~*,
ol.ui-listview>li img:first-child+*~* {
  margin-top: 0;
  text-indent: 2.04em
}

html .ui-filterable+.ui-listview,
html .ui-filterable.ui-listview {
  margin-top: .5em
}

.ui-collapsible-content>form.ui-filterable {
  margin-top: -.5em
}

.ui-collapsible-content>.ui-input-search.ui-filterable {
  margin-top: 0
}

.ui-collapsible-content>.ui-filterable+.ui-listview:not(.ui-listview-inset)>li.ui-first-child,
.ui-collapsible-content>.ui-filterable+.ui-listview:not(.ui-listview-inset)>li.ui-first-child>a.ui-btn,
.ui-collapsible-content>.ui-filterable.ui-listview:not(.ui-listview-inset)>li.ui-first-child,
.ui-collapsible-content>.ui-filterable.ui-listview:not(.ui-listview-inset)>li.ui-first-child>a.ui-btn {
  border-top-width: 1px
}

div.ui-slider {
  height: 30px;
  margin: .5em 0;
  padding: 0;
  -ms-touch-action: pan-y pinch-zoom double-tap-zoom
}

div.ui-slider:before,
div.ui-slider:after {
  content: "";
  display: table
}

div.ui-slider:after {
  clear: both
}

input.ui-slider-input {
  display: block;
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding: 4px;
  width: 40px;
  height: 20px;
  line-height: 20px;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  text-align: center;
  vertical-align: text-bottom;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

.ui-slider-input::-webkit-outer-spin-button,
.ui-slider-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.ui-slider-track {
  position: relative;
  overflow: visible;
  border-width: 1px;
  border-style: solid;
  height: 6px;
  margin: 0 15px 0 68px;
  top: 6px
}

.ui-slider-track.ui-mini {
  height: 12px;
  top: 8px
}

.ui-slider-track .ui-slider-bg {
  height: 100%
}

.ui-slider-track .ui-btn.ui-slider-handle {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 24px;
  height: 24px;
  margin: -15px 0 0 -15px;
  outline: 0;
  padding: 0;
  border: 3px solid $white;
  border-radius: 100%;
}

.ui-slider-track.ui-mini .ui-slider-handle {
  height: 14px;
  width: 14px;
  margin: -8px 0 0 -8px
}

select.ui-slider-switch {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

div.ui-slider-switch {
  display: inline-block;
  height: 32px;
  width: 5.8em;
  top: 0
}

div.ui-slider-switch:before,
div.ui-slider-switch:after {
  display: none;
  clear: none
}

div.ui-slider-switch.ui-mini {
  height: 29px;
  top: 0
}

.ui-slider-inneroffset {
  margin: 0 16px;
  position: relative;
  z-index: 1
}

.ui-slider-switch.ui-mini .ui-slider-inneroffset {
  margin: 0 15px 0 14px
}

.ui-slider-switch .ui-btn.ui-slider-handle {
  margin: 1px 0 0 -15px
}

.ui-slider-switch.ui-mini .ui-slider-handle {
  width: 25px;
  height: 25px;
  margin: 1px 0 0 -13px;
  padding: 0
}

.ui-slider-handle-snapping {
  -webkit-transition: left 70ms linear;
  -moz-transition: left 70ms linear;
  transition: left 70ms linear
}

.ui-slider-switch .ui-slider-label {
  position: absolute;
  text-align: center;
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  top: 0;
  line-height: 2;
  min-height: 100%;
  white-space: nowrap;
  cursor: pointer
}

.ui-slider-switch.ui-mini .ui-slider-label {
  font-size: 14px
}

.ui-slider-switch .ui-slider-label-a {
  z-index: 1;
  left: 0;
  text-indent: -1.5em
}

.ui-slider-switch .ui-slider-label-b {
  z-index: 0;
  right: 0;
  text-indent: 1.5em
}

.ui-slider-track .ui-slider-bg,
.ui-slider-switch .ui-slider-label,
.ui-slider-switch .ui-slider-inneroffset,
.ui-slider-handle {
  -webkit-border-radius: inherit;
  border-radius: inherit
}

.ui-field-contain div.ui-slider-switch {
  margin: 0
}

.ui-field-contain div.ui-slider-switch,
.ui-field-contain.ui-hide-label div.ui-slider-switch,
html .ui-popup .ui-field-contain div.ui-slider-switch {
  display: inline-block;
  width: 5.8em
}

.ui-slider-popup {
  width: 64px;
  height: 64px;
  font-size: 36px;
  padding-top: 14px;
  opacity: .8
}

.ui-slider-popup {
  position: absolute!important;
  text-align: center;
  z-index: 100
}

.ui-slider-track .ui-btn.ui-slider-handle {
  font-size: .9em;
  line-height: 30px
}

.ui-rangeslider {
  margin: .5em 0
}

.ui-rangeslider:before,
.ui-rangeslider:after {
  content: "";
  display: table
}

.ui-rangeslider:after {
  clear: both
}

.ui-rangeslider .ui-slider-input.ui-rangeslider-last {
  float: right
}

.ui-rangeslider .ui-rangeslider-sliders {
  position: relative;
  overflow: visible;
  height: 30px;
  margin: 0 68px
}

.ui-rangeslider .ui-rangeslider-sliders .ui-slider-track {
  position: absolute;
  top: 12px;
  right: 0;
  left: 0;
  margin: 0
}

.ui-rangeslider.ui-mini .ui-rangeslider-sliders .ui-slider-track {
  top: 8px
}

.ui-rangeslider .ui-slider-track:first-child .ui-slider-bg {
  display: none
}

.ui-rangeslider .ui-rangeslider-sliders .ui-slider-track:first-child {
  background-color: transparent;
  background: 0;
  border-width: 0;
  height: 0
}

html>body .ui-rangeslider .ui-rangeslider-sliders .ui-slider-track:first-child {
  height: 6px;
  border-width: 1px
}

html>body .ui-rangeslider.ui-mini .ui-rangeslider-sliders .ui-slider-track:first-child {
  height: 12px
}

div.ui-rangeslider label {
  position: absolute!important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px)
}

.ui-field-contain .ui-rangeslider input.ui-slider-input,
.ui-field-contain .ui-rangeslider.ui-mini input.ui-slider-input,
.ui-field-contain .ui-rangeslider .ui-rangeslider-sliders,
.ui-field-contain .ui-rangeslider.ui-mini .ui-rangeslider-sliders {
  margin-top: 0;
  margin-bottom: 0
}

.ui-input-text,
.ui-input-search {
  margin: .5em 0;
  border-width: 1px;
  border-style: solid
}

.ui-mini {
  margin: .446em
}

.ui-input-search .ui-input-clear-hidden,
.ui-input-text .ui-input-clear-hidden {
  display: none
}

.ui-input-text input::-moz-placeholder,
.ui-input-search input::-moz-placeholder,
textarea.ui-input-text::-moz-placeholder {
  color: #aaa
}

.ui-tabs {
  position: relative;
  padding: .2em
}

.ui-mobile [data-role="page"],
.ui-mobile [data-role="dialog"],
.ui-page {
  min-height: auto !important;
  top: auto;
  margin: -22px 0 0 0;
  width: 70% !important;
}

.mt-50 {
  margin-top: 50px;
}

@media screen and ( max-width: 767px) {
  .ui-mobile [data-role="page"],
  .ui-mobile [data-role="dialog"],
  .ui-page {
    width: 100% !important;
  }
}