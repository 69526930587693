.sop-dashboard p {
  margin-bottom: 0;
}

.sop-dashboard .accordian-title {
  font-size: 1.25em;
}

.sop-dashboard .accordian-icon {
  transition: transform 0.2s;
}
.sop-dashboard .accordian-icon.rotate-90 {
  transform: rotate(-90deg);
}

.swal-modal.form-member-modal {
  min-width: 600px;
}

.sop-dashboard .col {
  font-size: 18px;
}

.sop-dashboard .col {
  flex: 1 1 0;
  max-width: unset;
}


.sop-dashboard .col.checkbox-col{
  max-width: 50px;
}

.sop-dashboard .col.action-col {
  max-width: 100px;
}

.sop-dashboard .th {
  font-weight: 500;
}

.sop-dashboard .btn-in-progress {
  background: #FFCF96;
  color: #D16706;
}

.sop-dashboard .btn-complete {
  background: #C8FFBA;
  color: #147105;
}