//colors

$primary-600: #f17f70 !default;
$secondary: #444d56 !default;
$success: #2dce6e !default;
$warning: #fce29d !default;

$white:    #fff !default;
$bg-gray-500: #f8f9f9 !default;
$text-secondary-500: rgba(32,33,37,0.6) !default;
$black-900: #1a1a1a !default;
$text-gray-400: #738291 !default;
$text-gray-800: #444d56 !default;
$disabled: #CCCCCC;

$black:    #344654 !default;
$blue:    #01C7F1 !default;
$gray-900: #526C79 !default;
$pink:   #F94F7A !default;
$yellow:  #FBF9F0 !default;
$gray-800: #555555 !default;
$body-bg: #f2f3f6;
$primary: #0273eb;
$primary-900: #0260c5;
$link-hover: #489ad1;
$sign-up-bg: #42a7ea;
$sign-up-bg-700: #2d98df;
$gray-500: #d7d7d7;
$border-overlap-text: #f4f4f4;
$back-btn-bg: #6297ea;
$card-danger-bg: #ff545e;
$body-text-color: #413E3F;
$icn-circle-bg: #4d5a70;
$app-title-border: #99989a;
$app-title-bg: #dee0e4;
$dot-box-bg: #F58614;
$emp-number-box-bg: #e5f1fd;
$footer-text: #7c8591;
$form-label: #949293;
$bg-row-gray: #f2f4f6;
$border-checkbox: #dee2e6;
$text-bg-box-form: #8a8a8a;
$text-blured: #c7c6c6;
$emp-bg-gray: #f9fafc;
$side-nav-bg: #354052;
$side-nav-hover-bg: #2e3949;
$side-nav-hr-bg: #3f495a;
$side-nav-title-hr: #e8e9eb;
$table-th-color: #878688;
$table-td-color: #676565;
$bg-checkbox-border: #c0c0c0;
$bg-v-pipe: #d5d6d8;
$bg-checkbox-red: #e5545c;
$bg-checkbox-yellow: #fcc63f;
$bg-checkbox-violet: #9947b1;
$text-color: #949395;
$bg_em_circle_yellow: #ffd300;
$bg_em_circle_green: #3faf5e;
$alert_border-color: #eaeaea;
$bg-v-pipe1: #f5f5f5;
$bg-range-slider: #d8d8d8;
$placeholder-color:#4f4c4d;
$bg-overview-graph: #dddfe4;
$text_warning_color: #f8e71c;
$gray1-100: #ceced1;
$border_bottom_xs: rgba(52, 70, 84, 0.125);
$tabs-title-color: #A6ABB4;
$paragraph-color: #4A4A4A;



// Fonts
$font-family-base:    'Roboto', sans-serif;
//$font-family-base:    'Josefin Slab', serif;
//$font-family-sans-serif:    'Josefin Slab', serif;
// stylelint-enable value-keyword-case
$font-size-base:       0.875rem  !default;

$bright-blue: #0273EB;
$orange: #EB9F02;
$dark-blue: #0273A6;
$light-blue: #02A6EB;
$lime-green: #73EB02;
$magenta: #EB0273;
$light-gray: #E5E5E5;
$medium-gray: #999999;
$dark-gray: #333333;
$goldenrod: #D9A602;
$copper: #C56F02;
$plum: #A60273;
$vibrant-violet: #7302EB;
$jungle-green: #02A659;

$state-warning: #F1C40F;
$state-error: #E74C3C;
$state-success: #2ECC71;
$state-running: $bright-blue;