/*background:image_url("slider-arrow.png") no-repeat left top;
 */
@import 'custom_variables';
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import "functions";


.container {
  max-width: 1380px;
}

.hero-marque {
  width: 100%;
  float: left;
  height: 100vh;
  position: relative;
  z-index: 0;
  overflow: hidden;

  .text-holder {
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-42%);
    z-index: 0;
    text-align: center;
    font-size: 22px;
    font-family: $font-family-base !important;
    padding: 0 9%;
    color: $white;
    font-weight: 300;

    h1 {
      font-size: 46px;
      font-family: $font-family-sans-serif;
      font-weight: 400 !important;
    }
  }

  .hero-marquee-wrapp {
    opacity: 0.85;
    width: 100%;
    float: left;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background: #01b6dc;
    background: -moz-linear-gradient(45deg, #01b6dc 0%, rgba(122, 77, 255, 8.3) 100%);
    background: -webkit-linear-gradient(45deg, #01b6dc 0%, #7a4dff 100%);
    background: linear-gradient(45deg, #01b6dc 0%, #7a4dff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#01b6dc', endColorstr='#7a4dff', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
  }
}

#hero-bg {
  height: 100%;
  width: 100%;
  z-index: 0;
  right: 0;
  top: 0;
  background: image_url("background/hero-bg.jpg") no-repeat left top;
  background-size: cover;
  overflow: hidden;
  object-fit: cover;
  background-size: cover;
  overflow: hidden;
}

.emp-sec {
  padding: 80px 0;
}

/**** Login css here ****/
.emp-login-sec {
  .emp-brand {
    margin: 0px 0 52px 0;
    height: auto;
    width: 220px;
    display: inline-block;
    text-align: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .emp-login-form {
    .emp-form-card {
      border-radius: 6px;

      .emp-form-heading {
        h2 {
          margin-bottom: 20px;
          margin-top: 12px;
          font-size: 25px;
          font-weight: 400;
          line-height: 32px;
          color: #354052;
          letter-spacing: 0.42px;
        }
      }

      .emp-form-btn {
        min-width: 16rem;
        margin: 14px 0 13px 0;
      }

      .form-bottom-links {
        p {
          font-size: 14px;
          margin-bottom: 5px;
        }

        a {
          color: $sign-up-bg;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .emp-text-gray {
      color: $gray-500;
    }

    .emp-text-block {
      .text-confirm {
        padding: 8px 43px 0px 43px;
        color: $black;
      }
    }

    .yazi2 {
      position: relative;
      border-bottom: 2px solid $border-overlap-text;
      text-align: center;
      margin: 40px 0 30px 0;
      width: 100%;

      .or-text {
        position: absolute;
        font-size: 16px;
        left: 44%;
        display: inline-block;
        padding: 0 10px;
        margin-top: -12px;
        background: white;
        color: $gray-500;
      }
    }

    .emp-btn-secondary {
      background-color: $sign-up-bg;
      border-color: $sign-up-bg-700;
      color: $white;

      &:hover {
        background-color: $sign-up-bg-700;
        border-color: $sign-up-bg-700;
        color: $white;
      }
    }

    .signup-btn-block {
      margin: 26px 0 0 0;
    }

    .icn-ques {
      width: 18px;
      height: 18px;
      margin: -3px 10px 0px 10px;
    }

    .emp-btn {
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    }

    .emp-activate-devices {

    }
  }
}

/**** Form steps css here ****/
.emp-form-step-sec {
  h3.emp-brand-title {
    font-size: 20px;
    font-weight: 400;
    color: #1a173b;
    line-height: 30px;
  }
}

/**** END ****/

/**** css for without sidebar ****/

.emp-group-list-item {
  border-bottom: 1px solid rgba(52, 70, 84, 0.125);

  &:last-child {
    border-bottom: 0px solid transparent;
  }
}

.emp-group-label {
  color: $primary;
  font-size: 16px;

  &:hover {
    color: $side-nav-bg;
  }
}

.emp-group-secondary-label {
  font-size: 12px;
  line-height: 12px;
  color: $form-label;
  font-weight: 300;
}

.emp-sidebar {
  .emp-active-device-sec {
    padding-top: 0px;

    .emp-active-title {
      margin-top: 1.2rem;

      .sub_txt_title {
        font-size: 13px;
        color: $form-label;
        line-height: 13px;
      }

      h5 {
        font-weight: 400;
        color: #354052;
        font-size: 20px;
        margin-bottom: 0;
      }
    }

    .emp-active-content {
      font-size: 14px;
      color: $paragraph-color;
      line-height: 24px;
      margin: 30px 0 36px 0;
    }

    .emp-form-pipe {
      margin-left: -20px;
      margin-right: -20px;
      margin-top: 13px;
      margin-bottom: 22px;
    }

    .cst-select-box {
      -webkit-appearance: none !important;
      -moz-appearance: none;
      background-image: image_url("icon/ic_sort.png") !important;
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
    }

    .active-device-btn {
      margin-top: 15px;
    }

    .create-group {
      display: flex;
      align-items: center;

      span {
        color: $primary;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          color: $side-nav-bg;
        }
      }
    }

    .emp-cst-btn {
      padding: 6px 15px 6px 15px;
      font-size: 14px;
      min-width: 6rem;
      letter-spacing: 0.5px;
      transition: background-color 0.6s ease 0s, border-color 0.7s ease 0s, color 0.7s ease 0s;
      color: $white;

      .emp_icn_middle {
        position: relative;
        top: -1px;
      }
    }

    .emp-group-card {
      border: 0;
      border-radius: 8px;

      .emp_list_group_card {
        padding: 18px 28px 18px 28px;

        &:first-child {
          border-top-left-radius: 0.55rem;
          border-top-right-radius: 0.55rem;
        }

        &:last-child {
          border-bottom-right-radius: 0.55rem;
          border-bottom-left-radius: 0.55rem;
        }
      }

      .group-step-icn {
        background: $primary;
        border: solid 1px $primary;
        color: $white;
        content: "";
        display: block;
        font-size: 13px;
        border-radius: 100%;
        height: 30px;
        line-height: 27px;
        width: 30px;
        text-align: center;
        margin-left: 0px;
      }

      .group-step-label {
        //margin-left: 20px;
      }

      .move-up {
        position: relative;
        bottom: 10px;
      }
    }

    .emp-devices-card {
      .group-step-label {
        line-height: 15px;

        .emp_small_txt {
          font-size: 11px;
          line-height: 11px;
          color: $form-label;
          margin-left: 0;
          font-weight: 300;
        }
      }
    }
  }

  .emp_feature_sec {
    .create-group {
      display: inline-block;
      vertical-align: middle;
    }

    .emp-active-title {
      margin-bottom: 35px;
    }

    .emp_feature_text_card {
      h5 {
        font-size: 16px;
        color: $paragraph-color;
        font-weight: 400;
      }

      .text-date {
        color: $text-bg-box-form;
      }

      .emp_feature_para {
        margin-top: 14px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
      }

      .card-body {
        padding: 28px;
      }
    }
  }

  .emp_org_settings_sec {
    .bg-gray {
      background-color: $emp-bg-gray;
    }

    .emp_setting_logo {
      width: 174px;
      height: auto;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      margin: 16px 0;

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }

    .emp_cst_file_label::after {
      content: none;
    }

    .active-device-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .emp_org_settings_nav .emp_org_settings_link.active.emp_org_settings_link::before {
      content: "";
      position: absolute;
      left: 47%;
      top: 100%;
      width: 8px;
      border-top: 5px solid #007bff;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }

    .emp_org_settings_nav .emp_org_settings_link.active {
      color: #fff !important;
    }

    .emp_org_settings_nav {
      border-top: 1px solid $side-nav-title-hr;
      border-bottom: 1px solid $side-nav-title-hr;

      .emp_org_settings_item {
        position: relative;

        .emp_org_settings_link {
          color: #c5c5c5;
          padding: 8px 45px;
        }
      }
    }

    .emp_create_report_header {
      font-size: 18px;
    }

    .org_admin_preview_table {
      margin-top: 20px;

      h6 {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }

      .emp_txt_blured {
        color: $text-blured;
        font-size: 12px;
      }

      td {
        padding: 0.75rem;
        vertical-align: middle;
        border-bottom: 1px solid #f4f4f4;
        border-top: 1px solid #f4f4f4 !important;
      }

      .emp_user {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
      }
    }

    .alert_cont_preview_table {
      thead th {
        vertical-align: middle;
        border-bottom: none;
        border-top: none;
        font-weight: 300;
        color: #959394;
      }
    }

    .emp_file_input {
      .input-group-append {
        margin-left: 15px;

        .btn_file_input {
          border-radius: 0.25rem !important;
        }
      }

      .custom-file-label {
        border-radius: 0.25rem !important;
      }
    }
  }

  .emp_link {
    color: $primary;

    &:hover {
      color: $secondary;
    }
  }

  .emp_opacity_1 {
    opacity: 1;
  }

  .emp_profile_sec {
    .table-block {
      border: none;

      .table_label {
        padding: 0 1.25rem;
        margin-bottom: 10px;
      }

      .table th, .table td {
        padding: 0.75rem 1.25rem 0.75rem 1.25rem;
      }

      .table th {
        font-weight: 400;
      }
    }

    .table-striped tbody tr:nth-of-type(2n+1) {
      background-color: $emp-bg-gray;
    }
  }

  .emp_icn_fnt_24 {
    font-size: 24px;
  }

  .emp_create_report_form {
    .emp_create_report_body {
      .custom-control-label {
        text-transform: capitalize;
      }
    }
  }

  .create_new_report_sec {
    .emp-active-title {
      margin-bottom: 2rem;
    }

    .emp_create_report_form {
      .emp_create_report_card {
        border-radius: 6px;

        .emp_create_report_header {
          padding: 1.1rem 2rem 1.1rem 2rem;
          background-color: $white;
          font-size: 17px;
          font-weight: 400;

          .emp_create_report_badge {
            font-size: 18px;
            margin-left: 10px;
            min-width: 3rem;
            font-weight: 400;
            padding: 8px 13px 8px 13px;
            background-color: $back-btn-bg;
            border-radius: 6px;
          }
        }

        .emp_create_report_body {
          padding: 2rem;

          .emp_create_report_bg_gray {
            background: $bg-row-gray;
            padding: 5px 0 20px 0;
            border-radius: 6px;
            margin-top: 10px;
            position: relative;
          }
        }

        .emp_create_notify_body {
          .emp_create_notify_box {
            .card-body {
              padding: 0.675rem 0.875rem 0.675rem 0.875rem;
            }

            .emp_user {
              width: 35px;
              height: 35px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }

            h6 {
              font-size: 14px;
              font-weight: 300;
            }

            .emp_create_notify_box_gray {
              background-color: $bg-row-gray;
            }
          }

          .emp_notify_badge {
            padding: 8px 18px 8px 18px;
            font-size: 13px;
            margin-right: 10px;
            background-color: $back-btn-bg;
            color: $white;
            transition: background-color 0.6s ease 0s, border-color 0.7s ease 0s, color 0.7s ease 0s;
            font-weight: 400;

            &:hover {
              background-color: $primary;
              color: $white;
            }

            .emp_notify_icn {
              font-size: 20px;
              margin-left: 5px;
              vertical-align: middle;
            }
          }

          .support_preview_box {
            font-weight: 400;

            .preview_title {
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 20px;
            }

            .support_value_txt {
              color: $primary;

              &:hover {
                color: $secondary;
              }
            }
          }
        }

        .emp_support_preview_body {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }

      .option_list {
        .emp_border_btm {
          border-bottom: 1px solid $primary;
          padding-bottom: 4px;
          color: $primary;
        }

        .emp_create_circle_icn {
          background: $primary;
          color: $white;
          display: block;
          font-size: 12px;
          border-radius: 100%;
          height: 22px;
          line-height: 20px;
          width: 22px;
          text-align: center;
          float: right;
        }
      }

      .emp_add_right_box {
        position: absolute;
        right: -21%;
        top: 22%;

        h5 {
          font-size: 15px;
          font-weight: 300;
          margin-bottom: 0;
          color: $body-text-color;

          &:hover {
            color: $primary;
          }
        }
      }

      .emp_txt_blured {
        color: $body-text-color;
        opacity: 0.7;
      }

      .value-option {
        margin-right: 9px;
      }

      .option-label {
        margin-bottom: 1px;
      }

      .cases_box {
        padding: 2rem 0rem;

        .cases_box_list {
          padding: 0rem 2rem;

          .emp_text_body {
            font-size: 14px;
          }

          h5 {
            font-size: 17px;
            font-weight: 400;
            color: $sign-up-bg;
            margin-bottom: 15px;
          }

          .circle_icn {
            margin-right: 12px;
            text-align: right;
          }
        }
      }
    }
  }

  .custom-control-label::before {
    background-color: $white;
    border: 1px solid $border-checkbox;
  }

  .navbar-right-menu li .app-nav__item.active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    content: '.';
    color: transparent;
    background: $primary;
    height: 2px;
    display: block;
  }
}

.inline_radio_btn {
  display: flex;

  .custom-radio {
    margin-left: 40px;

    &:first-child {
      margin-left: 0px;
    }
  }
}

/**** END ****/
/*.card {
  overflow: hidden;
}*/
.emp_border_radius_6 {
  border-radius: 6px;
}

.emp-drop-down {
  .settings-menu {
    .dropdown-item {
      font-size: 14px;
      padding: 0.55rem 1.5rem;
    }

    .emp-dropdown-itm {
      font-size: 14px;
      font-weight: 300;
    }
  }

  li .settings-menu {
    box-shadow: 0px 0px 20px 0px rgba(4, 4, 4, 0.1);
    border: none;
  }
}

.emp-drop-down li ul::after {
  content: '';
  border: 6px solid transparent;
  width: 0;
  height: 0;
  border-bottom-color: $white;
  position: absolute;
  top: -12px;
  left: 50%;
}

.input_modifier {
  line-height: 1.5;
  padding: 5px 14px;
  border-radius: 4px;
  font-size: 13px;
  height: 2rem;
  border-color: $bg-range-slider;
  /*color: $body-text-color;*/
}

.textarea_modifier {
  line-height: 1.5;
  padding: 5px 14px;
  border-radius: 4px;
  font-size: 13px;
  border-color: $bg-range-slider;
  color: $body-text-color;
  text-transform: none;
}

.label_modifier {
  color: $body-text-color;
  font-size: 13px;
  opacity: 0.6;
}

.form_help_text {
  color: $body-text-color;
  opacity: 0.6;
}

.emp-btn {
  padding: 8px 15px 8px 15px;
  font-size: 14px;
  min-width: 10rem;
  letter-spacing: 0.5px;
  transition: background-color 0.6s ease 0s, border-color 0.7s ease 0s, color 0.7s ease 0s;
}

a:hover {
  text-decoration: none;
}

.emp_dot {
  font-size: 10px !important;
  color: $primary;
}

/**** Emp sidebar css here ****/
.emp-sidebar {
  .navbar-left-menu {
    margin: 0 0 0 6px;
    padding: 0;
    list-style: none;

    .wender-circle {
      background-color: #0273eb;
      color: #fff;
      border-radius: 100%;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      width: 25px;
      height: 25px;
      line-height: 25px;
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 0;

    }
  }

  .navbar-right-menu {
    .nav-icon-img {
      margin-right: 10px;
    }
  }

  .icn-circle-bg {
    color: $icn-circle-bg;
    font-size: 10px;
  }

  .app-sidebar {
    .app-menu {
      margin-top: 20px;

      .app-sidebar-li-border {
        border-color: $side-nav-hr-bg;
        margin: 7px 0;
        border-width: 2px;
      }
    }
  }

  .app-content {
    .app-title {
      border-bottom: none;
    }

    .emp_select_itme {
      width: 130px;
      margin-right: 24px;
      vertical-align: middle;
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: 0;
      }

      .custom-select {
        text-transform: capitalize;
        border: none;
      }
    }

    .emp_all_groups_w_300 {
      width: 300px;
    }

    .app-title-right-widget-small {
      .info {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding: 5px 14px;
        -ms-flex-item-align: center;
        align-self: center;
        background-color: $white;
        color: #2a2a2a;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.6;
        height: 2rem;
      }

      .app-title-btn-live {
        display: flex;
        padding: 5px 20px;
        -ms-flex-item-align: center;
        align-self: center;
        background-color: $primary;
        color: $white;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 400;

        &:hover {
          background-color: $primary-900;
          color: $white;
          border-color: $primary-900;
        }
      }

      .app-title-shadow {
        box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
        -moz-box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
        -webkit-box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
      }

      .border-info {
        padding: 5px 0px 17px 0px;
        background-color: transparent;

        .border-top-info {
          border-top: 2px solid $app-title-border;
          width: 10px;
          margin-top: 10px;
          margin-bottom: 0;
        }
      }

      .title-icn-calender {
        padding: 4px 15px 0 0px;
      }
    }

    .widget-small-title.card {
      background-color: $app-title-bg;
      border-color: $app-title-bg;
      border-radius: 6px;
      margin-bottom: 20px;

      .card-body {
        padding: 10px 15px;

        h4 {
          font-size: 13px;
          margin-bottom: 0;
          font-weight: 400;
        }
      }
    }

    .emp-box {
      margin-bottom: 10px;
      border-radius: 6px;

      .card-header {
        display: flex;
        align-items: start;
        font-size: 14px;
      }

      .dot-emp-box {
        color: $dot-box-bg;
        font-size: 10px;
        vertical-align: middle;
        margin-right: 12px;
        line-height: 20px;
      }

      .emp-number-box {
        padding: 30px;
        background-color: $emp-number-box-bg;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        position: relative;
        height: 100%;
        align-items: center;

        .emp-number {
          .text-number {
            font-size: 55px;
            color: $primary;
            line-height: 65px;
            font-weight: 400;
          }

          .number-box-arrow {
            position: absolute;
            top: 27%;
            left: 60%;
          }

          .up-arrow {
            top: 41%;
          }
        }
      }
    }

    .element_emp_equal {
      min-height: 320px;

      .list-group-item {
        border: 1px solid rgba(52, 70, 84, 0.125);
      }
    }

    .emp-incidents {
      .emp-graph-img {
        margin-bottom: 20px;
      }

      .emp-box {
        .card-body {
          padding-top: 0;
        }

        .emp-number-box {
          padding: 10px 30px;

          .emp-number {
            .text-number {
              font-size: 30px;
              line-height: 40px;
              font-weight: 400;
            }

            ul {
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;

              li {
                margin-left: 35px;
                list-style: none;
                display: inline-block;

                &:first-child {
                  margin-left: 0px;
                }

                .emp-box-icident-txt {
                  font-size: 13px;
                  line-height: 12px;
                }
              }
            }
          }
        }
      }

      .emp-circle-icn {
        margin: -2px 20px 0 0;
        position: absolute;
        z-index: 1020;
        left: -14px;
        top: 53px;
      }
    }

    .fleet-box-block {
      .emp-box .emp-number-box .emp-number .text-number {
        font-size: 35px;
        line-height: 45px;
        font-weight: 400;
      }

      .emp-box .emp-number-box {
        padding: 11px 30px;
      }
    }

    .emp_org_settings_sec {
      .emp_create_notify_body .emp_notify_badge {
        padding: 8px 18px 8px 18px;
        font-size: 13px;
        margin-right: 10px;
        background-color: #6297ea;
        color: #fff;
        transition: background-color 0.6s ease 0s, border-color 0.7s ease 0s, color 0.7s ease 0s;
      }

      .emp_create_notify_body .emp_notify_badge .emp_notify_icn {
        font-size: 20px;
        margin-left: 5px;
        vertical-align: middle;
      }

      .emp-active-title h5 {
        font-weight: 400;
        color: $side-nav-bg;
        font-size: 20px;
        margin-bottom: 0;
      }

      .sub_txt_title {
        font-size: 13px;
        color: $form-label;
        line-height: 13px;
      }

      .emp_create_report_header {
        padding: 1.1rem 2rem 1.1rem 2rem;
        background-color: $white;
        font-size: 17px;
        font-weight: 400;
      }

      .checkbox_list_inline {
        padding: 0;
        list-style: none;

        .checkbox_label_item {
          display: inline-block;
          margin-left: 40px;

          &:first-child {
            margin-left: 0px;
          }
        }
      }

      a {
        color: $primary;
      }

      .emp_org_settings_nav {
        border-top: 1px solid $side-nav-title-hr;
        border-bottom: 1px solid $side-nav-title-hr;

        .emp_org_settings_item {
          position: relative;
        }

        .emp_org_settings_link {
          color: $tabs-title-color;
          padding: 8px 45px;
        }

        .emp_org_settings_link.active.emp_org_settings_link::before {
          content: "";
          position: absolute;
          left: 47%;
          top: 100%;
          width: 8px;
          border-top: 5px solid #007bff;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
        }
      }

      .emp_org_settings_nav .emp_org_settings_link.active {
        color: $white !important;
      }

      .table-wrapper {
        .table-txt-sm {
          color: $form-label;
          line-height: 14px;
          margin-bottom: 0;
          font-size: 11px;
        }

        .dot-emp-box {
          font-size: 10px;
          color: $dot-box-bg;
        }

        .emp_cst_checkbox {
          padding-left: 1.5rem;
          top: -15px;

          .custom-control-label::before {
            position: absolute;
            top: 0.25rem;
            left: -1.5rem;
            display: block;
            width: 1.3rem;
            height: 1.3rem;
            background-color: transparent;
            border: 1px solid $bg-checkbox-border;
          }

          .custom-control-label::after {
            position: absolute;
            top: 0.25rem;
            left: -1.5rem;
            display: block;
            width: 1.3rem;
            height: 1.3rem;
          }

          .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $primary !important;
            border-color: $primary !important;
          }
        }

        .table-striped tbody tr:nth-of-type(2n+1) {
          background-color: $emp-bg-gray;
        }

        .table th, .table td {
          padding: 17px 15px;
          vertical-align: middle;
          border-top: none;
          font-size: 13px;
          font-weight: 400;
        }

        .table_history {
          .em_th_w-60 {
            width: 60px;
          }

          thead th {
            vertical-align: middle;
            border-bottom: none;
            border-top: none;
            color: $table-th-color;
          }

          tbody {
            background-color: $white;
            color: $table-td-color;
          }
        }
      }

      .alert_box_btn {
        margin: 5px 0 10px 0;

        .alert_btn_list {
          padding: 0;
          margin-bottom: 0;

          .alert_btn_itm {
            list-style: none;
            display: inline;
            margin-left: 15px;

            .create-group {
              vertical-align: middle;
              display: inline-block;
            }

            .emp-cst-btn {
              color: $white;
              min-width: 6rem;
              padding: 4px 20px;
              font-size: 14px;
            }
          }
        }
      }

      .v-pipe {
        position: relative;

        &:before {
          width: 1px;
          content: "";
          height: 24px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          background: $bg-v-pipe;
        }
      }

      .inner_box {
        .emp_badge {
          padding: 8px 16px;
          background-color: $app-title-bg;
          color: $black;
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .treeview {
    .nav-icon-img {
      margin-right: 10px;
    }
  }

}

.text-blue {
  color: $sign-up-bg;
}

.rdtPicker {
  right: 0;
}

.rdtTimeToggle {
  font-weight: bold;
}

.app-sidebar__toggle-xs-circle-times {
  background: #0273eb;
  border-radius: 50%;
  color: $white !important;
  font-size: 18px;
  height: 30px;
  line-height: 29px;
  text-align: center;
  width: 30px;
  position: absolute;
  z-index: 1020;
  right: 15px;
  top: 16px;
}

.emp-shadow {
  box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(12, 11, 11, 0.1);
}

.duration_list {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 5px 14px !important;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: $white;
  color: #2a2a2a;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
  height: 2rem;
  min-width: 70px;
  text-align: center;
}

.v-pipe-top {
  position: relative;
  margin: 0px 12px;
}

.v-pipe-top::before {
  width: 1px;
  content: "";
  height: 24px;
  position: absolute;
  left: 0;
  right: 0;
  top: -12px;
  background: $bg-v-pipe;
}

/**** sidebar css END ****/
.emp_circle_box_sec {
  .emp_heading {
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    color: $text-color;
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

.emp_alert_sec {
  .bg_transparent_box {
    margin-top: 30px;

    .bg_transparent_title {
      font-size: 16px;
      margin-left: 20px;
      font-weight: 400;
      vertical-align: middle;
    }
  }

  .emp_create_notify_body .emp_notify_badge {
    margin-bottom: 6px;
  }
}

.emp_temp_alert_sec {
  .emp_v_pipe_box .emp_v_pipe::before {
    left: 33%;
  }
}

.emp_shipment_form_sec {
  .emp_create_notify_body .emp_notify_badge {
    margin-bottom: 6px;
  }
}

/*create_group screen css*/
.emp_general_setting_sec {
  .general_setting_txt_title {
    color: $side-nav-bg !important;
  }

  .general_setting_btn {
    margin: 10px 0 10px 0;
  }
}

.settings_users_hd_title {
  font-weight: 400;
  font-size: 20px;
}

.setting_user_txt_title {
  font-size: 14px;
}

.setting-user-table-title {
  color: $placeholder-color;
  font-weight: 400;
}


.em_circle_orange {
  background-color: $dot-box-bg;
}

.em_circle_yellow {
  background-color: $bg_em_circle_yellow;
}

.em_circle_green {
  background-color: $bg_em_circle_green;
}

.emp_circle {
  width: 70px;
  margin-bottom: 17px;
  line-height: 70px;
  height: 70px;
  display: inline-block;
  border-radius: 100%;
  box-shadow: 0px 0px 15px 0px rgba(16, 16, 16, 0.2);
  -moz-box-shadow: 0px 0px 15px 0px rgba(16, 16, 16, 0.2);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(16, 16, 16, 0.2);
}

.emp_circle_card {
  border-radius: 6px;
  transition: all .2s ease-in-out;
  box-shadow: 0px 0px 15px 0px rgba(16, 16, 16, 0.2);
  -moz-box-shadow: 0px 0px 15px 0px rgba(16, 16, 16, 0.2);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(16, 16, 16, 0.2);
  margin-bottom: 30px;

  &:hover {
    z-index: 1;
    transform: scale(1.1);
    transition: all .2s ease-in-out;

    .card-text {
      color: $primary;
    }
  }

  .card-body {
    padding: 2rem 1.5rem;
  }

  .card-text {
    color: $body-text-color;
  }

}

.sidenav_inner_card {

}

.alert_sm_title {
  font-size: 13px;
}

.text-blure {
  color: $form-label;
}

.alert_body_txt {
  border-left: 1px solid $alert_border-color;
  padding: 15px 15px 15px 15px;
}

.alert_box {
  padding: 0 0px 0 18px;
}

.alert_sm_text {
  font-size: 13px;
}

/** css for Sidenav Right **/
.emp_sidenav_right {
  .sidenav_inner_card {
    margin: 0 15px;
  }
}

.emp_sidenav_right .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 60px;
  right: 0;
  background-color: #dddfe4;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 60px 0 0 0;
}

.emp_sidenav_right .sidenav a {
  padding: 0x;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.emp_sidenav_right .sidenav a:hover {
  color: $black;
}

.emp_sidenav_right .sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 36px;
  margin-left: 0;
}

#main {
  transition: margin-right .5s;
}

@media screen and (max-height: 450px) {
  .emp_sidenav_right .sidenav {
    padding-top: 15px;
  }
  .emp_sidenav_right .sidenav a {
    font-size: 18px;
  }
}

/** css for Sidenav Right **/
.em-title-checkbox {
  margin-right: 40px;

  .custom-control-label {
    vertical-align: middle;
  }

  .custom-control-label::before {
    width: 1.3rem;
    height: 1.3rem;
    top: 0;
    left: -1.8rem;
  }

  .custom-control-label::after {
    position: absolute;
    top: 0rem;
    left: -1.8rem;
    display: block;
    width: 1.3rem;
    height: 1.3rem;
  }
}

.custom-checkbox-yellow .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $bg-checkbox-yellow;
}

.custom-checkbox-red .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $bg-checkbox-red;
}

.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary;
}

.custom-checkbox-violet .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $bg-checkbox-violet;
}

.emp_v_pipe_box {
  position: relative;

  .emp_v_pipe::before {
    width: 1px;
    content: "";
    height: 100%;
    position: absolute;
    left: 48%;
    top: 0;
    background: $bg-v-pipe1;
  }
}

.input_w_5o {
  max-width: 50px;
  width: 100%;
  text-align: center;
}

.emp_create_notify_box {
  .card-body {
    padding: 0.675rem 0.875rem 0.675rem 0.875rem;
  }

  .emp_user {
    width: 35px;
    height: 35px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h6 {
    font-size: 14px;
    font-weight: 300;
  }

  .emp_create_notify_box_gray {
    background-color: $bg-row-gray;
  }
}


/*css here for graph section*/
.emp_overview_sec {
  background-color: $bg-overview-graph;
  padding: 0 0 0 15px;
  overflow: hidden;

  .bg_overview {
    width: 100%;
    padding-left: 42px;
    background: image_url("background/overview.jpg") no-repeat left top;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 50px;
    float: left;
    height: 100vh;
  }

  .emp_overview_card {
    border: none;
    border-radius: 6px;
    border-right: 6px solid transparent;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    -webkit-transition: border-right-color 0.3s ease;
    -o-transition: border-right-color 0.3s ease;
    transition: border-right-color 0.3s ease;

    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

    &:hover {
      border-right-color: $primary;
      -webkit-transform: translateX(8px);
      transform: translateX(8px);
    }

    .card-body {
      padding: 20px 12px 18px 20px;
    }

    .overview_card_title {
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .icn_cog_right {
      float: right;
      font-size: 20px;
      line-height: 11px;
    }

    .overview_body_text {
      font-size: 13px;
    }

    .overview_icns {
      font-size: 12px;
      color: $form-label;
    }
  }
}

.emp_gatway_box_scc {
  padding: 30px 0;

  .emp_gatway_box_card {
    border-radius: 6px;
    border: none;

    .card-title {
      font-size: 16px;
      margin-bottom: 22px;
      font-weight: 400;
    }

    .circle_box {
      width: 140px;
      height: 140px;
      border-radius: 100%;
      border: 8px solid $green;
      padding: 38px 22px 10px 22px;
      line-height: 15px;

      .icn_wifi_big {
        font-size: 40px;
        line-height: 20px;
      }

      .circle_sm_txt {
        font-size: 12px;
        line-height: 15px;
      }
    }

    .bg_gray_box {
      background-color: $bg-row-gray;
      border-radius: 6px;
      padding: 20px 30px 20px 30px;
    }

    .card_footer_box {
      margin-top: 40px;
      font-size: 14px;

      .card-text {
        margin-bottom: 0;
      }
    }
  }

  .icn_dots {
    font-size: 12px;
  }

  .emp_sensor_heading {
    margin-top: 60px;
  }
}

.setting_administrator_txt {
  font-size: 16px;
  color: $gray1-100;
  font-weight: 300;
}

/** css END **/
.checkbox_list_inline {
  padding: 0;
  list-style: none;

  .checkbox_label_item {
    display: inline-block;
    margin-left: 40px;

    &:first-child {
      margin-left: 0px;
    }
  }
}

.table_checkbox_container {
  text-align: center;
  margin-top: 12px;
}

.table_checkbox {
  transform: scale(1.75);
  outline: none;
}

.alert-table-text-cell {
  margin-top: 5px;
  height: 28px;
  display: flex;
  align-items: center;
}

.emp_btn_addons {
  .input-group-append {
    margin-left: 15px;
  }

  .btn_radius {
    border-radius: 0.25rem !important;
  }
}

.emp_opacity_1 {
  opacity: 1;
}


.emp_switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 28px;
  margin-bottom: 0;
  margin-top: 5px;
}

.emp_switch input {
  display: none;
}

.emp_numeric_input {
  padding: 2px 5px;
  margin: 5px;
  text-align: center;
  border: 1px solid #e9ecef;
}

.emp_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.emp_slider::before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .emp_slider {
  background-color: $primary;
}

input:focus + .emp_slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .emp_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

::placeholder {
  //color: #9ea6ae !important;
  font-weight: lighter;
}

.label-inline { // use this to override bootstrap form inline label
  display: inline !important;
}

/* 'control' button area for tables */
.emp-table-controls {
  display: flex;
  justify-content: flex-end;
}

/* Rounded sliders */
.emp_slider.round {
  border-radius: 34px;
}

.emp_slider.round:before {
  border-radius: 50%;
}

.icn_warning {
  color: $text_warning_color;
}

.emp_day_dropdown {
  .emp_day_dropdown_menu {
    min-width: 6rem !important;
    left: 13px !important;
    top: 10px !important;
  }
}

.emp_day_dropdown li ul::after {
  left: 45%;
}

.app-title .gatway_block_search_l395 {
  margin-right: 395px;
}

/**indicate notes css**/
.incident_notes_block {
  margin-top: 70px;

  .incident_notes_box {
    padding: 20px 20px 0;
  }

  .incident_notes_title {
    font-size: 14px;
    color: $sign-up-bg;
    font-weight: 300;
  }

  .incident_notes_para {
    color: $form-label;
  }

  .incident_notes_form {
    margin-top: 48px;
  }
}

/* special formatted containers */
.emp-2columns {
  columns: 2 auto;
  width: 80%;
  margin: auto;
}

/** incident detail page css**/
.incident-detail-label {
  color: $form-label;
}

.incident-detail-title {
  font-size: 2em;
}

/* modal css here */
.display-block {
  display: block;
}

.display-none {
  display: none;
}

.map-img {
  width: 100%;
  border-radius: 5px;
}

.p-bottom {
  padding-bottom: 45px;
}

.modal-title {
  font-size: 20px;
  font-weight: 400;
}

.support_title {
  color: $black;
}

.circle_position_ralative {
  position: relative;
  z-index: 1;
}

.circle_dots::before {
  width: 10px;
  height: 10px;
  content: '';
  background: $primary;
  position: absolute;
  border-radius: 50%;
  top: 18px;
  left: 5px;
  z-index: 2;

}

.dots_v_line::before {
  width: 1px;
  height: 37px;
  position: absolute;
  background: $border-color;
  content: '';
  top: 28px;
  left: 10px;
  z-index: 2;
}

.report_search_form {
  width: 100%;
  float: left;

  .emp_search_item {
    display: flex;
    justify-content: space-between;
  }

  .emp_create_notify_box {
    z-index: 1;
  }
}

.current_item_time {
  background-color: $primary;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emp_txt_blured {
  color: $body-text-color;
  opacity: 0.7;
}

.emp-cst-btn {
  min-width: 6rem;
}

.modal_w_84 {
  width: 84%;
}

.info_cal {
  max-width: 100px;
  float: left;
  width: 100%;
}

.datepicker {
  margin-top: 60px;
}

.text-initial {
  text-transform: initial;
}

.new_feature_modal_block {
  .modal_v_pipe {
    &:before {
      left: 60%;
      top: -17px;
      height: 385px;
    }
  }

  .outline_circle {
    width: 140px;
    height: 140px;
    border-radius: 100%;
    border: 8px solid #5ABE76;
    padding: 32px 22px 10px 22px;
    line-height: 15px;
  }

  .circle_sm_txt {
    display: block;
  }

  .bg_gray_box {
    background-color: $bg-row-gray;
    border-radius: 6px;
    padding: 20px 30px 20px 30px;
    width: 80%;
  }

  .support_value_txt {
    color: $primary;
  }
}

.add-wifi-header-block {
  .create-group {
    color: $primary;
    font-size: 14px;
  }

  .fa-bluetooth-b {
    margin-left: 0.25rem;
  }
}

.setting-wifi-sec {
  .cst-table-header {
    font-size: 17px;
    font-weight: 400;
  }
}

.wifi-network-modal-body {
  padding-bottom: 80px;
}

.mt-user-setting-btn {
  margin-top: 32%;
}

.emp_link {
  color: $body-text-color;

  &:hover {
    color: $primary;
  }
}

a[href*="metric_alert"] .emp_circle_card .fa-chart-bar {
  color: white;
  margin-top: 1.35rem;
}

.border_radius_tr {
  border-radius: 0 0 4px 4px;
}

.search_gatways {
  margin-right: 440px !important;

  .app-search__button {
    top: 8px;

    .fa {
      opacity: 0.8;
    }
  }

  .emp_create_notify_box {
    width: 280px;
    z-index: 1;
  }

  .emp_txt_blured {
    color: #413E3F;
    opacity: 0.7;
  }
}

.is-expanded .fa-cog {
  color: $primary;
}

.emp_genral_sec {
  padding-top: 30px;

  .emp-active-title {
    margin-bottom: 40px;
  }

  .active-device-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .create-group {
    display: flex;
    align-items: center;
  }

  .active-device-btn {
    margin-top: 15px;
  }

  .emp-cst-btn {
    padding: 6px 15px 6px 15px;
    font-size: 14px;
    min-width: 6rem;
    letter-spacing: 0.5px;
    transition: background-color 0.6s ease 0s, border-color 0.7s ease 0s, color 0.7s ease 0s;
    color: $white;
  }

  .emp_icn_fnt_24 {
    font-size: 24px;
  }

  .create-group span {
    color: $primary;
    //margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }

  .emp_file_input .custom-file-label {
    border-radius: 0.25rem !important;
  }

  .custom-file-label::after {
    content: none;
  }

  .emp_file_input .input-group-append {
    margin-left: 15px;
  }

  .emp_file_input .input-group-append .btn_file_input {
    border-radius: 0.25rem !important;
  }

  .bg-gray {
    background-color: $emp-bg-gray;
  }

  .emp_setting_logo {
    width: 174px;
    height: auto;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin: 16px 0;
  }

  .emp_setting_logo img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.general_Settings_sec {
  .emp-active-title {
    margin-bottom: 80px;
  }
}

.search_sensors {
  width: 280px;

  .emp_create_notify_box {
    width: 280px;
    z-index: 1;
  }

  .app-search__button {
    top: 5px;
  }
}

.sensor_id_table {
  th, td {
    font-weight: 400;
    padding-left: 0;
    padding-bottom: 5px;
    opacity: 0.6;
  }
}

.emp_gatway_circle_sec {
  .emp_gatway_box_card .circle_box {
    width: 250px !important;
    height: 250px !important;
    border: 14px solid #5ABE76;
    padding: 38px 22px 10px 22px;
    line-height: 100px;
  }

  .emp_gatway_box_card .bg_gray_box {
    padding: 20px 30px 20px 60px;
    min-height: 250px;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
}

.emp_map_time {
  margin-right: 20px;
}

.emp_map_resolve_text {
  float: right;
  margin-right: 37px;
  opacity: 0.7;
}

.emp_text_initial {
  text-transform: initial;
}

.edit_network {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 5px 14px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
  }

  .active {
    background-color: $primary;
    color: $white;
  }
}

.card_activate_devices_block {
  .emp_cst_checkbox {
    margin-top: -16px;
    padding-left: 2rem;
    opacity: 1;
  }

  .emp_opacity_1 {
    opacity: 1 !important;
  }
}

.active_devices_table {
  td, th {
    padding: 0.75rem;
  }
}

.card_activate_devices_body {
  padding: 0px;
  border-radius: 4px;

  .list-group-item1 {
    list-style: none;
  }

  .emp_cst_checkbox {
    margin-top: -29px;
  }
}

.opacity_6 {
  opacity: 0.6;
}

.active_device_dropdown {
  list-style: none;
  padding: 0px;
  margin: 0;
  position: relative;

  .card_activate_devices_ul {
    width: 100%;
    float: left;
  }

  .card_activate_devices_menu {
    width: 100%;
    float: left;
    border: none;
    margin-top: 1px;
    padding: 0;

    li {
      border-radius: 4px;
    }

    .dropdown-item {
      padding: 10px 15px;
      overflow: hidden;

    }
  }

  .app-nav__item {
    padding: 10px 15px;
    overflow: hidden;
  }
}

.trigger-section-container {
  .emp_create_notify_box {
    z-index: 1;
  }
}

.activate_devices_table_container {
  height: 120px;
  overflow: hidden;
}

.active_device_controls {
  position: absolute;
  right: 22px;
  top: 70px;
  z-index: 1;

  p {
    margin-bottom: 0px;
    line-height: 14px;
  }

  .icn_nav {
    font-size: 18px;
    cursor: pointer;
    vertical-align: bottom;
    line-height: 0px;
    color: $primary;
  }
}

.text-black1 {
  color: $black !important;
}

.emp_gateways_search .app-search__input {
  width: 280px;
}

.emp_gateways_search_li {
  margin-right: 340px !important;
}

.font-weight-500 {
  font-weight: 500;
}


.custom-checkbox-primary.light_blue_checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #43dfdf;

}

.em-title-widget {
  margin-right: 1.2rem;
}

.environment_checkbox {

  .em-title-checkbox {
    margin-right: 10px;
    position: relative;
    top: 6px;
    display: block !important;

    .custom-checkbox-mb-2 {
      margin-bottom: 6px;
    }
  }

  .checkbox_mr_35 {
    margin-right: 40px;
  }

  .emp_all_groups_w_300 {
    margin-right: 26px !important;
  }
}

/* React-Table - manual customization classes and overrides */
body .ReactTable {
  font-size: 13px;
  font-weight: 400;
}

body .ReactTable .rt-td,
body .ReactTable .rt-th {
  padding: 13px 11px;
}

body .ReactTable .dot-emp-box {
  font-size: 10px;
  color: #F58614;
}

body .ReactTable {
  .-sort-desc {
    box-shadow: none !important;

    &:before {
      font-family: "FontAwesome";
      content: "\f0dd"; //"▼";
      float: right;
    }
  }

  .-sort-asc {
    box-shadow: none !important;

    &:before {
      font-family: "FontAwesome";
      content: "\f0de"; //"▲";
      float: right;
    }
  }
}

body .ReactTable.-striped .rt-tr.-odd {
  background: #f9fafc !important;
}

.users_group_dropdown {
  margin-bottom: 0;

  a {
    color: $white !important;
    width: 100%;
  }

  .emp_create_notify_box {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 1px;

    .dropdown-item {
      .media {
        border-bottom: 1px solid $border-checkbox;
        padding-bottom: 10px;
        padding-top: 10px;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

/**** css for footer ****/
footer {
  background-color: $gray-800;
  font-size: 12px;
  color: $white;
  line-height: 25px;

  a {
    color: $white !important;

    &:hover, &:focus, &:active {
      color: $blue !important;
      text-decoration: none;
    }
  }

  .navbar-brand {
    color: $white;
    font-family: $font-family-sans-serif;
    font-size: 24px;
    margin-top: 8px;
  }

  h6 {
    font-size: 16px;
    font-family: $font-family-sans-serif;
    color: $gray-500;
    font-weight: 800 !important;
    margin-top: 10px;
  }

  .list-group-item {
    background-color: transparent;
    border: 0px;
    padding: 2px 0;
  }

  .list-group {
    margin-bottom: 30px;
  }

  .list-group-item-action:hover, .list-group-item-action:focus {
    background-color: transparent;
    color: $blue;
  }
}

.transparent-input {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.emp-dashboard-nav-item {
  display: flex;
  min-width: 100px;
}

/* dasbboard device card styling  */
.emp-device-card {
  margin: 15px auto 15px auto;
  width: 50%;
}

.emp-device-card-body {
  padding: 1rem 1.25rem 1rem 2rem;
}

.emp-device-card-title {
  color: #526c79;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 250;
  display: inline-block;
  padding-top: 10px;
  margin-bottom: 0px;
}

.emp-device-card-secondary-text {
  color: #526c79;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
  margin-top: 0;
}

.emp-device-card-data { // telemetry values
  font-family: roboto;
  font-size: 18px;
}

.emp-device-card-color1 {
  color: #c624c9;
}

.emp-device-card-color2 {
  color: #43dfdf
}

.emp-device-card-color3 {
  color: #0273eb;
}

.emp-device-card-color4 {
  color: #bd00ff;
}

.emp-device-card-color5 {
  color: #ffcc00;
}

.emp-device-card-color6 {
  color: #9d4a75;
}

// device card media queries
@media screen and (max-width: 1275px) {
  .emp-device-card {
    width: 90%;
  }
  .emp-device-card-data {
    margin-top: 0 !important;
  }
}

/* input fields with symbols */
.input-icon {
  position: relative;
  display: inline-block;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 2.5em;
  text-align: center;
  font-style: normal;
}

.input-icon-right > i {
  right: 0;
}

.input-icon-right > input {
  padding-left: 0;
  padding-right: 2.5em;
  text-align: right;
}


.overview-page {
  display: flex;
  justify-content: space-between;
}

.Overview-map {
  height: 80vh;
  width: 70%;
}

.active-card-label {
  background-color: $primary;
  color: white;
  font-weight: bold;
  width: 150px;
  border-radius: 0.25rem;
}

.overview-page-card {
  margin-bottom: 10px;
}

.active-card {
  border-right-color: $primary;
  border-right-width: 5px;
  width: 22rem;
}

.overview-page-card-settings {
  float: right;
  color: $primary;
}

.padding-right-10 {
  padding-right: 10px;
}

.App-map {
  height: 250px;
  width: 100%;
}

.geofence-modal-footer {
  display: flex;
  margin: auto;
}

.pac-container {
  z-index: 10000;
}

.device-details-table {
  margin-top: 30px;
}

.device-details-table .rt-td {
  text-align: center;
}

.text-align-center {
  text-align: center;
}

.color-white {
  color: #fff !important;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.disabled {
  background-color: $disabled !important;
}

.audit-log-link {
  text-decoration: underline;
  color: $primary-900;
}

.audit-log-link:hover {
  color: $link-hover;
  text-decoration: underline;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-self-center {
  align-self: center;
}

.border-error {
  border: 1px solid $bg-checkbox-red;
}

.rt-resizable-header-content > div {
  margin-top: 12px;
}

.table-wrapper .mb-2 .ml-4 img {
  margin-top: 5px;
}

.highcharts-credits {
  display: none;
}

.dashboard-group-sub-menu {
  top: 0%;
  left: 100%;
}

.emp-device-card h3 {
  text-align: center;
}

.emp-query-builder {

  .emp-query-dropdown-label {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 1em;
    padding-bottom: 10px;
  }

  .emp-query-dropdown-label > span {
    font-size: 0.8em;
  }

  .emp-dashboard-query-select {
    padding-right: 25px;
    padding-top: 15px;
  }

  .emp-dashboard-add-dashboard {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    padding-top: 20px;
  }

  .recharts-responsive-container {
    height: 300px !important;
  }
}

.emp-device-card-content {
  height: 80%;
  display: flex;
}

.emp-device-card-content > div {
  margin: auto;
  font-size: 4rem;
}

.emp-dashboard-device-card {

  .emp-device-card-settings {
    position: absolute;
    right: 0;
    padding: 5px;
    cursor: pointer;
  }

  .emp-device-card-settings > i {
    padding: 5px;
  }

  .fa-search-minus {
    padding: 10px;
    text-align: left;
    position: absolute;
    padding-top: 15px;
  }

  a .fa-pen{
    color: $gray-900 !important;
  }
}

.emp-close-query-builder-wrapper {
  background: #0273eb;
  border-radius: 50%;
  color: $white !important;
  height: 30px;
  line-height: 29px;
  text-align: center;
  width: 30px;
  right: 15px;
  top: 16px;
}

.emp-dashboard-card {
  .emp_badge {
    margin-left: 5px;
  }

  .emp-device-card-secondary-text {
    padding: 5px
  }

  .emp-device-card-body {
    padding: 1rem 1.25rem 1rem 1.25rem;
  }

  @media screen and (max-width: 290px) {
    .emp-device-card-title {
      font-size: 1rem;
    }
  }
}

.react-grid-layout {
  .emp-device-card-body > div {
    grid-template-rows: 0 !important;
  }

  .emp-device-card-body > div > i {
    margin: auto;
  }
}

.emp-form-select {
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.emp-dashboard-detail-view {
  .emp-dashboard-title-area {
    display: flex;

    .fa-trash-o {
      padding-right: 5px;
      padding-left: 5px;
      line-height: 1.2;
    }
  }

  .emp-dashboard-title-area > a {
    padding-right: 5px;
  }
}

.emp-dashboard-nav-area {
  padding-bottom: 10px;
}

.emp-dashboard-nav-area button {
  margin-right: 15px;
}

@media (max-width: 480px) {
  .emp-dashboard-nav-area .emp-select-dashboard-area {
    display: unset;
  }
}

.incident-detail-card {
  .card-header {
    //justify-content: space-between;
  }

  span {
    align-self: center;
  }
}

@media screen and (max-width: 490px) {
  .date-field {
    width: 100%;
  }

  .rdtPicker {
    left: 0;
  }
}

@media screen and (max-width: 300px) {
  .date-field {
    margin-left: 0 !important;
  }

  .date-field ~ li:nth-of-type(2n) {
    width: 100%;
  }

  .date-field ~ li:nth-of-type(1n) {
    width: 100%;
  }
}

@media screen and (min-width: 490px) and (max-width: 650px) {
  .date-field input[name=start_date] + div {
    left: 0;
  }

  .date-field input[name="query_params[start_date]"] + div {
    left: 0;
  }

  .date-field input[name="device_data[end_date]"] + div {
    left: 0;
  }
}

@media screen and (max-width: 510px) {
  .emp-date-btn-short {
    margin-bottom: 10px;
  }
}

.shipment-detail-badge {
  padding: 9px 18px 8px 18px;
  margin-right: 5px;

  span {
    color: white;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.workflow_schedule_form {

  .workflow-schedule-date-field-border {
    .date_time_react {
      width: 100%;
    }
  }
  
  .emp_workflow_schedule_form_sec {
    .emp_create_notify_body .emp_notify_badge {
      margin-bottom: 6px;
    }
  }
}

.border-radius-4px {
  border-radius: 4px !important;
}

.shipment-detail-card {
  .card-header {
    justify-content: space-between;
  }

  span {
    align-self: center;
  }
}

.workflow-schedule-list-item {
  letter-spacing: 1px;

  .emp-device-card-body {
    padding: 1.5rem;

    .dropdown-menu {
      min-width: 0;
    }
  }

  .schedule-item-status {
    display: flex;
    justify-content: space-between;
    font-size: 7pt;
  }

  .schedule-item-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .emp-schedule-item-body {
    padding: 0.75rem;

    a {
      color: unset !important;
    }
  }
}

.emp-scheduled-job-history {

  .emp-scheduled-job-card {
    color: #526C79;
    padding: 0.5rem;

    .scheduled-job-top-area {
      display: flex;
      align-items: center;

      .emp-type-tag {
        margin-left: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.schedule-detail-btns > div {
  margin-right: 0.5rem;
}

.schedule-detail-btns > div:last-child {
  margin-right: 0;
}

.schedule-detail-btns {
  display: flex;
}

.emp-scheduled-job-history {
  .emp-scheduled-job-card {
    color: #526C79 !important;
    padding: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;
    border-radius: 0.25rem;

    .scheduled-job-status-area {
      display: flex;
    }

    .schedule-detail-timestamp {
      display: flex;
    }

    @media screen and (max-width: 766px) {
      .scheduled-job-status-area {
        margin-top: 0.5rem;
      }
    }

    .scheduled-job-status-area > div {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.schedule-detail-page {
  .schedule-detail-label {
    color: $form-label;
  }

  @media screen and (max-width: 990px) {
    .card {
      .col-sm-12 {
        border-right: 0 !important;
      }
    }
  }

  .card-header h4 {
    margin-bottom: 0;
  }
}

.emp-tag {
  border: 1px solid;
  padding: 5px;
  text-transform: uppercase;
  display: inline-flex;
  font-size: 7pt;
  border-radius: 2px;
}

.emp-tag-yellow {
  color: $state-warning;
  background: #FEF3CC;
  border-color: #F1C40F;
}

.emp-tag-red {
  color: $state-error;
  background: #F9DEDE;
  border-color: #E74C3C;
}

.emp-tag-geekblue {
  color: $bright-blue;
  background: #e2f3ff;
  border-color: #0e72bb;
}

.emp-tag-green {
  color: $state-success;
  background: #D9F7E1;
  border-color: #2ECC71;
}

.emp_create_report_card {
  .react-autosuggest__suggestions-list {
    max-height: 18rem;
    overflow-y: auto;
  }
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin-bottom: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

// Empiric palette

.emp-type-h1 {
  font-size: 96px;
  letter-spacing: -1.5px;
  font-weight: 300;
}

.emp-type-h2 {
  font-size: 60px;
  letter-spacing: -0.5px;
  font-weight: 300;
}

.emp-type-h3 {
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0px;
}

.emp-type-h4 {
  font-size: 34px;
  letter-spacing: 0.25px;
  font-weight: 400;
}

.emp-type-h5 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
}

.emp-type-h6 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.emp-type-subtitle1 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
}

.emp-type-subtitle2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.emp-type-body1 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.emp-type-body2 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.emp-type-btn-primary {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
}

.emp-type-caption {
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 400;
}

.emp-color-helper-text {
  color: $body-text-color;
  opacity: 0.6;
}

.emp-input-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.emp-input-container input {
  height: 48px;
  border-radius: 1px;
  border-color: #d8d8d8;
  border-width: 1px;
  border-style: solid;
  padding: 24px 16px 4px 16px;
  //font-size: 16px;
  //line-height: 1;
  outline: none;
  //box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.emp-input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}

.emp-input-container select {
  height: 48px;
  border-radius: 1px;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px;
  padding: 23px 16px 4px 16px;
  //font-size: 16px;
  //line-height: 1;
  outline: none;
  //box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.emp-input-container select:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}

.emp-input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 16px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  //font-size: 16px;
  //line-height: 1;
  left: 16px;
}

.emp-input-container:focus-within label {
  transform: translate(0, 7px) scale(0.8);
  color: #0a53e4;
}

.emp-input-container .filled {
  transform: translate(0, 7px) scale(0.8);
}

.emp-input-container {
  &.emp-date-btn-short {
    button {
      border-radius: 1px;
      border-color: #d8d8d8;
      border-width: 1px;
      border-style: solid;
      font-weight: 300;
      border-right: none;

      outline: none;
      background-color: white;
      color: #6f81a5;

      &:hover {
        background-color: #007bff;
        color: white;
      }
    }
  }

  &.emp-date-btn-short-last {
    button {
      border-right: 1px solid #d8d8d8;
    }
  }
}

.env-date .emp-input-container input {
  border-radius: 1px !important;
}

@media only screen and (max-device-width: 480px) {
  .emp-input-container {
    select {
      -webkit-appearance: none;
      background-color: transparent;
    }
  }

  .emp-input-container select {
    padding: 23px 16px 4px 16px;
  }
}

.emp-color-error {
  color: #dc0f0f;
}

.emp-checkbox-container {
  display: flex;
  flex-wrap: wrap;
}

.emp-checkbox-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  label {
    font-size: 1rem;

    span {
      padding-left: 10px;
      padding-right: 15px;
      font-size: 1rem;
      color: #6f81a5;
    }
  }
}

.emp-radio-btn-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

  label {
    font-size: 1rem;
    display: flex;
    align-items: center;

    span {
      color: #9e9e9e;
      font-size: 1rem;
      padding-left: 10px;
      padding-right: 15px;
    }
  }

  input[type="radio"] {
    background-color: #fff;
    font: inherit;
    color: currentColor;
    width: 1em;
    height: 1em;
  }
}

.pb-40 {
  padding-bottom: 160px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.background-none {
  background: none;
}

.scheduled-job-list-item {
  color: $gray-900;
}

.color-form-label {
  color: $form-label
}

.collapsed .app-menu__icon {
  visibility: visible;
}