
/* Media query */
@media screen and ( max-width: 1919px) {
  .app-menu {
    min-height: 550px;
    overflow-y: auto;
    height: 550px;
  }
}
@media screen and ( max-width: 1650px) {
  .app-title .gatway_block_search_l395 {
    margin-right: 336px;
  }
}
@media screen and ( max-width: 1600px) {
  .emp_gatway_box_scc .emp_gatway_box_card .circle_box .icn_wifi_big {
    font-size: 36px;
    line-height: 20px;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .circle_box {
    width: 120px;
    height: 120px;
    padding: 27px 15px 10px 15px;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .bg_gray_box {
    padding: 15px 24px 15px 24px;
    font-size: 14px;
  }
}
@media screen and ( max-width: 1500px) {
  .app-title .gatway_block_search_l395 {
    margin-right: 300px;
  }
}
@media screen and ( max-width: 1440px) {
  .emp-sec {
    padding: 60px 0;
  }
  .emp-login-sec .emp-brand {
    margin: 0px 0 35px 0;
  }
  .emp-login-sec .emp-login-form .emp-form-card .emp-form-heading h2 {
    line-height: 30px;
  }
  .app-menu {
    min-height: 580px;
    overflow-y: auto;
    height: 580px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .circle_box {
    width: 200px !important;
    height: 200px !important;
    padding: 38px 22px 10px 22px;
    line-height: 70px;
    margin-right: 30px !important;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .bg_gray_box {
    padding: 20px 30px 20px 35px;
    min-height: 200px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .circle_box .circle_sm_txt {
    margin-top: 10px;
  }
}
@media screen and ( max-width: 1366px) {
  .emp-sidebar .app-content .emp-box .emp-number-box .emp-number .text-number {
    font-size: 40px;
    line-height: 50px;
  }
  .app-menu {
    min-height: 550px;
    overflow-y: auto;
    height: 550px;
  }
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_add_right_box {
    right: -23%;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .circle_box {
    width: 110px;
    height: 110px;
    padding: 20px 10px 10px 10px;
    margin-right: 10px !important;
  }
  .app-title .gatway_block_search_l395 {
    margin-right: 269px;
  }
}
@media screen and ( max-width: 1290px) {
  .emp-sidebar .app-content .emp_org_settings_sec .checkbox_list_inline .checkbox_label_item {
    margin-left: 20px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .circle_box {
    width: 180px !important;
    height: 180px !important;
    line-height: 80px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .bg_gray_box {
    padding: 20px 30px 20px 35px;
    min-height: 180px;
  }
}
@media screen and ( max-width: 1280px) {
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_add_right_box {
    right: -24%;
  }
  .em-title-checkbox {
    margin-right: 10px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .table-wrapper .table_history .em_th_w-60 {
    width: 80px;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .bg_gray_box {
    padding: 12px 12px 12px 14px;
    font-size: 12px;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .circle_box .icn_wifi_big {
    font-size: 30px;
  }
  .app-title .gatway_block_search_l395 {
    margin-right: 247px;
  }
  .emp-sidebar .app-content .emp_all_groups_w_300 {
    width: 200px;
  }
}
@media screen and ( max-width: 1210px) {
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_add_right_box {
    right: -26%;
  }
}
@media screen and ( max-width: 1199px) {
  .emp_gatway_box_scc .emp_gatway_box_card .circle_box {
    width: 140px;
    height: 140px;
    padding: 38px 24px;
    margin-right: 30px !important;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .bg_gray_box {
    padding: 20px 30px 20px 30px;
  }
  .app-title .gatway_block_search_l395 {
    margin-right: 0;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .circle_box {
    padding: 20px 24px !important;
  }
}
@media screen and ( max-width:1170px) {
  .container{
    max-width: 100%;
  }
  header ul .nav-item{
    margin-right: 3px;
    margin-left: 3px;
  }
  header{
    padding-left: 0;
    padding-right: 0;
  }
  header a.navbar-brand{
    font-size: 20px;
    font-weight: 700;
    float: left;
  }
  header ul .start{
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
@media screen and ( max-width: 1135px) {
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_add_right_box {
    right: -30%;
  }
}
@media screen and ( max-width: 1080px) {
  .app-nav__item {
    font-size: 14px !important;
    padding: 20px 12px 20px 12px;
  }

}

@media screen and ( max-width: 1024px) {
  .emp-sidebar .app-content .emp-title-item {
    margin-bottom: 10px;
  }

}
@media screen and ( max-width:992px) {
  header a.navbar-brand{
    float: left;
    margin-left: 0;
  }
  .navbar{
    display: block;
  }
  .navbar-light .navbar-toggler{
    float: right;
    border-color: $white;
    background-color:$white;
  }
  #navbarNavDropdown{
    background-color: $white;
    float: left;
    text-align: center;
    width: 100%;
    float: left;
  }
  header{
    background-color: $white;
  }
  header{
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    background-color:$white;
    z-index:5;
    transition: all .1s ease-in-out;
    box-shadow: 0 2px 6px rgba(0,0,0,.2);
    a.navbar-brand{
      color: $black !important;
    }
    ul .nav-link{
      color: $gray-500 !important;
      &:hover,&:focus,&:active{
        color: $blue !important;
      }
    }
    .nav-item.active{
      .nav-link{
        color:$pink !important;
      }
    }
    .number{
      color: $black;
      a{
        color: $black;
      }
    }
    .nav-link.login{
      border-color: $blue;
      color: $blue !important;
      &:hover,&:focus,&:active{
        background-color: $pink;
        color: $white !important;
        border-color: $pink;
      }
    }
    .nav-link.start{
      border-color: $blue;
      color: $white !important;
      &:hover,&:focus,&:active{
        background-color: $pink;
        color: $white !important;
        border-color: $pink;
      }
    }
  }
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_add_right_box {
    right: -32%;
  }
}
@media screen and ( max-width: 991px) {
  .emp_v_pipe_md_hide {
    display: none;
  }
  .emp_position_inherit {
    position: inherit;
  }
  #create_schedule .checkbox_list_inline .sm_checkbox_label_item {
    margin-left: 0px;
    margin-right: 15px;
  }
  .incident_notes_block {
    margin-top: 0px;
  }
  .app-title h1 {
    font-size: 16px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .circle_box {
    width: 250px !important;
    height: 250px !important;
    padding: 38px 22px 10px 22px;
    line-height: 100px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .bg_gray_box {
    padding: 20px 30px 20px 60px;
    min-height: 250px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card {
    margin-bottom: 20px;
  }
}
@media screen and ( max-width: 900px) {
  .emp_gatway_box_scc .emp_gatway_box_card .circle_box {
    width: 100px;
    height: 100px;
    padding: 14px 6px;
    margin-right: 15px !important;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .bg_gray_box {
    padding: 12px 12px;
  }
}
@media screen and ( max-width: 980px) {
  .app-nav__item {
    font-size: 13px !important;
    padding: 20px 12px 20px 12px ;
  }
}
@media screen and ( max-width: 925px) {
  .app-nav__item {
    font-size: 12px !important;
    padding: 20px 10px 20px 10px ;
  }
  .emp-sidebar .navbar-right-menu .nav-icon-img {
    margin-right: 5px;
  }
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_add_right_box {
    right: -36%;
  }

}
@media screen and ( max-width: 870px) {
  .app-nav__item {
    padding: 20px 7px 20px 7px ;
  }
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_add_right_box {
    margin: 30px auto 0 auto;
    position: inherit;
    right: 1%;
  }
}

@media screen and ( max-width: 840px) {
  .navbar-right-menu li {
    border-left: none !important;
  }
}
@media screen and ( max-width: 885px) {
  .emp-sidebar .app-content .emp_org_settings_sec .emp_sm_email_btn .emp_notify_badge {
    margin-bottom: 10px;
  }
  .emp-sidebar .app-content .emp_sm_checkbox_btn .checkbox_list_inline .sm_checkbox_label_item {
    margin-left: 0px;
    margin-right: 10px;
  }
}

@media screen and ( max-width: 811px) {
  .app-nav__item {
    font-size: 11px !important;
  }
}
@media screen and ( max-width: 805px) {
  .emp-sidebar .navbar-left-menu .wender-circle {
    font-size: 10px;
    font-weight: 400;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-right: 0px;
  }
}

@media screen and ( max-width: 767px) {
  .app-nav.navbar-right-menu {
    display: none;
  }
  .app-nav__item {
    font-size: 13px !important;
    padding: 20px 12px 20px 12px ;
  }
  .fleet-box-block {
    margin-top: 15px;
  }
  .emp-sidebar .app-content .emp-incidents .emp-circle-icn {
    z-index: 1;
  }
  .emp-sidebar .app-content .emp-box .emp-number-box .emp-number .text-number {
    font-size: 30px;
    line-height: 40px;
  }
  .emp-sidebar .app-content .emp-incidents .emp-box .emp-number-box .emp-number .text-number {
    font-size: 24px;
    line-height: 34px;
  }
  .emp-sidebar .app-content .emp-incidents .emp-circle-icn {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  .app-menu {
    min-height: 430px;
    overflow-y: auto;
    height: 430px;
  }
  .sidebar-mini.sidenav-toggled .app-header__logo {
    left: 0;
    overflow: hidden;
    display: block !important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 280px;
    background-color: $primary;
    transition: left 0.3s ease, width 0.3s ease;
  }
  .sidebar-mini.sidenav-toggled .navbar-left-menu {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__toggle {
    display: none;
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__toggle-xs-circle-times {
    display: block;
  }

  /*xs em sidebar show*/
  .without-emp-sidebar .app-sidebar {
    display: block;
  }
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_create_report_card .emp_create_notify_body .emp_notify_badge {
    margin-bottom: 5px;
  }
  .without-emp-sidebar .emp_org_settings_sec .emp_org_settings_nav .emp_org_settings_item .emp_org_settings_link {
    padding: 8px 35px;
    font-size: 14px;
  }
  .without-emp-sidebar .emp_org_settings_sec .emp_create_report_header {
    font-size: 14px;
  }
  .tab-content .card-body {
    font-size: 14px;
  }
  .border-sm-0 {
    border-right: none !important;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .circle_box {
    width: 140px;
    height: 140px;
    padding: 38px 24px;
    margin-right: 30px !important;
  }
  .emp_gatway_box_scc .emp_gatway_box_card .bg_gray_box {
    padding: 20px 30px 20px 30px;
  }
  .emp_gatway_box_scc .emp_gatway_box_card {
    margin-bottom: 20px;
  }
  .emp_overview_sec {
    padding: 0 15px 0 15px;
  }
  .emp_overview_sec .bg_overview {
    margin-top: 20px;
  }
  .app-nav__item {
    padding: 20px 18px 20px 18px;
  }
}
@media screen and ( min-width:768px) {
  .xs-show-navbar-right-menu {
    display: none;
  }
}
@media screen and ( max-width: 620px) {
  .without-emp-sidebar .emp_org_settings_sec .emp_org_settings_nav .emp_org_settings_item .emp_org_settings_link {
    padding: 8px 25px;
  }
}

@media screen and ( max-width: 614px) {
  .icn_xs_m  {
    margin-left: 0px !important;
  }
  .icn_table img {
    margin-top: 5px;
  }
}

@media screen and ( max-width:580px) {
  h2{
    font-size: 22px;
    line-height: 28px;
    margin-top: 10px;
  }
  h2 br{
    display: none;
  }
  .hero-marque .text-holder h1{
    font-size: 30px;
  }
  .hero-marque .text-holder {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
  }
  header a.navbar-brand{
    font-size: 19px;
  }
  header .number{
    padding-left: 10px;
  }
  .hero-marque{
    margin-bottom: 15px;
  }
  .emp-login-sec .emp-brand {
    margin: 30px 0 70px 0;
  }

  .app-title {
    padding: 20px 0 20px 13px !important;
  }
  .total-incidents-xs-p-m {
    padding-left: 0;
    padding-right: 0;
    margin-top: 15px;
  }
  .p-6-xs-view-l {
    padding-left: 5px;
  }
  .p-6-xs-view-r {
    padding-right: 5px;
  }
  .emp_xs_border_b {
    border-bottom: 1px solid $border_bottom_xs;
    padding: 0px 0 14px 0;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .table-wrapper .table th, .emp-sidebar .app-content .emp_org_settings_sec .table-wrapper .table td {
    font-size: 12px;
  }
  .emp-login-sec .emp-login-form .emp-text-block .text-confirm {
    padding: 8px 0 0px 0px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .circle_box {
    width: 200px !important;
    height: 200px !important;
    line-height: 90px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .bg_gray_box {
    min-height: 200px;
    padding: 20px 30px 20px 30px !important;
  }
}
@media screen and ( max-width: 576px) {
  .new_feature_modal_block .bg_gray_box {
    width: 100%;
  }
}
@media screen and ( max-width: 575px) {
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .cases_box .cases_box_list .circle_icn {
    margin-right: 0;
    text-align: left;
    margin-top: 15px;
  }
  /*.emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .v-pipe-xs-hide {
    display: none;
  }*/
  .xs_mb_10 {
    margin-bottom: 10px;
  }
}

@media screen and ( max-width: 545px) {
  .without-emp-sidebar .emp_org_settings_sec .emp_org_settings_nav .emp_org_settings_item .emp_org_settings_link {
    padding: 8px 15px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm {
    margin-left: 0;
    margin-right: 10px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .emp-cst-btn {
    min-width: 5rem;
    padding: 4px 14px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .create-group {
    margin-top: -6px;
  }
}
@media screen and ( max-width: 520px) {
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .create-group {
    margin-top: -6px;
  }
}
@media screen and ( max-width: 496px) {
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .emp-cst-btn {
    min-width: 4rem;
    padding: 4px 14px;
  }
}
@media screen and ( max-width: 480px) {
  .total-incidents-xs-p-m {
    padding-left: 15px;
    padding-right: 15px;
  }
  .emp-sidebar .app-content .emp-incidents .emp-box .emp-number-box .emp-number ul li {
    margin-left: 20px;
  }
  .emp-sidebar .app-content .emp-incidents .emp-circle-icn {
    left: 0;
  }
  .p-6-xs-view-l {
    padding-left: 6px;
  }
  .p-6-xs-view-r {
    padding-right: 6px;
  }
  .emp-sidebar .app-content .fleet-box-block .emp-box .emp-number-box .emp-number .text-number {
    font-size: 28px;
    line-height: 38px;
  }
  .emp-active-title {
    margin-top: 20px;
  }
  #main {
    margin-top: 10px;
  }
}
@media screen and ( max-width: 435px) {
  .without-emp-sidebar .emp_org_settings_sec .emp_org_settings_nav .emp_org_settings_link.active.emp_org_settings_link::before {
    left: 49.3%;
  }
  .without-emp-sidebar .emp_org_settings_sec .emp_org_settings_nav {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }
}
@media screen and ( max-width: 425px) {
  .alert_sm_title {
    font-size: 14px;
  }
  .alert_sm_text {
    font-size: 11px;
  }
  .alert_body_txt {
    padding: 10px 10px 10px 10px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .circle_box {
    width: 160px !important;
    height: 160px !important;
    line-height: 60px;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .bg_gray_box {
    min-height: 160px;
    padding: 20px 30px 20px 30px !important;
  }
}
@media screen and ( max-width: 419px) {
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .emp-cst-btn {
    min-width: 4rem;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .create-group span {
    display: none;
  }
}
@media screen and ( max-width: 380px) {
  .emp-login-sec .emp-login-form .emp-btn-secondary {
    padding: 6px 6px 6px 6px;
    font-size: 13px;
  }
  .emp-login-sec .emp-login-form .icn-ques {
    width: 18px;
    height: 18px;
    margin: -2px 5px 0px 0px;
  }
  .emp-sidebar .app-content .emp-incidents .emp-box .emp-number-box .emp-number .text-number {
    font-size: 14px;
    line-height: 30px;
  }
  .emp-sidebar .app-content .emp-incidents .emp-box .emp-number-box .emp-number ul li {
    margin-left: 15px;
  }
  .emp-sidebar .app-content .emp-incidents .emp-box .emp-number-box .emp-number ul li .emp-box-icident-txt {
    font-size: 12px;
    line-height: 12px;
  }
  .without-emp-sidebar .emp-active-device-sec .emp-group-card .emp_list_group_card {
    padding: 15px;
  }
  .without-emp-sidebar .emp-active-device-sec .emp-group-card .group-step-label {
    font-size: 14px;
  }
  .emp_create_notify_box .card-body {
    padding: 1rem;
  }
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_create_report_card .emp_create_report_body {
    padding: 1rem;
  }
  .without-emp-sidebar .create_new_report_sec .emp_create_report_form .emp_create_report_card .emp_create_notify_body .emp_create_notify_box .emp_user {
    width: 35px;
    height: 35px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm {
    margin-top: 10px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .emp-cst-btn {
    min-width: 3rem;
  }
  .without-emp-sidebar .emp_org_settings_sec .emp_file_input .input-group-append {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
  .without-emp-sidebar .emp_org_settings_sec .emp_file_input .input-group-append .btn_file_input {
    width: 100%;
  }
}
@media screen and ( max-width: 370px) {
  .emp_gatway_box_scc .emp_gatway_box_card .circle_box {
    width: 120px;
    height: 120px;
    padding: 30px 16px;
    margin-right: 24px !important;
  }
}
@media screen and ( max-width: 360px) {
  .app-menu {
    min-height: 350px;
    overflow-y: auto;
    height: 350px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .emp-cst-btn {
    padding: 4px 14px;
    font-size: 13px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm {
    margin-right: 6px;
  }
}

@media screen and ( max-width: 350px) {
  .emp_gatway_circle_sec .emp_gatway_box_card .circle_box {
    width: 150px !important;
    height: 150px !important;
    line-height: 50px;
    padding: 12px 17px !important;
  }
  .emp_gatway_circle_sec .emp_gatway_box_card .bg_gray_box {
    min-height: 150px;
    padding: 20px !important;
  }
}
@media screen and ( max-width: 335px) {
  .app-sidebar__toggle-xs-circle-times {
    font-size: 14px;
    height: 24px;
    line-height: 22px;
    width: 24px;
    right: 11px;
  }
  .emp-sidebar .app-content .emp_org_settings_sec .alert_box_btn .alert_btn_list .alert_btn_itm .emp-cst-btn {
    padding: 4px 12px;
    font-size: 12px;
  }
}

@media screen and ( max-width: 334px) {
  .btn_xs_t10 {
    margin-top: 0px;
    margin-left: 5px;
  }
  .xs_mr_0 {
    margin-right: 0 !important;
  }
}

@media screen and ( max-width: 586px) {

  .emp-dashboard-nav-item {
    margin-bottom: 15px;
  }
}

/* // Media query */

