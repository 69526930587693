@import "../../styles/variables";

.app-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 5;

  .header-promo {
    position: relative;
    text-align: center;
    background-color: #F5A623;
    font-size: 18px;
    color: #fff;

    button {
      border: none;
      background: transparent;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 15px;
    }
  }

  &--blurred {
    background-color: rgba(0, 0, 0, .4);
    @supports ( (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)) ) {
      background-color: transparent;
      backdrop-filter: blur(5px);
    }
  }

  &__content {
    height: $header-height;
    display: flex;
    align-items: center;
  }


  .logo {
    margin-right: 80px;
    a {
      display: inline-block;
    }
  }


  .logo {
    margin-right: 80px;
  }

  .destination-search-bar {
    width: 100%;
    margin-right: 80px;
  }

  .navigation {
    margin-left: auto;
    white-space: nowrap;
    font-size: 17px;

    .dropdown-menu {
      border-radius: 0;
      padding: 0;
      left: auto;
      right: 0;
      margin-top: 10px;
      text-align: right;

      .dropdown-large-items {
        line-height: 24px;
        li {
          padding-bottom: 10px;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      .dropdown-small-items {
        font-size: 12px;
        line-height: 22px;
        background-color: #fafafa;
      }

      .dropdown-large-items,
      .dropdown-small-items {
        padding: 10px 0;
      }


      li {
        a {
          display: block;
          padding: 0 10px;
          color: #666666;
        }
      }


      &:before {
        content: "";
        border-bottom: 9px solid rgba(0, 0, 0, 0.2);
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);
        display: inline-block;
        right: 8px;
        position: absolute;
        top: -8px;
      }

      &:after {
        content: "";
        border-bottom: 8px solid #FFFFFF;
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);
        display: inline-block;
        right: 8px;
        position: absolute;
        top: -7px;
      }
    }

    .navigation-list {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;

      > li {
        margin-right: 30px;

        .account-link {
          background-color: transparent;
          padding: 0;
          width: 36px;
          height: 36px;
          display: inline-block;
          text-indent: -999999px;
          box-shadow: none;
          border: none;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }

        > a {
          color: #fff;
          text-decoration: none;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
  }
}
.header-icon {
  margin-top: -1px;
}