#sops.app-content {
  margin-top: 60px;
  padding: 0;
}

#sops .font-small {
  font-size: 0.75em;
}

#sops section {
  padding-top: 25px;
  padding-bottom: 100px;
}

#sops .form-navigator {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f2f3f6;
}
#sops .form-navigator .row {
  max-width: 800px;
  margin-inline: auto;
}


.sop-form {
  color: #000000;
  font-size: 20px;
  max-width: 800px;
  margin-inline: auto;
  min-height: unset;
}

@media (max-width: 822px) {
  .sop-form {
    max-width: 700px;
  }
}



.sop-form input {
  padding: 0.2rem 0.5em;
  height: initial;
}

.sop-form label {
  font-weight: 500;
}

#sops.batch-info label {
  text-align: left;
}

.sop-section-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 0;
}

.sop-section-title--sm {
  font-size: 20px;
  font-weight: 600;
  color: #526C79;
}
.sop-section-subtitle {
  color: #526C79;
  font-size: 0.75em;
  margin-bottom: 0;

}
.sop-instruction {
  font-size: 26px;
  font-weight: 500;
  margin-block: 1em;
}

.sop-heading {
  font-size: 28px;
  margin-block: 1em;
  white-space: pre-wrap;
}


.sop-form .form-group {
  display: flex;
  align-items: center;
  gap: 1em;
}

.sop-form .test-col {
  font-size: 20px;
}

.sop-form .form-input {
  display: flex;
  flex-direction: column;
}


.sop-form .form-input--horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}



.sop-form .form-input--horizontal label {
  margin-bottom: 0;
  max-width: 140px;
  width: 100%;
  text-align: right;
}


.sop-form .form-input.checkbox-note {
  flex-direction: row;
}

.sop-form .form-input.checkbox-note label {
  font-size: 28px;
  max-width: 700px;
}

.form-control-input {
  /* margin-left: auto; */
}

input[type=checkbox].form-control {
  width: clamp(20px, 100%, 100px);
  margin-block: auto;
  height: 20px;
}
input[type=time].form-control {
  width: 160px;
}
input.form-control, div.form-control-input{
  width: 100%;
  font-size: 20px;
}


.sop-form .field-error {
  font-size: 16px;
  position: absolute;
  top: -1.5rem;
  right: 0.5rem;
}

.sop-form .field-error.vertical {
  top: 0.75rem;
}

.batch-adjustment {
  margin: 0 0 8rem;
  padding-block: 0
}

.batch-adjustment input[type=time].form-control {
  width: 100%;
}

.batch-adjustment .form-input, .batch-adjustment .form-control {
  margin-block: 0 !important;
  font-size: 16px;
}

.batch-adjustment .header-row {
  font-weight: 500;
}

.header {
  padding: 10px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

/* Example of additional styling for specific headers */
.header[data-type="chemist"] {
  background-color: #D1E8FF;
}

.header[data-type="operator"] {
  background-color: #1D7CBF;
}