@import '../../styles/custom/custom_variables';
.emp-sec {
  padding: 60px 0;
}
.emp-login-sec {
  .emp-brand {
    margin: 0 0 52px 0;
    height: auto;
    width: 220px;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  .emp-login-form {
    .emp-form-card {
      border-radius: 6px;
      .emp-form-heading {
        h2 {
          margin-bottom: 20px;
          margin-top: 12px;
          font-size: 25px;
          font-weight: 500;
          line-height: 32px;
          color: #354052;
          letter-spacing: 0.42px;
        }
      }
      .emp-form-btn {
        min-width: 16rem;
        margin: 14px 0 13px 0;
      }
      .form-bottom-links {
        p {
          font-size: 14px;
          margin-bottom: 5px;
        }
        a {
          color: $sign-up-bg;
          &:hover {
            color: $sign-up-bg-700;
          }
        }
      }
    }
    .emp-text-gray {
      color: $gray-500;
    }
    .emp-text-block {
      .text-confirm {
        padding: 8px 43px 0 43px;
      }
    }
    .yazi2 {
      position: relative;
      border-bottom: 2px solid $border-overlap-text;
      text-align: center;
      margin: 40px 0 30px 0;
      width: 100%;
      .or-text {
        position: absolute;
        font-size: 16px;
        left: 44%;
        display: inline-block;
        padding: 0 10px;
        margin-top: -12px;
        background: white;
        color: $gray-500;
      }
    }
    .emp-btn-secondary {
      background-color: $sign-up-bg;
      border-color: $sign-up-bg-700;
      color: $white;
      &:hover {
        background-color: $sign-up-bg-700;
        border-color: $sign-up-bg-700;
        color: $white;
      }
    }
    .signup-btn-block {
      margin: 26px 0 0 0;
    }
    .icn-ques {
      width: 18px;
      height: 18px;
      margin: -3px 10px 0 10px;
    }
    .emp-btn {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    }
    .emp-activate-devices {

    }
  }
}
