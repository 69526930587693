@import "../../styles/variables";

.login-page {
  //background-image: image_url("images/auth-bg.jpg");
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .1);
  }

  @media (min-width: 992px) {
    height: 100vh;
  }

  .auth-sections {
    color: #fff;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;

    @media (min-width: 992px) {
      position: absolute;
      width: 800px;
      display: flex;
    }

    .row {
      @media (min-width: 992px) {
        width: 100%;
      }
    }

    &__title {
      display: block;
      text-align: center;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 40px;
    }
  }

  .left-side,
  .right-side {
    padding-top: 30px;
    padding-bottom: 30px;
    height: 367px;
  }

  .left-side {
    @media (min-width: 992px) {
      padding-right: 80px;
      border-right: 1px solid #fff;
    }
  }

  .right-side {
    text-align: center;
    @media (min-width: 992px) {
      padding-left: 80px;
    }
  }


  .login-form {
    .form-group {
      margin-bottom: 10px;
    }
    .form-control {
      background-color: rgba(100, 100, 100, .35);
      border: 1.25px solid #fff;
      color: #fff;
      height: 50px;
      border-radius: 4px;

      &::placeholder {
        color: #fff;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn-link {
      font-size: 1.4em;
      color: #fff;
      transition: all 0.2s ease-out;
      &:hover {
        color: #ccc;
      }
    }

    .forgot-password {
      margin: 20px 0;
      text-align: right;
    }
  }


  .connect-with-facebook {
    span {
      display: block;
      font-weight: bold;
      font-size: 1.5em;
      margin-bottom: 12px;
    }

    .btn-facebook {
      background-color: #3B5998;
      border-color: #3B5998;
      font-size: 30px;
      width: 150px;
      height: 63px;

      &:focus,
      &:hover,
      &:active {
        color: #fff;
        background-color: darken(#3B5998, 10%);
        border-color: darken(#3B5998, 10%);
      }
    }
  }

  .or-text {
    font-size: 1.9em;
    font-style: italic;
    margin: 30px 0;
  }

  .btn-signup {
    background-color: rgba(100, 100, 100, .35);
    border: 1.25px solid #fff;

    &:focus,
    &:hover {
      color: #fff;
    }

    &:hover {
      background-color: rgba(100, 100, 100, .45);
    }
    &:focus {
      background-color: rgba(100, 100, 100, .55);
    }
  }

}